/*
 * Js > Datatables
 */

//----------------------------------------------------------------------
// Dependencies
//----------------------------------------------------------------------
var $ = jQuery = require('jquery');
var moment = require('moment');
var dataTable = require('datatables');
var datatablebs3 = require('datatablebs3');
var datetimeMoment = require('datetime-moment');

//----------------------------------------------------------------------
// Exports
//----------------------------------------------------------------------
module.exports = {
    init: function(){
        baseFilter();
    }
};

//----------------------------------------------------------------------
// Functions

//----------------------------------------------------------------------
//use for small data, no ajax
function baseFilter(){
  
    $.fn.dataTable.moment('DD/MM/YYYY');
    $.fn.dataTable.moment('DD/MM/YYYY HH:mm:ss');

    var filterDefault = $('.table-filter-default').dataTable({
        columnDefs: [{
            targets: ['no-order'],
            orderable: false
        }],
        language: {
               "url": "/api/helper/datatable-translations"
        },
        lengthChange: false,
        stateSave: true,
        order: [],
        fnDrawCallback: function(oSettings) {
            if (oSettings._iDisplayLength > oSettings.fnRecordsDisplay()) {
                $(oSettings.nTableWrapper).find('.dataTables_paginate').hide();
            } else {
                $(oSettings.nTableWrapper).find('.dataTables_paginate').show();
            }
        }
    });

    var filterPageless = $('.table-filter-pageless').dataTable({

        columnDefs: [{
            targets: ['no-order'],
            orderable: false
        }],
        order: [],
        paging: false,

        fnDrawCallback: function(oSettings) {
            if (oSettings._iDisplayLength > oSettings.fnRecordsDisplay()) {
                $(oSettings.nTableWrapper).find('.dataTables_paginate').hide();
            } else {
                $(oSettings.nTableWrapper).find('.dataTables_paginate').show();
            }
        }
    });

    var filterGrandTable = $('.table-filter-grand').dataTable({
        columnDefs: [{
            targets: ['no-order'],
            orderable:false
        }],

        lengthChange: false,
        paging: false,
        order: []
    });



    // Faux filterbar - on focus
    $('.filterbar input').focus(function() {
        $('.filterbar .input-group').css('border-bottom', '2px solid #1976d2');
    });

    // Faux filterbar - on blur
    $('.filterbar input').blur(function() {
        $('.filterbar .input-group').css('border-bottom', '2px solid transparent');
    });

    $('.input-filter-grand').keyup(function() {
        // filterGrandTable.fnFilter($(this).val());
        if ($(this).closest('.datatables-container').length > 0) {
            $(this).closest('.datatables-container').find('.table-filter-grand').dataTable().fnFilter($(this).val());
        } else {
            filterDefault.fnFilter($(this).val());
        }
    });

    $(document).on('keyup', '.input-filter-grand', function(){
        if ($(this).closest('.datatables-container').length > 0) {
            $(this).closest('.datatables-container').find('.table-filter-grand').dataTable().fnFilter($(this).val());
        } else {
            filterDefault.fnFilter($(this).val());
        }
    });

    // Fix to allow multiple datatables on same page (i.e. tabs)
    $('.input-filter-default').keyup(function() {
        //multiple datatables on one page
        if ($(this).closest('.datatables-container').length > 0) {
            $(this).closest('.datatables-container').find('.table-filter-default').dataTable().fnFilter($(this).val());
        } else {
            filterDefault.fnFilter($(this).val());
        }
    });

    // Fix to allow multiple datatables on same page (i.e. tabs)
    $('.input-filter-pageless').keyup(function() {
        //multiple datatables on one page
        if ($(this).closest('.datatables-container').length > 0) {
            $(this).closest('.datatables-container').find('.table-filter-pageless').dataTable().fnFilter($(this).val());
        } else {
            filterPageless.fnFilter($(this).val());
        }
    });
}
