/**

 * This file will be bundled by browserify
 * Implement using CommonJs modules or shim non CommonJs modules in package.json
 */
// ===============================================================
// Import
// ===============================================================
// Either npm module packages or shimmed packages in package.json
// ---------------------------------------------------------------
// NPM modules


var $                       = jQuery = require('jquery');
var bootstrap               = require('bootstrap');

// base modules
var baseEvents              = require('./local/events');
var baseSelect2             = require('./local/select2');
var baseDatepicker          = require('./local/datepicker');
var baseChart               = require('./local/chart');

var baseDatatables          = require('./local/datatables');
var baseBootstrap3          = require('./local/bootstrap3');
var baseValidator           = require('./local/validator/main');

//Resource modules
var localPartner            = require('./local/partner/main');
var localReservation        = require('./local/reservation/main');
var localCruise             = require('./local/cruise/main');
var localPassenger          = require('./local/passenger/main');
var localPackage            = require('./local/package/main');
var localContract           = require('./local/contract/main');
var localCharterContract    = require('./local/chartercontract/main');
var localFinance            = require('./local/finance/main');
var localInvoice            = require('./local/finance/invoice/main');
// var localShip               = require('./local/ship/main');

var localShipTable          = require('./local/ship/table');
var localShip               = require('./local/ship/main');

var localReport             = require('./local/finance/report/main');

var koko 					= require('./local/koko');

var deleteEvents 			= require('./local/delete');
 
//----------------------------------------------------------------------
// Main
//----------------------------------------------------------------------
$(document).ready(function() {
    'use strict';

    //----------------------------------------------------------------------
    // Initialize | Base global UX
    //----------------------------------------------------------------------


    baseEvents.init();

    baseSelect2.init();
    baseDatepicker.init();
    baseChart.init();
    baseDatatables.init();
    baseBootstrap3.init();
    baseValidator.init();
    //----------------------------------------------------------------------
    // Resource | Specific UX
    //----------------------------------------------------------------------
    localPartner.init();
    localReservation.init();
    localPackage.init();
    localContract.init();
    localCruise.init();
    localPassenger.init();
    localCharterContract.init();
    localFinance.init();
    localInvoice.init();
    localShip.init();
    localShipTable.init();
    localReport.init();


    // Koko
    koko.init();

    deleteEvents.init();


});
