var $ = jQuery = require('jquery');

module.exports = {
	'init': function() {
		safeDelete();
        safeFormDelete();
        safeAjaxDelete();
        permDelete();
		closeModal();
	}
}

function safeDelete(){
    $(document).on('click', '.safe-delete', function(e){
        e.preventDefault();
        var action = $(this).attr('href');
        $('#delete-modal').fadeIn();
        $('.perm-delete').attr('href', action);
    });
}

function safeFormDelete(){
    $(document).on('click', '.safe-form-delete', function(e){
        e.preventDefault();
        var action = $(this).parent().attr('action');
        $('#delete-modal').fadeIn();
        $('.perm-delete').hide();
        $('.perm-delete-form').show();
        $('.perm-delete-form').attr('action', action);
    });
}

function safeAjaxDelete(){
    $(document).on('click', '.safe-ajax-delete', function(e){
        e.preventDefault();
        var attributes = $(this).prop('attributes');
        var permDelete = $('.perm-delete');
        var action = $(this).data('action');
        $.each(attributes, function(){
            permDelete.attr(this.name, this.value);
        });

        $('#delete-modal').fadeIn();
        $(permDelete).removeClass('btn-flat').removeClass('safe-ajax-delete').addClass('perm-delete').addClass(action).addClass('btn-icon-text');
    });
}

function permDelete(){
    $(document).on('click', '.perm-delete', function(e){
        $('.modal').fadeOut();
        $(this).removeClass();
        $(this).removeData();
        $(this).addClass('btn').addClass('btn-sm').addClass('perm-delete').addClass('btn-danger').addClass('btn-icon-text')
    });
}

function closeModal(){
    $(document).on('click', '.close-modal', function(e){
        e.preventDefault();
        $('.modal').fadeOut();
    });
}
