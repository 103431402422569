var $ = jQuery = require('jquery');

module.exports = {
  'init': function(){

  },
  'sendMessage': function(type, message){
    clearMessageContainer();

    function clearMessageContainer(){
      $('.message-container').text('');
      $('.message-container').removeClass('alert alert-info alert-warning alert-danger alert-success');
    }

    $('.message-container').addClass('alert alert-'+type);
    $('.message-container').text(message);
  }
}
