var $ = jQuery = require('jquery');
var bootstrap = require('bootstrap');
var select2 = require('select2');
var ajax = require('../ajax');


module.exports = {
    init: function () {
        checkSelectBoxes();
        excursionEvents();
        packageEvents();
    }

};

function checkSelectBoxes(){

    $('.check-package').click(function(e){
        $('.error-container p').remove();
        if($('.package-type').val() == 0){
            $('.error-container').append('<p class="package">Select a package type</p>');
            e.preventDefault();
        }
        else {
            $('.error-container .package').remove();
        }
    });


    $('.package-type').change(function(e){
        var changeVal = $(this).val();

        if(changeVal != 0){
            $('.error-container .package').remove();
        }
    })
}

function excursionEvents(){
    $(document).on('click', '.get-price-edit-form', function(e){
        e.preventDefault();

        var excursionInstanceId = $(this).data('id');

        var data = {
            'excursion_instance_id': excursionInstanceId
        };

        var url = '/package/excursion/ajax-get-price-edit-form';

        ajax.bladeCall(url, data, callback);

        function callback(response){
            $('.edit-price-form-container').html(response);
        }

    });

    $(document).on('change', '.package-type', function(e){
      var value = $(this).val();

      if(value == 2){
        $('.excursion-select-container').show();
      } else {
        $('.excursion-select-container').hide();
      }
    });


    $(document).on('change', '.charter-package-select', function(e){
        var packageId = $(this).val();

        var data = {
            'package_id': packageId,
        };

        var url = '/chartercontract/charterorder/ajax-check-charter-package-is-excursion';

        ajax.jsonCall(url, data, callback);

        function callback(response){
            if(response){
                $('.package-price').val(response);
                $('.package-price').attr('readonly', 'true');
            } else {
                $('.package-price').val('');
                $('.package-price').removeAttr('readonly');
            }
        }
    });

    $(document).on('change', '.contract__view-allotment .season-package-select', function(e){
        var packageId = $(this).val();

        var data = {
            'package_id':packageId
        };

        var url = '/contract/allotment/ajax-check-package-is-excursion';

        ajax.jsonCall(url, data, callback);

        function callback(response){

            $('.fixed-row').remove();
            $('.commission-row').remove();

            if(response){
                defaultPackageRateRow(response, '.inithide.table-package-add-rate-dummy .package-add-rate-row', '.package-add-rate-container tbody');
                $('.btn-add-package-rate').attr('disabled', 'disabled');
            } else {
                $('.btn-add-package-rate').removeAttr('disabled');
                $('.fixed-row').remove();
                $('.commission-row').remove();
            }
        }
    });

    $(document).on('change', '.contract__create .season-package-select', function(e){
        var packageId = $(this).val();

        var data = {
            'package_id':packageId
        };

        var url = '/contract/allotment/ajax-check-package-is-excursion';

        ajax.jsonCall(url, data, callback);

        function callback(response){

            $('.fixed-row').remove();
            $('.commission-row').remove();

            if(response){
                var packageId = $('#season-package-modal .season-package-select').val();
                var seasonId = $('#season-package-modal .season-package-select').data('seasonid');

                var packageRateTypeName = 'allocations[seasonId_' + seasonId + '][packages][packageId_' + packageId + '][packageRate][type][]';
                var packageRateValueName = 'allocations[seasonId_' + seasonId + '][packages][packageId_' + packageId + '][packageRate][value][]';

                var rowElement = $(document).find('.inithide.table-package-rate-dummy .package-rate-row');
                rowElement.splice($.inArray(1, rowElement), 1);

                defaultPackageRateRow(response, rowElement, '.table-package-rate-overview tbody', packageRateTypeName, packageRateValueName);
                $('.btn-add-package-rate').attr('disabled', 'disabled');

            } else {
                $('.btn-add-package-rate').removeAttr('disabled');
                $('.fixed-row').remove();
                $('.commission-row').remove();
            }
        }
    });


    $(document).on('click', '.contract__view-allotment .btn-add-package-commission-row', function(e){

        defaultPackageCommissionRow('.inithide.table-package-rate-dummy .package-rate-row', '.package-rate-container tbody', 'rateType[]', 'rateValue[]');
        $(this).attr('disabled', 'disabled');
    });

    $(document).on('click', '.contract__create .btn-add-package-commission-row', function(e){

        var packageId = $(this).data('packageid');
        var seasonId = $(this).data('seasonid');

        var packageRateTypeName = 'allocations[seasonId_' + seasonId + '][packages][packageId_' + packageId + '][packageRate][type][]';
        var packageRateValueName = 'allocations[seasonId_' + seasonId + '][packages][packageId_' + packageId + '][packageRate][value][]';

        var rowElement = $(document).find('.inithide.table-package-rate-dummy .package-rate-row');
        rowElement.splice($.inArray(1, rowElement), 1);

        defaultPackageCommissionRow(rowElement, '.table-package-rate-overview tbody', packageRateTypeName, packageRateValueName);

        $(this).attr('disabled', 'disabled');
    });

    $(document).on('click', '.btn-rate-row-delete', function(e){
        $('.btn-add-package-commission-row').removeAttr('disabled');
        $('.btn-mass-add-package-rate').removeAttr('disabled');
        $('.btn-mass-add-commission-rate').removeAttr('disabled');
    });


    $(document).on('change', '.mass-package-select', function(e){
        var packageId = $(this).val();

        var data = {
            'package_id':packageId
        };

        var url = '/contract/allotment/ajax-check-package-is-excursion';

        ajax.jsonCall(url, data, callback);

        function callback(response){
            $('.fixed-row').remove();
            $('.commission-row').remove();

            if(response){

                var rowElement = $(document).find('.inithide.table-package-rate-dummy .package-rate-row');

                rowElement.splice($.inArray(1, rowElement), 1);

                defaultPackageRateRow(response, rowElement, '.table-package-rate-overview tbody', 'package[packageRateTypes][]', 'package[packageRateValues][]');

                $('.btn-mass-add-package-rate').attr('disabled', 'disabled');
                $('.btn-mass-add-package-rate').addClass('btn-mass-add-commission-rate');
                $('.btn-mass-add-package-rate').removeClass('btn-mass-add-package-rate');

                var types = $(document).find('input[name="package[packageRateTypes][]"]');
                var values = $(document).find('input[name="package[packageRateValues][]"]');

            } else {
                $('.btn-mass-add-package-rate').removeAttr('disabled');
                $('.btn-mass-add-package-rate').removeClass('btn-mass-add-commission-rate');
                $('.fixed-row').remove();
                $('.commission-row').remove();
            }
        }

    });

    $(document).on('click', '.btn-mass-add-commission-rate',function(e){
        var rowElement = $(document).find('.inithide.table-package-rate-dummy .package-rate-row');
        rowElement.splice($.inArray(1, rowElement), 1);

        defaultPackageCommissionRow(rowElement, '.table-package-rate-overview tbody', 'package[packageRateTypes][]', 'package[packageRateValues][]');
        $(this).attr('disabled', 'disabled');
    });
}


function defaultPackageRateRow(price, rowClone, appendTo, packageRateTypeName = 'rateType[]', packageRateValueName = 'rateValue[]'){

    var rateRow = $(rowClone).clone();

    $(rateRow).find('.rateType_name').attr('name', packageRateTypeName);

    $(rateRow).find('.rateType_name').find('option').each(function(option){
        if(option == 1){
            $(this).attr('selected');
        } else {
            $(rateRow).find(this).remove();
        }
    });

    $(rateRow).find('.rateValue_name').attr('name', packageRateValueName);
    $(rateRow).find('.rateValue_name').attr('step', 'any');
    $(rateRow).find('.rateValue_name').attr('readonly', 'readonly');
    $(rateRow).find('.rateValue_name').val(price);

    $(rateRow).find('.btn-rate-row-delete').remove();

    $(rateRow).removeClass('.table-package-rate-dummy');
    $(rateRow).addClass('fixed-row');

    rateRow.appendTo(appendTo);

    defaultPackageCommissionRow(rowClone, appendTo, packageRateTypeName, packageRateValueName);
}

function defaultPackageCommissionRow(rowClone, appendTo, packageRateTypeName, packageRateValueName){

    var commissionRow = $(rowClone).clone();

    $(commissionRow).find('.rateType_name').attr('name', packageRateTypeName);

    $(commissionRow).find('.rateType_name').find('option').each(function(option){
        if(option == 3){
            $(this).attr('selected');
        } else {
            $(commissionRow).find(this).remove();
        }
    });

    $(commissionRow).find('.rateValue_name').attr('name', packageRateValueName);
    $(commissionRow).find('.rateValue_name').attr('step', 'any');

    $(commissionRow).removeClass('.table-package-rate-dummy');
    $(commissionRow).addClass('commission-row');

    commissionRow.appendTo(appendTo);

    $('.btn-rate-row-delete').click(function (event) {
        $(this).closest('tr').remove();
        $('.btn-add-package-commission-row').removeAttr('disabled');
    });

}

function packageEvents(){
    $('.delete-package-form').submit(function(event){
        event.preventDefault();
        var deletePackage = confirm('Are you sure you want to delete this package? This change cannot be undone.');

        if(deletePackage){
            $(this).unbind('submit').submit();
        }
    });

}
