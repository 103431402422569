/*
 * Js > Select2
 */

//----------------------------------------------------------------------
// Dependencies
//----------------------------------------------------------------------
var $         = jQuery = require('jquery')
var select2        = require('select2');

module.exports = {
    init: function(){
        select2Init();
    }
};


function select2Init() {
    //only initialise dropdown with select2 when necessary
    $('.select2').select2();
    $.fn.modal.Constructor.prototype.enforceFocus = function() {};
}
