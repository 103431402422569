/*
 * Js > Events
 */


//----------------------------------------------------------------------
// Dependencies
//----------------------------------------------------------------------
var $ = jQuery = require('jquery');
var ajax = require('./ajax');

//----------------------------------------------------------------------
//Exports
//----------------------------------------------------------------------
module.exports = {
    'init': function() {
        cardToggle();
        formChangeAlert();
        imageInputPreview();
        countrySelect();
        getTaxNumberFromEdit();
        miscEventsOnDocumentReady();
        deleteUser();
        selectAll();
        scrollToNavigation();
        scrollableToolbar();
        submitToolbar();
		closePopup();
    },
    'countrySelect': countrySelect
};

var zero = 0;
var minusOne = -1;
var hundred = 100;
var one = 1;

//----------------------------------------------------------------------
// Functions
//----------------------------------------------------------------------
// card toggle
function cardToggle() {
    $('.paper-wrap .btn-card-toggle, .card.paper .btn-card-toggle').click(function(e) {
        e.preventDefault();
        $(this).toggleClass('fa-caret-down');
        $(this).toggleClass('fa-caret-left');
        $(this).parent().parent().parent().find('.card-content').toggle();
    });
}

// form leave alert
function formChangeAlert() {
    var formmodified = 0;
    $('form *').change(function() {
        formmodified = 1;
    });
    window.onbeforeunload = confirmExit;

    function confirmExit() {
        if (formmodified == 1) {
            return 'New information not saved. Do you wish to leave the page?';
        }
    }

    $("button.submit, button[type='submit'], button").click(function() {
        formmodified = 0;
    });
}

// preview image input
function imageInputPreview() {
    $('.img-input').change(function() {
        readURL(this, '.img-input-preview');

        //read url for image preview function -> might refactor this out?
        function readURL(input, target) {
            if (input.files && input.files[0]) {
                var reader = new FileReader();
                reader.onload = function(e) {
                    $(target).attr('src', e.target.result);
                };
                reader.readAsDataURL(input.files[0]);
            }
        }
    });
}
// ajax: city by country dropdowns
function countrySelect() {
    if ($('.country-select').length >= 0 || $('.city-select').length >= 0) {
        $('.country-select').change(function(e) {
            var country = $(this).children(':selected').text();

            var data = {
                'country': country
            };

            ajax.jsonCall('/api/helper/cities-by-country', data, callback);

            function callback(response) {
                $('.city-select option').remove();
                $('.city-select').select2({
                    'data': response
                });
            }
        });
    }
}

//----------------------------------------------------------------------
// Ajax: TaxNumber update events
//----------------------------------------------------------------------

function getTaxNumberFromEdit() {

    $('.edit-taxnumber').click(function(e) {
        e.preventDefault();
        var taxNumberId = $(this).data('taxnumberid');
        var formAction = $(this).data('formaction');
        var data = {
            'tax_number_id': taxNumberId,
            'form_action': formAction
        };
        ajax.bladeCall('/api/finance/taxnumber-by-id', data, callback);


        function callback(response) {
            $('#modal-taxnumber-form').html(response);

        }

        $('#edit-taxnumber-modal').modal('show');
    });

}

// function for several scripts that run on ready state

function miscEventsOnDocumentReady(){
    // loader for large exports
    $('.loader-button').on('click', function(){
        $('.select-loader').show();

        setTimeout(function(){ $('.select-loader').hide(); }, 10000);
    });


    $(document).on('click', 'a[role="scroll-to"]', function(e){
        e.preventDefault();
        var id = $(this).attr('href');
        $(this).parent().parent().find('.active').removeClass('active');
        $(this).parent().addClass('active');

        $('html, body').animate({
            scrollTop: $(id).offset().top-100
        }, 1500);
    });

    // hide/show list items in overviews

    $(document).on('click', '.first-li', function() {
        $(this).parent().children('li:not(:first-child)').slideToggle();
        $(this).toggleClass('rotated');
    });

    $(document).on('click', '.show-more-onclick-div .expand-button', function(){
        $(this).parent().toggleClass('full-height');
        $(this).toggleClass('rotate');
    });

    $(document).ready(function(e){

        // add class to first list item in overviews
        $('.show-more-onclick').each(function(){
            var all_li = $(this).find('li').length;
            var hidden_li = all_li - one;

            if(all_li > one){
                $(this).find('li:first-child').attr('hiddenCount', hidden_li).addClass('first-li');
            }
        });

        // add active class to li's for user to know where they are
        $('.nav-sidebar-main > li > a').each(function(){

            var href_tag = $(this).attr('href');
            var protocol = window.location.protocol;
            var url_host = window.location.host;
            var url_pathname = window.location.pathname.split('/');
            var url_path = url_pathname[one];


           if(url_path == 'user'){
               var url_window = protocol + '//' + url_host + '/' + url_path + '/' + url_pathname[2];
           }
            else {
                var url_window = protocol + '//' + url_host + '/' + url_path;
            }

            if(href_tag == url_window){
                $(this).parent().addClass('active');
            }
        });
    });

    $(document).on('click', '.close-navigations', function(e){
        $('.sidebar').hide();
        $('.page-header').hide();
        $('.page-toolbar').hide();
        $('.main').addClass('full-size-main');
        $('.open-navigations').show();
        $(this).hide();
    });

    $(document).on('click', '.open-navigations', function(e){
        $('.sidebar').show();
        $('.page-header').show();
        $('.page-toolbar').show();
        $('.main').removeClass('full-size-main');
        $('.close-navigations').show();
        $(this).hide();
    });

    $(document).on('click', '.prevent', function(e){
      e.preventDefault();
    });

    calculateToolbarUlWidth();
    cardRowShowMore();

}

function calculateToolbarUlWidth(){
    var totalWidth = zero;

    $('.tab-toolbar').children('li').each(function(){
        totalWidth += $(this).width();
    });
    var parentWidth = $('.tab-toolbar').parent().width();

    if(totalWidth > parentWidth){
        $('.scrollable-toolbar-left').removeClass('inithide');
        $('.scrollable-toolbar-right').removeClass('inithide');
    }

    $('.tab-toolbar').css('width', totalWidth + 50);
}

function scrollableToolbar() {

    $(document).on('click', '.scrollable-toolbar-left', function(e){
        $('.scrollable-toolbar').animate({
            scrollLeft: '-='+20000
        }, 2000);
    });

    $(document).on('click', '.scrollable-toolbar-right', function(e){
        $('.scrollable-toolbar').animate({
            scrollLeft: '+='+20000
        }, 2000);
    });
}

function cardRowShowMore(){
  $('.card.paper').each(function(e){
    var rowAmount = $(this).children('.card-row').length;
    if(rowAmount > 5){
        var count = rowAmount - 5;
        $(this).find('.toggle-card-rows').show();
        $(this).find('.show-more-rows-onclick').attr('hiddenCount', count);
        hideExcessiveRows(this);
    }

  });

  $(document).on('click', '.show-more-rows-onclick', function(e){
     e.preventDefault();
     var parent = $(this).parent().parent();

     $(parent).children('.card-row').removeClass('hidden');

     $(this).removeClass('show-more-rows-onclick');
     $(this).addClass('hide-rows-onclick');
     $(this).find('i').removeClass('fa-chevron-down');
     $(this).find('i').addClass('fa-chevron-up');

  });

  $(document).on('click', '.hide-rows-onclick', function(e){
      e.preventDefault();

      var parent = $(this).parent().parent();
      hideExcessiveRows(parent);

      $(this).removeClass('hide-rows-onclick');
      $(this).addClass('show-more-rows-onclick');
      $(this).find('i').removeClass('fa-chevron-up');
      $(this).find('i').addClass('fa-chevron-down');

  });
}

function hideExcessiveRows(element){
    var count = 0;
    $(element).children('.card-row').each(function(){
        if(count >= 5){
            $(this).addClass('hidden');
        }
        count++;
    });

    $(element).find('.show-more-rows').show();
}

function deleteUser(){

    $(document).on('click', '.delete-user', function(e){
        e.preventDefault();

        var userId = $(this).data('userid');

        var answer = confirm('Are you sure you want to delete this user? This change cannot be undone.');

        if(answer){
            var url = '/user/ajax-delete';
            var data = {
                'userId': userId
            }

            ajax.deleteCall(url, data, callback);

            function callback(response){
                if(response['delete'] == 'success'){
                    $('#'+userId).remove();
                }
            }
        }
    });
}

function selectAll(){

    // All checkboxes
    $(document).on('click', '.mass-checkbox-all', function(e){
        var container = $(this).closest('.check-all-container');

        if($(this).prop('checked')){
            $(container).find('.mass-checkbox').prop('checked', true);
        } else {
            $(container).find('.mass-checkbox').prop('checked', false);
        }
    });

    // All selects
    $(document).on('click', '.select-all', function(e){
        e.preventDefault();
        var select2 = $(this).parent().parent().find('.select2');
        $(select2).find('option').each(function(){
            $(this).prop('selected', true);
        });

        select2.select2();
        $(this).hide();
        $('.unselect-all').css('display', 'inline-block');
    });

    $(document).on('click', '.unselect-all', function(e){
        e.preventDefault();
        var select2 = $(this).parent().parent().find('.select2');
        $(select2).find('option').each(function(){
            $(this).prop('selected', false);
        });

        select2.select2();
        $(this).hide();
        $('.select-all').css('display', 'inline-block');
    });

    // @TODO: set on a generic place
    if($('.select-all').length > zero){

        $('.form-group').each(function(){
            $(this).find('.form-control.select2').each(function(){

                // check if all boxes are selected
                var options = $(this).find('option').length;
                var selectedOptions = $(this).find('option:selected').length;

                if(options == selectedOptions && options >= 1){
                    $(this).parent().find('.select-all').css('display', 'none');
                    $(this).parent().find('.unselect-all').css('display', 'inline-block');
                } else {
                    $(this).parent().find('.unselect-all').css('display', 'none');
                    $(this).parent().find('.select-all').css('display', 'inline-block');
                }

            });

        });
    }
}

function scrollToNavigation(){
    $(document).on('click', 'a[role="scroll-to"]', function(e){
        // Reset the borders for all other items first
        $('a[role="scroll-to"]').each(function(){
            $(document).find($(this).attr('href')).css('border-bottom', '0px');
        });

        e.preventDefault();
        var divId = $(this).attr('href');
        var div = $(document).find(divId);
        var divTop = div.offset();

        var scrollTo = divTop.top - hundred;

        $(this).parent().parent().find('li').removeClass('active');
        $(this).parent().addClass('active');
        $(div).css('border-bottom', '4px solid #00344f');
        $('html, body').animate({scrollTop: scrollTo}, 1000);

    });
}

function submitToolbar(){
    $(document).on('click', 'a[role="submit"]', function(e){
        e.preventDefault();
        var formId = $(this).attr('href');
        var count = zero;
        var errorItem = '';

        $(document).find('input').each(function(){
            if($(this).attr('required')){
                if($(this).val() === ''){
                    errorItem = this;
                    $(this).next('small').text('This field is required.');
                    count++;
                } else {
                    $(this).next('small').text('');
                }
            }

        });

        $(document).find('select').each(function(){
          if($(this).attr('required')){
            var selectedValue = $(this).find('option:selected').val();
            if(selectedValue === zero || selectedValue === minusOne){
              $(this).next('small').text('Select an option');
              count++;
            } else {
              $(this).next('small').text('');
            }
          }
        });

        var parent = $(errorItem).parent().parent().parent().parent();

        if(count > zero){
            var scrollTo = $(parent).offset().top;
            $(parent).css('border-bottom', '4px solid #d32f2f');
            $('html, body').animate({scrollTop: scrollTo - hundred}, 1000);
        }

        if(count === zero){
            $(document).find(formId).submit();
        }

    });
}

function closePopup(){
    $(document).on('click', '.close-popup-modal', function(e){
        $('.popup-modal').removeClass('fade').removeClass('in').fadeOut();
    });
}
