/*
 * Js > Charts
 */

//----------------------------------------------------------------------
// Dependencies
//----------------------------------------------------------------------
var $ = jQuery = require('jquery');
var ajax = require('./ajax');
var moment = require('moment');
var Chart = require('chart.js');

//
var colorList = [];
var shipData = [];
var shipLabels = [];
//----------------------------------------------------------------------
// Exports
//----------------------------------------------------------------------
module.exports = {

    init: function(){
      if($('#myChartMonth').length > 0){
        barChartInit();
        monthLeft();
        monthRight();
      }
        // pieChartInit();
    }
};

//----------------------------------------------------------------------
// Functions

//----------------------------------------------------------------------
function monthLeft(){
  $('#monthLeft').click(function(e){
      var olddate = $('#monthTitel').attr('value');
      console.log(olddate);
      var date = moment(olddate, "DD-MM-YYYY").subtract(1, 'months');
      $('#monthTitel').attr('value', date.format("DD-MM-YYYY"));
      $('#monthTitel').text(date.format("MMMM YYYY"));

      myChart.destroy();


      barChartMonth();
  });
}

function monthRight(){
  $('#monthRight').click(function(e){
    var olddate = $('#monthTitel').attr('value')
    console.log(olddate);
    var date = moment(olddate, "DD-MM-YYYY").add(1, 'months');
    $('#monthTitel').attr('value', date.format("DD-MM-YYYY"));
    $('#monthTitel').text(date.format("MMMM YYYY"));

    myChart.destroy();

    barChartMonth();
  });
}

function barChartInit(){
  $("#myChartMonth").ready(function() {
    barChartMonth();
  });
}

var myChart;

function barChartMonth(){
  var ctx = document.getElementById("myChartMonth");

  var date = moment($('#monthTitel').attr('value'), "DD-MM-YYYY").format("DD-MM-YYYY");
  var dataList = [];
  var dataObjectCount = 0;

  var data = {
      'date': date
  };



  ajax.jsonCall('/user/revenue-by-month', data, callback);

  function callback(response) {

      dataList = response[1];
      dataObjectCount = dataList.length;

    myChart = new Chart(ctx, {
        type: 'bar',
        data: {
            labels: response[0],
            datasets: [{
                label: 'Expected revenue',
                data: response[1],
                borderWidth: 1,
                backgroundColor: generateColors(dataObjectCount)
            }]
        },
      });
    }
}

//----------------------------------------------------------------------
//> Finance Report
//----------------------------------------------------------------------

var dynamicColors = function() {
    var r = Math.floor(Math.random() * 255);
    var g = Math.floor(Math.random() * 255);
    var b = Math.floor(Math.random() * 255);
    return "rgb(" + r + "," + g + "," + b + ")";
}

var generateColors = function(dataObjectCount) {
    var i = 0;
    while(i < dataObjectCount)
    {
        colorList.push(dynamicColors());
        i++;
    }
    return colorList;
}
//
// function firstPieChart() {
//     var ctx = document.getElementById("firstPieChart");
//     startDate = $('.start').val();
//     endDate = $('.end').val();
//
//     var dataList = [];
//     var labels =[];
//     var dataObjectCount = 0;
//     var data = {
//                 'start' :startDate,
//                 'end'   :endDate
//               };
//     var url = '/finance/report/ajax/get-ship-revenue';
//     ajax.jsonCall(url, data, callback);
//
//     function callback(response){
//
//         dataList = response.data;
//         labels = response.labels;
//
//         dataObjectCount = dataList.length;
//         var myFirstPie = new Chart(ctx, {
//             type: 'pie',
//             data: {
//             labels: labels,
//             datasets: [
//                 {
//                     data: dataList,
//                     backgroundColor: generateColors(dataObjectCount)
//                 }]
//             }
//         });
//
//     }
//
//
//     var data = {
//                 'start' :startDate,
//                 'end'   :endDate
//               };
//
//     var url = '/finance/report/ajax/get-ship-summery';
//     ajax.bladeCall(url, data, callback2);
//
//     function callback2(response)
//     {
//       $('#ship-revenue-summ').html(response);
//     }
//
//     var data = {};
//     var url = '/finance/report/ajax/get-allotment-revenue';
//     ajax.bladeCall(url, data, callback3);
//
//     function callback3(response)
//     {
//       $('#allotment-revenue-container').html(response);
//     }
// }
//
// function pieChartInit(){
//     $("#firstPieChart").ready(function() {
//           //get ship revenue
//
//
//         firstPieChart();
//
//
//     });
// }
