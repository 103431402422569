
//----------------------------------------------------------------------
// Child includes
//----------------------------------------------------------------------
var shipEvents = require('./events');

module.exports = {
    'init': function(){
        shipEvents.init();
    }
};
