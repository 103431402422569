//----------------------------------------------------------------------
// Dependencies global
//----------------------------------------------------------------------
var $         = jQuery = require('jquery');
var bootstrap = require('bootstrap');
var select2   = require('select2');
var ajax      = require('../ajax');

//----------------------------------------------------------------------
// Exports
//----------------------------------------------------------------------
module.exports = {
    'init': function(){
        init();
    }
};

//----------------------------------------------------------------------
// Functions
//----------------------------------------------------------------------
function init(){
    //initial even bindings
    partnerAllotmentCreateEvents();

    //refresh events when cruise dates are reloaded from ajax
    $('.cruise-dates-container').change(function (e) {
        e.preventDefault();
        partnerAllotmentCreateEvents();
    });
}

/*
|--------------------------------------------------------------------------
| Deprecated to Contract
|--------------------------------------------------------------------------
| Specific events used in partner > allotment > create page
|
*/
function partnerAllotmentCreateEvents(){
    //----------------------------------------------------------------------
    // >> Click Events
    //----------------------------------------------------------------------
    // mass checkbox
    $('.cb-allotment-mass-check').click(function (e) {
        e.preventDefault();

        if($(this).is(':checked')){
            $('.allotment-cruisedate-checkbox').prop('checked', true);
        }
        else{
            $('.allotment-cruisedate-checkbox').prop('checked', false);
        }
    });

    //mass cabins-flexible assignment via modal
    $('.btn-mass-assign-cabins-flexible').click(function (e) {
        e.preventDefault();

        var massCabinValue = $('input[name="mass-cabin-flexible"]').val();
        $('.allotment-total-flexible').val(massCabinValue);

        $('.modal').modal('hide');
    });

    //mass cabins-guarantee assignment via modal
    $('.btn-mass-assign-cabins-guarantee').click(function (e) {
        e.preventDefault();

        var massCabinValue = $('input[name="mass-cabin-guarantee"]').val();
        $('.allotment-total-guarantee').val(massCabinValue);

        //show all inputs
        $('.allotment-cabins-row select').removeAttr('disabled');
        //sync cabins
        var massCabinOptions =  $('.mass-cabinnumbers-guarantee option:selected');

        var selects = $('.allotment-cabins-row select');
        for (var i = 0; i < selects.length ; i++) {
            //unselect all currently selection options in sleect
            $(selects[i]).find('option').removeAttr('selected');
            for (var j = 0; j < massCabinOptions.length ; j++) {
                $(selects[i]).find('option[value="' + $(massCabinOptions[j]).val() + '"]').attr('selected', 'selected');
                $(selects[i]).select2();
            }
        }

        $('.allotment-cabins-row').show();
        $('.modal').modal('hide');
    });

    //----------------------------------------------------------------------
    // >> Change events
    //----------------------------------------------------------------------
    //guarantee input amount on change binding
    $('.cabins-guarantee-select').select2();
    $('.allotment-total-guarantee').change(function (e) {
        e.preventDefault();

        var changeVal = $(this).val();
        var cruiseDateId = $(this).data('cruisedate-id');

        if(changeVal > 0) {
            $('.allotment-cabins-row-' + cruiseDateId + " select").removeAttr('disabled');
            $('.allotment-cabins-row-' + cruiseDateId + " select").select2({ maximumSelectionLength: changeVal});
            $('.allotment-cabins-row-' + cruiseDateId).show();
        }
        else {
            // console.log(changeVal);
            $('.allotment-cabins-row-' + cruiseDateId + ' select').select2('destroy');
            $('.allotment-cabins-row-' + cruiseDateId).hide();

        }
    });

    //modal mass cabins guarantee input on change
    $('.mass-cabin-input').change(function (e) {
        e.preventDefault();

        var amount = $(this).val();
        $('.mass-cabinnumbers-guarantee').select2({ maximumSelectionLength: amount});

    });

    // cabin type select change
    $('select[name="cabin_type_id"]').change(function(e) {
        var partner_id    = $('input[name="partner_id"]').val();
        var cruise_id     = $('input[name="cruise_id"]').val();
        var cabin_type_id = $(this).val();
        var data          = {
            'partner_id': partner_id,
            'cruise_id': cruise_id,
            'cabin_type_id': cabin_type_id
        };

        ajax.bladeCall('/partner/ajax/get-cruisedates-by-cabintype', data, callback);

        function callback(response){
            $('.cruise-dates-container').html(response);
            $('.table-partner-allotment').show();
            $('.partner-allotment-actions').show();

            //duplicate options from cabins select
            var cabinSelect = $('.cabins-guarantee-select').first();
            var options = $(cabinSelect).find('option').clone();

            if(options.length){
                //remove any existiong option
                $('.mass-cabinnumbers-guarantee option').remove();
                $('.mass-cabinnumbers-guarantee').append(options);
                $('.mass-cabinnumbers-guarantee').select2();
            }
        }
    });

}