/*
 * Reservation > Typeahead
 */

//----------------------------------------------------------------------
// Dependencies
//----------------------------------------------------------------------
var $          = jQuery = require('jquery')
var typeahead  = require('typeahead');
var Bloodhound = require('bloodhound');
var Handlebars = require('handlebars')
var reservationHelper = require('./helper');

module.exports = {
    'init': function(){
        init();
    },
    'passengerBloodhound': function(){
        return passengerBloodhound();
    },
    'passengerTypeahead': function(){
        return passengerTypeahead();
    },
    'findPassengerBloodhound':function(){
       return findPassengerBloodhound();
    },
    'findPassengerTypeahead': function() {
        return findPassengerTypeahead();
    }  
};


//----------------------------------------------------------------------
// > Init
//----------------------------------------------------------------------
function init(){
    passengerTypeahead();
    findPassengerTypeahead();

}

//----------------------------------------------------------------------
// > Passenger bloodhound
//----------------------------------------------------------------------
function passengerBloodhound(){
    var passengers = new Bloodhound({
        name: 'passengers',
        prefetch: {
            url: '/passenger/json/passengers',
            cache: false
        },
        datumTokenizer: function customTokenizer(d) {
            // var firstnameTokens = Bloodhound.tokenizers.whitespace(datum.firstname);
            // var lastnameTokens = Bloodhound.tokenizers.whitespace(datum.lastname);
            var fnTokens = [];
            var lnTokens = [];
            var firstnameTokens = Bloodhound.tokenizers.whitespace(d.firstname);
            $.each(firstnameTokens, function(k, v) {
                var i = 0;
                while ((i + 1) < v.length) {
                    fnTokens.push(v.substr(i, v.length));
                    i++;
                }
            });
            var lastnameTokens = Bloodhound.tokenizers.whitespace(d.lastname);
            $.each(lastnameTokens, function(k, v) {
                var i = 0;
                while ((i + 1) < v.length) {
                    lnTokens.push(v.substr(i, v.length));
                    i++;
                }
            });
            return fnTokens.concat(lnTokens);
        },
        queryTokenizer: Bloodhound.tokenizers.whitespace
    });

    return passengers;
}

//----------------------------------------------------------------------
// > Passenger bloodhound search
//----------------------------------------------------------------------

function findPassengerBloodhound() {
  var engine = new Bloodhound({
     remote: {
         url: '/passenger/find?q=%QUERY%',
         wildcard: '%QUERY%'
     },

     datumTokenizer: Bloodhound.tokenizers.whitespace('q'),
     queryTokenizer: Bloodhound.tokenizers.whitespace
 });

 return engine;
}

//add date of birth.
function findPassengerTypeahead() {
  var passengers = findPassengerBloodhound();
  passengers.initialize(true);

  //activate typahead
  var passengerTypeahead = $('.find-passenger-typeahead').typeahead({
      hint: true,
      highlight: true,
      minLength: 1
  }, {
      name: 'passengers',
      source: passengers.ttAdapter(),
      displayKey: Handlebars.compile('{{lastname}}, {{firstname}}'),
      templates: {
          empty: ['<div style="padding:0 12px;">No match found.</div>'].join('\n'),
          suggestion: Handlebars.compile('<div>{{lastname}}, {{firstname}}</div>')
      }
  }).on('typeahead:selected', function(obj, datum) {
      passengerTypeahead.typeahead('val', '');
      reservationHelper.appendPassenger(datum);
  });

  return passengerTypeahead;


}


//----------------------------------------------------------------------
// > Passenger typeahead
//----------------------------------------------------------------------
function passengerTypeahead(){
    //get bloodhound engine and initialize
    var passengers = passengerBloodhound();
    passengers.initialize(true);

    //activate typahead
    var passengerTypeahead = $('.passenger-typeahead').typeahead({
        hint: true,
        highlight: true,
        minLength: 1
    }, {
        name: 'passengers',
        source: passengers,
        displayKey: Handlebars.compile('{{lastname}}, {{firstname}}'),
        templates: {
            empty: ['<div style="padding:0 12px;">No match found.</div>'].join('\n'),
            suggestion: Handlebars.compile('<div>{{lastname}}, {{firstname}}</div>')
        }
    }).on('typeahead:selected', function(obj, datum) {
        passengerTypeahead.typeahead('val', '');
        reservationHelper.appendPassenger(datum);
    });

    return passengerTypeahead;
}
