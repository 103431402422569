/*
 * Partner > Events
 * @TODO: refactor delete functions
 */

//----------------------------------------------------------------------
// Dependencies global
//----------------------------------------------------------------------
var $ = jQuery = require('jquery');
var bootstrap = require('bootstrap');
var select2 = require('select2');
var ajax = require('../ajax');
var tables = require('../tables');

//----------------------------------------------------------------------
// Resource Model Variables global
//----------------------------------------------------------------------

module.exports = {
    init: function () {
        init();
    }
};

//----------------------------------------------------------------------
// > Init
//----------------------------------------------------------------------
function init() {
    //initial even bindings
    addCruiseDateEvents();
    addSailingEvents();
    addItineraryEvents();
    addSeasonEvents();
    addCruiseEvents();

	filteredCruiseDatesEvents();
}

function addCruiseDateEvents() {

    $('.cb-itinerary-mass-check').click(function (e) {
        if ($(this).is(':checked')) {
            $('.itinerary-cruisedate-checkbox').prop('checked', true);
        }
        else {
            $('.itinerary-cruisedate-checkbox').prop('checked', false);
        }
    });


    $('.btn-cruisedate-remove').click(function (e) {
        e.preventDefault();
        var answer = confirm("Are you sure you want to remove this Cruise Date?");
        if (answer) {
            var cruiseId = $(this).data('cruiseid');
            var cruisedateId = $(this).data('cruisedateid');
            var url = '/cruise/' + cruiseId + '/cruisedate/' + cruisedateId + '/delete';
            var data;
            ajax.deleteCall(url, data, callback);
            function callback(response) {

                if (!response.success) {
                    alert("The Cruise Date is used in a Contract and can therefore not be removed");
                } else {
                    $('#cruisedate-row-' + cruisedateId).hide();

                }
            }
        }
    });

    $(document).on('change', '.create-cruisedate-cruise-select', function(e){
        e.preventDefault();

        var cruise = $(this).val();

        var data = {
            'cruise': cruise
        };

        var url = '/cruise/ajax-get-itineraries';

        ajax.bladeCall(url, data, callback);

        function callback(response){
            $('.itinerary-select-response').html(response);
            $('.itinerary-select').select2();
        }
    });
}

function addItineraryEvents() {

    $('.btn-itinerary-remove').click(function (e) {
        e.preventDefault();

        var answer = confirm("Are you sure you want to remove this Itinerary?");

        if (answer) {
            var id = $(this).data('itineraryid');
            var url = '/itinerary/' + id + '/delete';
            var data;
            $('#itinerary-' + id).hide();
            ajax.deleteCall(url, data, callback);
            function callback(response) {
                if(response.success) {
                    $('#itinerary-' + id).hide();
                }
                else {
                    $('#itinerary-' + id).show();
                    alert('This itinerary is being used and therefore it cannot be deleted');
                }
                //@TODO: check if deletion is really succesfull
//                $('#itinerary-' + id).remove();
            }
        }

    });
}

function addSailingEvents() {


    //EDIT

      $(document).on('change', '.edit-country-select', function(e) {

            var country = $(this).children(':selected').text();

            var data = {
                'country': country
            };

            ajax.jsonCall('/api/helper/cities-by-country', data, callback);

            function callback(response) {

                $('.edit-city-select option').remove();
                $('.edit-city-select').select2({'data': response});
            }
        });



    $('.sailing-edit-btn').click(function (e) {
        e.preventDefault();

        var cruiseID = $(this).data('cruiseid');
        var itineraryID = $(this).data('itineraryid');
        var sailingID = $(this).data('sailingid');

        var url = '/ajax/cruise/' + cruiseID + '/itinerary/' + itineraryID + '/sailing/' + sailingID + '/edit';
        var data;

        ajax.bladeCall(url, data, callback);
        function callback(response) {
            $(response).appendTo('#modal-content-edit');
            $('.edit-country-select').select2();
            $('.edit-city-select').select2();


        }

        $('#edit-itinerary-sailing-modal').modal('show');

    });

    $(document).on('click', '.btn-save-sailing', function (e) {

        $('#edit-itinerary-sailing-form').submit();

    });

    $('#edit-itinerary-sailing-modal').on('hidden.bs.modal', function () {
        $('#modal-content-edit').empty();
    });

    $('.btn-cruisedate-sailing-edit').click(function (e) {
        //e.preventDefault();

        var cruiseID = $(this).data('cruiseid');
        var cruiseDateID = $(this).data('cruisedateid');
        var itineraryID = $(this).data('itineraryid');
        var sailingID = $(this).data('sailingid');


        var url = '/ajax/cruise/' + cruiseID + '/cruisedate/' + cruiseDateID + '/itinerary/' + itineraryID + '/sailing/' + sailingID + '/edit';
        var data;

        ajax.bladeCall(url, data, callback);
        function callback(response) {
            $(response).appendTo('#modal-content-edit');

        }

        $('#edit-itinerary-sailing-modal').modal('show');

    });

    $('.btn-sailing-remove').click(function (e) {
        e.preventDefault();
        var answer = confirm("Are you sure you want to remove this Sailing from the Itinerary?");
        if (answer) {
            var sailingId = $(this).data('sailingid');
            var url = '/sailing/' + sailingId + '/delete';
            var data;
            ajax.deleteCall(url, data, callback);
            function callback(response) {

            }
            $('#sailing-row-' + sailingId).hide();
        }
    });

    //get edit modal input for distance
    $('.btn-itinirary-distance-edit').click(function(e)
    {
        e.preventDefault();
        var itineraryID = $(this).data('itineraryid');
        var distanceID = $(this).data('distanceid');
        var cruiseID = $(this).data('cruiseid');

        var url = '/ajax/cruise/'+cruiseID+'/itinerary/'+itineraryID+'/distance/'+distanceID+'/edit';
        var data;
        ajax.bladeCall(url, data, callback);
        function callback(response) {
            $(response).appendTo('#edit-itinerary-distance-modal-content');
            $('#edit-itinerary-distance-modal').modal('show');
        }


    });

    $('#edit-itinerary-distance-modal').on('hidden.bs.modal', function () {
        $('#edit-itinerary-distance-modal-content').empty();
    });

    $('.btn-itinirary-distance-delete').click(function (e) {
        e.preventDefault();
        var answer = confirm("Are you sure you want to remove this Distance?");
        if (answer) {
            var distanceID = $(this).data('distanceid');
            var url = '/itinerary-distance/'+ distanceID;
            var data;
            ajax.deleteCall(url, data, callback);
            function callback(response) {
                if (!response.success) {
                    alert("The distance cannot be removed");
                } else {
                    $('#itinerary-distance-row-' + distanceID).hide();
                }
            }
        }
    });
}
//season
function addSeasonEvents() {

    $('.btn-season-remove').click(function (e) {
        //e.preventDefault();
        var answer = confirm("Are you sure you want to remove this Season from the Cruise?");
        if (answer) {
            var data;
            var cruiseId = $(this).data('cruiseid');
            var seasonId = $(this).data('seasonid');
            var url = '/cruise/' + cruiseId + '/season/' + seasonId + '/destroy';
            ajax.deleteCall(url, data, callback);
            function callback(response) {
                //do shit
                if (!response.success) {
                    alert("The Season is used in a Contract and can therefore not be removed");
                } else {
                    $('#season-row-' + seasonId).hide();
                }
            }
        }
    });
}

/*
* TODO: fix delete
*/


function addCruiseEvents() {
    $(document).on('click', '.btn-cruise-remove', function (e) {

        e.preventDefault();

        var answer = confirm("Are you sure you want to remove this Cruise?");

        if (answer) {
            var id = $(this).data('cruiseid');
            var url = '/cruise/' + id;
            var data;
            ajax.deleteCall(url, data, callback);
            function callback(response) {
                if (response.success) {
                    $('#cruise-row-' + id).hide();
                } else {
                    alert("this Cruise cannot be removed as it is part of an active contract");
                }
            }
        }

    });
}

function filteredCruiseDatesEvents() {
    $(document).on('keyup', '.search-cruisedate-by-itinerary-number', function(e) {
        var itineraryNumber = $(this).val();

        if (e.keyCode == 13 && itineraryNumber != ''){
            var data = {
                'itinerary_number': itineraryNumber
            };

            var url = 'cruise/cruisedates/ajax/get-cruisedate-by-itinerary_number';
            ajax.jsonCall(url, data, callback);

            function callback(response){
                if(response){
                    window.location.replace(response);
                } else {
                    $('.search-cruisedate-error').text('Cruisedate not found');
                }
            }
        } else if(e.keyCode == 13 && reservationId == ''){
            $('.search-cruisedate-error').text('Please enter a valid itinerary number');
        }
    });

    $(document).on('click', '.search-cruisedates', function(e){
        console.log('clicked');
        e.preventDefault();
        var startDate = $('.cruisedates-startdate').val();
        var endDate = $('.cruisedates-enddate').val();
        var ships = $('.cruisedates-ship').val();
        var cruises = $('.cruisedates-cruise').val();

        if(startDate === ''){
            return false;
        }

        var data = {
            'startDate': startDate,
            'endDate': endDate,
            'ships': ships,
            'cruises': cruises
        };

        var url = '/cruisedates/ajax/get-cruisedates';

        ajax.bladeCall(url, data, callback);

        function callback(response){
            $('.cruisedates-response').html(response);
            $('.cruisedates-title').text('Cruisedates');

            tables.reset('.table-filter-default', '.input-filter-default');
        }
    });

    $(document).on('change', '.cruisedates-ship', function(e){
        var ships = $(this).val();

        var data = {
            'ships': ships
        };

        var url = '/cruise/ajax-get-cruises-by-ships';

        ajax.bladeCall(url, data, callback);

        function callback(response){
            $('.cruisedates-cruise-response').html(response);
            $('.cruisedates-cruise').select2();
        }
    });

}
