var $ = jQuery = require('jquery');
var bootstrap = require('bootstrap');
var select2 = require('select2');
var ajax = require('../../ajax');
var formValidator = require('../../form-validator');
var tables = require('../../tables');

module.exports = {
  init: function(){
    init();
  }
}

const allotmentString = 'Allotment';
const charterString = 'Charter';
const standardString = 'Standard';
const sampleString = 'Sample';
const debitString = 'Debit';
const creditString = 'Credit';
const upfrontString = 'Upfront';
const correctionString = 'Correction';
const cruisedateString = 'cruisedate';
const defaultSelectValue = -1;

function init(){
  createInvoiceEvents();
  createIndividualInvoiceEvents();
  closeTableEvents();
  excursionSentenceEvents();
  updateInvoiceEvents();
  paidStatus();
  filterInvoices();
}

function createInvoiceEvents(){
  $(document).on('change', '.create-invoice-container .partner-select-list', function(e){

    // Always reset all other data
    resetCreateInvoiceData(['type', 'payable-type', 'credit-option', 'contract', 'cruisedate', 'invoices', 'invoice-generate-setting', 'invoice-type', 'submit']);

    if($(this).val() != defaultSelectValue){
      $('.create-invoice-container .type-select-container').show();

      ajaxGetPartnerDetails($(this).val());
    }
  });

  $(document).on('change', '.create-invoice-container .type-select-list', function(e){
    if($(this).val() != defaultSelectValue){

      // Reset all selects and steps beside partner first

      resetCreateInvoiceData(['payable-type', 'credit-option', 'contract', 'cruisedate', 'invoices', 'invoice-generate-setting', 'invoice-type', 'submit']);

      ajaxGetContractSelectList();

      if($(this).val() === allotmentString){
        $('.invoice-type-select-list option[value="Sample"]').remove();
        $('.invoice-type-select-list').append('<option value="Sample">Sample</option>');
      } else {
        $('.invoice-type-select-list option[value="Sample"]').remove();
      }
    }
  });

  $(document).on('change', '.create-invoice-container .contract-select-list', function(e){
    var type = $('.create-invoice-container .type-select-list').val();
    if($(this).val() != defaultSelectValue){

      resetCreateInvoiceData(['invoice-type', 'payable-type', 'cruisedate', 'invoices', 'invoice-generate-setting', 'submit'])

      $('.create-invoice-container .invoice-type-container').show();

      // Always get contract info
      ajaxGetContractDetails();
    }
  });

  $(document).on('change', '.create-invoice-container .invoice-type-select-list', function(e){
    if($(this).val() != defaultSelectValue){
      if($(this).val() === upfrontString){

        // Reset the cruise date select and payable-type
        resetCreateInvoiceData(['cruisedate', 'payable-type', 'invoices', 'submit']);

        // set the payable type to debit
        $('.create-invoice-container .payable-type-select-list').val('Debit');

        ajaxGetInvoiceGenerateSettings();
      } else if($(this).val() === standardString){

        resetCreateInvoiceData(['payable-type', 'invoice-generate-setting', 'invoices', 'submit']);

        $('.create-invoice-container .payable-type-container').show();

      } else if($(this).val() === correctionString || $(this).val() === sampleString){

        // Reset all selects below first
        resetCreateInvoiceData(['payable-type', 'cruisedate', 'credit-option', 'invoice-generate-setting', 'invoices', 'submit']);
        ajaxGetCruiseDates();
      }

      // always reset credit-option select
      resetCreateInvoiceData(['credit-option', 'submit']);

    }
  });

  $(document).on('change', '.create-invoice-container .payable-type-select-list', function(e){
    var payableType = $(this).val();
    var invoiceType = $('.create-invoice-container .invoice-type-select-list').val();
    var type = $('.create-invoice-container .type-select-list').val();

    if(payableType != defaultSelectValue){
      if(invoiceType === upfrontString && payableType === debitString){
        ajaxGetInvoiceGenerateSettings();
        resetCreateInvoiceData(['cruisedate', 'submit']);
      } else if(invoiceType === standardString && payableType === debitString){

        // Reset the invoice select box and credit option select
        resetCreateInvoiceData(['invoices', 'credit-option', 'submit']);

        ajaxGetCruiseDates();
      } else if(payableType === creditString){
        $('.create-invoice-container .credit-option-select-container').show();

        // Reset the cruise date select box
        resetCreateInvoiceData(['cruisedate', 'submit']);

        // ajaxGetInvoices();
      }
    }

  });

  $(document).on('change', '.create-invoice-container .invoice-generate-setting-select-list', function(e){
    if($(this).val() != defaultSelectValue){
      ajaxGetInvoiceGenerateSettingDetails();
      $('.create-invoice-button').addClass('clickable');
    }
  });

  $(document).on('change', '.create-invoice-container .cruisedate-select-list', function(e){
    var type = $('.create-invoice-container .type-select-list').val();
    if($(this).val() != defaultSelectValue){
      ajaxGetCruiseDateDetails($(this).val());

      $('.create-invoice-button').addClass('clickable');

    }
  });

  $(document).on('change', '.create-invoice-container .invoice-select-list', function(e){
    if($(this).val() != defaultSelectValue){
      ajaxGetInvoiceDetails();
      $('.create-invoice-button').addClass('clickable');
    }
  });

  $(document).on('change', '.credit-option-select-list', function(e){
    if($(this).val() != defaultSelectValue){
      if($(this).val() === cruisedateString){

        // reset invoices select list
        resetCreateInvoiceData(['invoices', 'submit']);

        ajaxGetCreditCruiseDates();
      } else {
        // reset cruisedate select list
        resetCreateInvoiceData(['cruisedate', 'submit']);

        ajaxGetCreditableInvoices();
      }
    }
  });

}

function createIndividualInvoiceEvents(){
  $(document).on('change', '.create-individual-invoice-container .entity-select-list', function(e){
    if($(this).val() != defaultSelectValue){
      resetCreateInvoiceData(['label', 'relation', 'partner', 'reservation', 'passenger', 'individual-type', 'cruisedate', 'payable-type', 'allotment-contract', 'charter-contract', 'submit']);
      ajaxGetEntityDetails($(this).val());
      $('.label-select-container').show();
    }
  });


  $(document).on('change', '.create-individual-invoice-container .label-select-list', function(e){
    if($(this).val() != defaultSelectValue){
      resetCreateInvoiceData(['relation', 'partner', 'reservation', 'passenger', 'individual-type', 'cruisedate', 'payable-type', 'allotment-contract', 'charter-contract', 'submit']);
      ajaxGetLabelDetails($(this).val());
      $('.relation-select-container').show();
    }
  });

  $(document).on('change', '.create-individual-invoice-container .relation-select-list', function(e){
    resetCreateInvoiceData(['partner', 'reservation', 'passenger', 'individual-type', 'cruisedate', 'payable-type', 'allotment-contract', 'charter-contract', 'submit']);
    if($(this).val() != defaultSelectValue){
      if($(this).val() == 'Partner'){
        $('.partner-select-container').show();
      } else if($(this).val() == 'Reservation'){
        getAllCruiseDatesHasReservations();
      }
    }
  });

  $(document).on('change', '.create-individual-invoice-container .partner-select-list', function(e){
    resetCreateInvoiceData(['individual-type','cruisedate', 'payable-type', 'allotment-contract', 'charter-contract', 'submit']);
    if($(this).val() != defaultSelectValue){
      $('.create-individual-invoice-container .individual-types-select-container').show();
      ajaxGetPartnerDetails($(this).val(), true);
      $('.payable-type-container').show();
    }
  });

  $(document).on('change', '.create-individual-invoice-container .reservation-select-list', function(e){
    resetCreateInvoiceData(['passenger', 'individual-type', 'payable-type', 'allotment-contract', 'charter-contract', 'submit']);
    if($(this).val() != defaultSelectValue){
      getPassengersByReservationId($(this).val());
    }
  });

  $(document).on('change', '.create-individual-invoice-container .passenger-select-list', function(e){
    resetCreateInvoiceData(['individual-type', 'payable-type', 'allotment-contract', 'charter-contract', 'submit']);
    if($(this).val() != defaultSelectValue){
      ajaxGetPassengerDetails($(this).val());
      $('.payable-type-container').show();
    }
  });

  $(document).on('change', '.create-individual-invoice-container .individual-types-select', function(e){
    resetCreateInvoiceData(['cruisedate', 'payable-type', 'allotment-contract', 'charter-contract']);
    var value = $(this).val();
    var partnerId = $('.create-individual-invoice-container .partner-select-list').val();
    var entityId = $('.create-individual-invoice-container .entity-select-list').val();

    if(value != defaultSelectValue){

      if(value === 'Cruise Date'){
        ajaxGetCruiseDatesByPartnerEntity(partnerId, entityId);
      } else if(value === 'Contract'){
        ajaxGetAllotmentContractsByPartnerEntity(partnerId, entityId);
      } else if(value === 'Charter Contract'){
        ajaxGetCharterContractsByPartnerEntity(partnerId, entityId);
      }
    } else {
      $('.payable-type-container').show();
    }
  });

  $(document).on('change', '.create-individual-invoice-container .cruisedate-select-list', function(e){
    resetCreateInvoiceData(['reservation', 'passenger', 'submit', 'payable-type']);

    var value = $(this).val();
    var relation = $('.relation-select-list').val();

    if(value != defaultSelectValue){
      ajaxGetCruiseDateDetails(value, true);

      if(relation == 'Partner'){
        $('.create-individual-invoice-container .payable-type-container').show();
      } else {
        getReservationsByCruiseDate();
      }

    }
  });

  $(document).on('change', '.create-individual-invoice-container .allotment-contract-select-list', function(e){
    resetCreateInvoiceData(['cruisedate', 'payable-type', 'submit']);
    var value = $(this).val();
    if(value != defaultSelectValue){
      ajaxGetAllotmentContractDetails(value);
      ajaxGetCruiseDatesByAllotmentContract(value);
      $('.create-individual-invoice-container .payable-type-container').show();
    }
  });

  $(document).on('change', '.create-individual-invoice-container .charter-contract-select-list', function(e){
    resetCreateInvoiceData(['cruisedate', 'payable-type', 'submit']);
    var value = $(this).val();
    if(value != defaultSelectValue){
      ajaxGetCharterContractDetails(value);
      ajaxGetCruiseDatesByCharterContract(value);
      $('.create-individual-invoice-container .payable-type-container').show();
    }
  });

  $(document).on('change', '.create-individual-invoice-container .payable-type-select-list', function(e){
    var value = $(this).val();
    if(value != defaultSelectValue){
      if($(document).find('.disable-invoice-button').length == 0){
        $('.create-invoice-button').addClass('clickable');
      }
    } else {
      resetCreateInvoiceData(['submit']);
    }
  });

  $(document).on('click', '.add-individual-invoice-lines', function(e){
    e.preventDefault();

    var validatedRequired = formValidator.validateRequired();

    if(validatedRequired){
      var distanceTax = $('input[name="distance_tax"]:checked').length;
      var taxPercentage = $('input[name="tax_percentage"]').val();
      var description = $('input[name="description"]').val();
      var itemPrice = $('input[name="item_price"]').val();
      var amount = $('input[name="amount"]').val();
      var subTotal = itemPrice * amount;

      $('.invoice-lines-container').removeClass('inithide');
      var dupeRow = $('.invoice-lines-container .invoice-line-row.inithide').clone().removeClass('inithide');

      if(distanceTax > 0){
        $(dupeRow).find('.distance_tax').attr('name', 'invoiceItems[distance_tax][]');
        $(dupeRow).find('.distance_tax').prop('checked', true);
      } else {
        $(dupeRow).find('.distance_tax').attr('type', 'hidden');
        $(dupeRow).find('.distance_tax').attr('name', 'invoiceItems[distance_tax][]');
      }

      $(dupeRow).find('.tax_percentage').attr('value', taxPercentage);
      $(dupeRow).find('.tax_percentage').attr('name', 'invoiceItems[tax_percentage][]');
      $(dupeRow).find('.tax_percentage').prop('readonly', true);

      $(dupeRow).find('.description').attr('value', description);
      $(dupeRow).find('.description').attr('name', 'invoiceItems[description][]');
      $(dupeRow).find('.description').prop('readonly', true);

      $(dupeRow).find('.item_price').attr('value', itemPrice);
      $(dupeRow).find('.item_price').attr('name', 'invoiceItems[item_price][]');
      $(dupeRow).find('.item_price').prop('readonly', true);

      $(dupeRow).find('.amount').attr('value', amount);
      $(dupeRow).find('.amount').attr('name', 'invoiceItems[amount][]');
      $(dupeRow).find('.amount').prop('readonly', true);

      $(dupeRow).find('.sub-total').attr('value', subTotal);
      $(dupeRow).find('.sub-total').attr('name', 'invoiceItems[sub_total][]');
      $(dupeRow).find('.sub-total').prop('readonly', true);

      //append to real container
      dupeRow.appendTo('.invoice-lines-container table tbody');
    }

  });

  $(document).on('click', 'input[name="invoiceItems[distance_tax][]"]', function(e){
    e.preventDefault();
  });

  $(document).on('click', '.remove-invoice-line', function(e){
    e.preventDefault();
    $(this).parent().parent().remove();
  });
}

function ajaxGetContractSelectList(){
  $('.loading-contracts').show();

  var partnerId = $('.create-invoice-container .partner-select-list').find('option:selected').val();
  var type = $('.create-invoice-container .type-select-list').find('option:selected').val();

  var data = {
    'partnerId': partnerId,
    'type': type
  };

  var url = 'ajax-get-contracts';

  ajax.bladeCall(url, data, callback);

  function callback(response){
    $('.contract-response-container').show();
    $('.loading-contracts').hide();
    $('.contract-response-container').html(response);
    $('.create-invoice-container .contract-select-list').select2();
  }
}

function ajaxGetAllotmentContractsByPartnerEntity(partnerId, entityId){
  $('.loading-contracts').show();

  var data = {
    'partnerId': partnerId,
    'entityId': entityId,
  };

  var url = '../ajax-get-allotment-contracts-by-partner-entity';

  ajax.bladeCall(url, data, callback);

  function callback(response){
    $('.loading-contracts').hide();
    $('.create-individual-invoice-container .contract-response-container').html(response);
    $('.allotment-contract-select-list').select2();
    $('.create-individual-invoice-container .contract-response-container').show();
  }

}

function ajaxGetCharterContractsByPartnerEntity(partnerId, entityId){
  $('.loading-charter-contracts').show();

  var data = {
    'partnerId': partnerId,
    'entityId': entityId
  };

  var url = '../ajax-get-charter-contracts-by-partner-entity';

  ajax.bladeCall(url, data, callback);

  function callback(response){
    $('.loading-charter-contracts').hide();
    $('.create-individual-invoice-container .charter-contract-response-container').html(response);
    $('.charter-contract-select-list').select2();
    $('.create-individual-invoice-container .charter-contract-response-container').show();
  }

}

function ajaxGetInvoiceGenerateSettings(){
  $('.loading-invoice-generate-settings').show();

  var contractId = $('.create-invoice-container .contract-select-list').find('option:selected').val();
  var type = $('.create-invoice-container .type-select-list').find('option:selected').val();
  var invoiceType = $('.create-invoice-container .invoice-type-select-list').find('option:selected').val();

  var data = {
    'contractId': contractId,
    'type': type,
    'invoiceType': invoiceType
  };

  var url = 'ajax-get-invoice-generate-settings';

  ajax.bladeCall(url, data, callback);

  function callback(response){
    $('.invoice-generate-settings-response-container').show();
    $('.loading-invoice-generate-settings').hide();
    $('.invoice-generate-settings-response-container').html(response);
    $('.create-invoice-container .invoice-generate-setting-select-list').select2();
  }
}

function ajaxGetCruiseDates(){
  $('.loading-cruisedates').show();

  var type = $('.create-invoice-container .type-select-list').find('option:selected').val();
  var contractId = $('.create-invoice-container .contract-select-list').find('option:selected').val();

  var data = {
    'type': type,
    'contractId': contractId
  };

  var url = 'ajax-get-cruisedates';

  ajax.bladeCall(url, data, callback);

  function callback(response){
    $('.cruise-dates-response-container').show();
    $('.loading-cruisedates').hide();
    $('.cruise-dates-response-container').html(response);
    $('.create-invoice-container .cruisedate-select-list').select2();
  }
}

function ajaxGetCruiseDatesByPartnerEntity(partnerId, entityId){
  $('.loading-cruisedates').show();

  var data = {
    'partnerId': partnerId,
    'entityId': entityId
  };

  var url = '../ajax-get-cruisedates-by-partner-entity';

  ajax.bladeCall(url, data, callback);

  function callback(response){
    $('.cruise-dates-response-container').show();
    $('.loading-cruisedates').hide();
    $('.cruise-dates-response-container').html(response);
    $('.create-individual-invoice-container .cruisedate-select-list').select2();
  }

}

function ajaxGetCruiseDatesByAllotmentContract(contractId){
  $('.loading-cruisedates').show();
  var data = {
    'contractId': contractId
  };

  var url = '../ajax-get-cruisedates-by-allotmentcontract';

  ajax.bladeCall(url, data, callback);

  function callback(response){
    $('.loading-cruisedates').hide();
    $('.create-individual-invoice-container .cruise-dates-response-container').html(response);
    $('.create-individual-invoice-container .cruisedate-select-list').select2();
    $('.create-individual-invoice-container .cruise-dates-response-container').show();
  }

}

function ajaxGetCruiseDatesByCharterContract(charterContractId){
  $('.loading-cruisedates').show();
  var data = {
    'charterContractId': charterContractId
  };

  var url = '../ajax-get-cruisedates-by-chartercontract';

  ajax.bladeCall(url, data, callback);

  function callback(response){
    $('.loading-cruisedates').hide();
    $('.create-individual-invoice-container .cruise-dates-response-container').html(response);
    $('.create-individual-invoice-container .cruisedate-select-list').select2();
    $('.create-individual-invoice-container .cruise-dates-response-container').show();
  }

}

function ajaxGetInvoices(){
  $('.loading-invoices').show();

  var type = $('.create-invoice-container .type-select-list').find('option:selected').val();
  var invoiceType = $('.create-invoice-container .invoice-type-select-list').find('option:selected').val();
  var contractId = $('.create-invoice-container .contract-select-list').find('option:selected').val();

  var data = {
    'type': type,
    'invoiceType': invoiceType,
    'contractId': contractId
  };

  var url = 'ajax-get-invoices';

  ajax.bladeCall(url, data, callback);

  function callback(response){
    $('.invoices-response-container').show();
    $('.loading-invoices').hide();
    $('.invoices-response-container').html(response);
    $('.create-invoice-container .invoice-select-list').select2();
  }
}

function ajaxGetContractsBlade(contracts, type){
  var data = {
    'contracts': contracts
  };

  var url = 'ajax-get-contracts-blade';

  ajax.bladeCall(url, data, callback);

  function callback(response){
    $('.create-invoice-container .contract-response-container').show();
    $('.loading-contracts').hide();
    $('.create-invoice-container .contract-response-container').html(response);
    $('.create-invoice-container .contract-select-list').select2();
  }
}

function ajaxGetCreditCruiseDates(){
  $('.create-invoice-container .loading-cruisedates').show();
  var partnerId = $('.create-invoice-container .partner-select-list').val();
  var contractId = $('.create-invoice-container .contract-select-list').val();
  var type = $('.create-invoice-container .type-select-list').val();

  var data = {
    'partnerId': partnerId,
    'type': type,
    'contractId': contractId
  };

  var url = 'ajax-get-credit-cruisedates';

  ajax.bladeCall(url, data, callback);

  function callback(response){
    $('.create-invoice-container .loading-cruisedates').hide();
    $('.create-invoice-container .cruise-dates-response-container').show();
    $('.create-invoice-container .cruise-dates-response-container').html(response);
    $('.create-invoice-container .cruisedate-select-list').select2();
  }
}

function ajaxGetCreditableInvoices(){
  $('.create-invoice-container .loading-invoices').show();
  var partnerId = $('.create-invoice-container .partner-select-list').val();
  var contractId = $('.create-invoice-container .contract-select-list').val();
  var type = $('.create-invoice-container .type-select-list').val();

  var data = {
    'partnerId': partnerId,
    'type': type,
    'contractId': contractId
  };

  var url = 'ajax-get-creditable-invoices';

  ajax.bladeCall(url, data, callback);

  function callback(response){
    $('.create-invoice-container .loading-invoices').hide();
    $('.create-invoice-container .invoices-response-container').show();
    $('.create-invoice-container .invoices-response-container').html(response);
    $('.create-invoice-container .invoice-select-list').select2();
  }
}

function ajaxGetInvoiceGenerateSettingCruiseDates(){
  $('.create-invoice-container .loading-cruisedates').show();
  var invoiceGenerateSettingId = $('.create-invoice-container .invoice-generate-setting-select-list').val();

  var data = {
    'invoiceGenerateSettingId': invoiceGenerateSettingId
  };

  var url = 'ajax-get-invoice-generate-setting-cruisedates';

  ajax.bladeCall(url, data, callback);

  function callback(response){
    $('.create-invoice-container .loading-cruisedates').hide();
    $('.create-invoice-container .cruise-dates-response-container').show();
    $('.create-invoice-container .cruise-dates-response-container').html(response);
    $('.create-invoice-container .cruisedate-select-list').select2();
  }
}


/* Detail calls */

function ajaxGetEntityDetails(entityId){
    $('.entity-info-loader').show();

    var data = {
      'entityId': entityId
    };

    var url = '../ajax-get-entity-details';

    ajax.bladeCall(url, data, callback);

    function callback(response){
      $('.entity-info-loader').hide();
      $('.invoice-information-container .entity-info').fadeIn();
      $('.invoice-information-container .entity-info').html(response);
    }

}

function ajaxGetLabelDetails(labelId){
  $('.label-info-loader').show();

  var data = {
    'label_id': labelId
  };

  var url = '../ajax-get-label-details';

  ajax.bladeCall(url, data, callback);

  function callback(response){
    $('.label-info-loader').hide();
    $('.invoice-information-container .label-invoice-info').html(response);
    $('.invoice-information-container .label-invoice-info').fadeIn();
  }

}

function ajaxGetPartnerDetails(partnerId, individual = false){
  $('.partner-info-loader').show();

  var data = {
    'partnerId': partnerId
  };

  if(individual){
    var url = '../ajax-get-partner-details';
  } else {
    var url = 'ajax-get-partner-details';
  }


  ajax.bladeCall(url, data, callback);

  function callback(response){
    $('.partner-info-loader').hide();
    $('.invoice-information-container .partner-info').html(response);
    $('.invoice-information-container .partner-info').fadeIn();
  }
}

function ajaxGetContractDetails(contractId = null){
  $('.contract-info-loader').show();
  if(!contractId){
    var contractId = $('.create-invoice-container .contract-select-list').val();
  }

  var type = $('.create-invoice-container .type-select-list').val();

  var data = {
    'contractId': contractId,
    'type': type
  };

  var url = 'ajax-get-contract-details';

  ajax.bladeCall(url, data, callback);

  function callback(response){
    $('.contract-info-loader').hide();
    $('.invoice-information-container .contract-info').html(response);
    $('.invoice-information-container .contract-info').fadeIn();
  }
}

function ajaxGetAllotmentContractDetails(contractId){
  $('.contract-info-loader').show();
  var data = {
    'contractId': contractId
  };

  var url = '../ajax-get-allotment-contract-details';

  ajax.bladeCall(url, data, callback);

  function callback(response){
    $('.contract-info-loader').hide();
    $('.invoice-information-container .contract-info').html(response);
    $('.invoice-information-container .contract-info').fadeIn();
  }
}

function ajaxGetCharterContractDetails(charterContractId){
  $('.charter-contract-info-loader').show();

  var data = {
    'charterContractId': charterContractId
  };

  var url = '../ajax-get-charter-contract-details';

  ajax.bladeCall(url, data, callback);

  function callback(response){
    $('.charter-contract-info-loader').hide();
    $('.invoice-information-container .charter-contract-info').html(response);
    $('.invoice-information-container .charter-contract-info').fadeIn();
  }
}

function ajaxGetInvoiceGenerateSettingDetails(){
  $('.invoice-generate-setting-info-loader').show();

  var invoiceGenerateSettingId = $('.create-invoice-container .invoice-generate-setting-select-list').val();

  var data = {
    'invoiceGenerateSettingId': invoiceGenerateSettingId
  };

  var url = 'ajax-get-invoice-generate-setting-details';

  ajax.bladeCall(url, data, callback);

  function callback(response){
    $('.invoice-generate-setting-info-loader').hide();
    $('.invoice-information-container .invoice-generate-setting-info').html(response);
    $('.invoice-information-container .invoice-generate-setting-info').fadeIn();
  }
}

function ajaxGetCruiseDateDetails(cruiseDateId, individual = false){
  $('.cruise-date-info-loader').show();

  var data = {
    'cruiseDateId': cruiseDateId
  };

  if(individual){
    var url = '../ajax-get-cruisedate-details';
  } else {
    var url = 'ajax-get-cruisedate-details';
  }

  ajax.bladeCall(url, data, callback);

  function callback(response){
    $('.cruise-date-info-loader').hide();
    $('.invoice-information-container .cruise-date-info').html(response);
    $('.invoice-information-container .cruise-date-info').fadeIn();
  }
}

function ajaxGetInvoiceDetails(){
  $('.invoice-info-loader').show();
  var invoiceIds = $('.create-invoice-container .invoice-select-list').val();

  var data = {
    'invoiceIds': invoiceIds
  };

  var url = 'ajax-get-invoice-details';

  ajax.bladeCall(url, data, callback);

  function callback(response){
    $('.invoice-info-loader').hide();
    $('.invoice-information-container .invoice-info').html(response);
    $('.invoice-information-container .invoice-info').fadeIn();
  }
}

function getAllCruiseDatesHasReservations(){
  $('.loading-cruisedates').show();

  var data = {};

  var url = '../ajax-get-all-cruisedates-has-reservations';

  ajax.bladeCall(url, data, callback);

  function callback(response){
    $('.loading-cruisedates').hide();
    $('.cruise-dates-response-container').html(response);
    $('.cruise-dates-response-container').show();
    $('.cruisedate-select-list').select2();
  }

}

function getReservationsByCruiseDate(){
  $('.loading-reservations').show();

  var cruiseDateId = $('.cruisedate-select-list').val();

  var data = {
    'cruise_date_id': cruiseDateId
  };

  var url = '../ajax-get-reservations-by-cruisedate';

  ajax.bladeCall(url, data, callback);

  function callback(response){
    $('.loading-reservations').hide();
    $('.reservation-response').html(response);
    $('.reservation-select-container').show();
    $('.reservation-select-list').select2();
  }

}

function getPassengersByReservationId(reservationId){
  $('.loading-passengers').show();

  var data = {
    'reservation_id': reservationId,
  };

  var url = '../ajax-get-passengers-by-reservation';

  ajax.bladeCall(url, data, callback);

  function callback(response){
    $('.loading-passengers').hide();
    $('.passenger-response').html(response);
    $('.passenger-select-container').show();
    $('.passenger-select-list').select2();
  }
}

function ajaxGetPassengerDetails(passengerId){
  $('.passenger-info-loader').show();

  var data = {
    'passenger_id': passengerId
  };

  var url = '../ajax-get-passenger-details';

  ajax.bladeCall(url, data, callback);

  function callback(response){
    $('.passenger-info-loader').hide();
    $('.passenger-info').html(response);
    $('.passenger-info').show();
  }

}

/**
 * Close table events
 */
function closeTableEvents(){
  $(document).on('click', '.close-table', function(e){
    $(this).parent().parent().find('table').slideToggle();
    $(this).toggleClass('rotated');
  });
}

/**
 * Reset create invoice data
 * @param Array resetList
 */
function resetCreateInvoiceData(resetList){

  // Reset payable_type
  if(resetList.indexOf('payable-type') !== defaultSelectValue){
    $('.payable-type-container').hide();
    $('.payable-type-select-list').val(defaultSelectValue);
    $('.payable-type-select-list').select2();
  }

  // Reset cruisedate
  if(resetList.indexOf('cruisedate') !== defaultSelectValue){
    $('.cruise-dates-response-container').hide();
    $('.invoice-information-container .cruise-date-info').hide();
    $('.cruisedate-select-list').val(defaultSelectValue);
    $('.cruisedate-select-list').select2();

  }

  // Reset credit option
  if(resetList.indexOf('credit-option') !== defaultSelectValue){
    $('.create-invoice-container .credit-option-select-container').hide();
    $('.create-invoice-container .credit-option-select-list').val(defaultSelectValue);
    $('.create-invoice-container .credit-option-select-list').select2();
  }

  // Reset invoice generate setting
  if(resetList.indexOf('invoice-generate-setting') !== defaultSelectValue){
    $('.create-invoice-container .invoice-generate-settings-response-container').hide();
    $('.invoice-information-container .invoice-generate-setting-info').hide();
    $('.create-invoice-container .invoice-generate-setting-select-list').val(defaultSelectValue);
    $('.create-invoice-container .invoice-generate-setting-select-list').select2();
  }

  // reset invoices
  if(resetList.indexOf('invoices') !== defaultSelectValue){
    $('.create-invoice-container .invoices-response-container').hide();
    $('.invoice-information-container .invoice-info').hide();
    $('.create-invoice-container .invoice-select-list').val(defaultSelectValue);
    $('.create-invoice-container .invoice-select-list').select2();
  }

  // Reset contract
  if(resetList.indexOf('contract') !== defaultSelectValue){
    $('.create-invoice-container .contract-response-container').hide();
    $('.invoice-information-container .contract-info').hide();
    $('.create-invoice-container .contract-select-list').val(defaultSelectValue);
    $('.create-invoice-container .contract-select-list').select2();
  }

  // Reset allotment contract
  if(resetList.indexOf('allotment-contract') !== defaultSelectValue){
    $('.contract-response-container').hide();
    $('.invoice-information-container .contract-info').hide();
    $('.allotment-contract-select-list').val(defaultSelectValue);
    $('.allotment-contract-select-list').select2();
  }

  // Reset charter contract
  if(resetList.indexOf('charter-contract') !== defaultSelectValue){
    $('.charter-contract-response-container').hide();
    $('.invoice-information-container .charter-contract-info').hide();
    $('.charter-contract-select-list').val(defaultSelectValue);
    $('.charter-contract-select-list').select2();
  }

  // Reset invoice type
  if(resetList.indexOf('invoice-type') !== defaultSelectValue){
    $('.create-invoice-container .invoice-type-container').hide();
    $('.create-invoice-container .invoice-type-select-list').val(defaultSelectValue);
    $('.create-invoice-container .invoice-type-select-list').select2();
  }

  if(resetList.indexOf('type') !== defaultSelectValue){
    $('.create-invoice-container .type-select-container').hide();
    $('.create-invoice-container .type-select-list').val(defaultSelectValue);
    $('.create-invoice-container .type-select-list').select2();
  }

  if(resetList.indexOf('partner') !== defaultSelectValue){
    $('.invoice-information-container .partner-info').hide();
    $('.create-individual-invoice-container .partner-select-container').hide();
    $('.partner-select-list').val(defaultSelectValue);
    $('.partner-select-list').select2();
  }

  if(resetList.indexOf('label') !== defaultSelectValue){
    $('.invoice-information-container .label-invoice-info').hide();
    $('.label-select-list').val(defaultSelectValue);
    $('.label-select-list').select2();
  }

  if(resetList.indexOf('reservation') !== defaultSelectValue){
    $('.reservation-select-container').hide();
    $('.reservation-select-list').val(defaultSelectValue);
    $('.reservation-select-list').select2();
  }

  if(resetList.indexOf('relation') !== defaultSelectValue){
    $('.relation-select-container').hide();
    $('.relation-select-list').val(defaultSelectValue);
    $('.relation-select-list').select2();
  }

  if(resetList.indexOf('passenger') !== defaultSelectValue){
    $('.passenger-select-container').hide();
    $('.passenger-info').hide();
    $('.passenger-select-list').val(defaultSelectValue);
    $('.passenger-select-list').select2();
  }

  if(resetList.indexOf('submit') !== defaultSelectValue){
    $('.create-invoice-button').removeClass('clickable');
  }

  if(resetList.indexOf('individual-type') !== defaultSelectValue){
    $('.individual-types-select-container').hide();
    $('.individual-types-select').val(defaultSelectValue);
    $('.individual-types-select').select2();
  }

}

function excursionSentenceEvents(){
  $(document).on('click', '.edit-excursion-sentence', function(e){
    e.preventDefault();
    $('.excursion-sentence').removeAttr('disabled');
    $('.excustion-sentence-submit').show();
  });
}

function updateInvoiceEvents(){
  $(document).on('click', '.ajax-edit-extra-discount', function(e){
    e.preventDefault();
    var invoiceId = $(this).data('invoiceid');
    var invoiceItemId = $(this).data('invoiceitemid');
    var targetModal = $(this).data('modal');

    var data = {
      'invoice_id': invoiceId,
      'invoice_item_id': invoiceItemId,
    };

    var url = '/finance/invoice/ajax-edit-extra-discount';

    ajax.bladeCall(url, data, callback);

    function callback(response){
      $('.edit-extra-discount-response').html(response);
      $(targetModal).modal('show');
    }

  });

  $(document).on('change, keyup', '.extra-discount-item-price, .extra-discount-amount', function(e){
    var itemPrice = $('.extra-discount-item-price').val();
    var amount = $('.extra-discount-amount').val();
    var subtotal = itemPrice * amount;

    $('.extra-discount-subtotal').val(subtotal);
  });

  // edit individual terms functions
  editRemark();
  editPaxTerms();
  editTravelTaxTerms();
  editPackageTerms();
  editInvoiceTerms();
}

function editRemark(){
  $(document).on('click', '.edit-remark', function(e){
    e.preventDefault();

    $('.edit-remark-container').show();
    $('.remark-info').hide();
  });

  $(document).on('click', '.cancel-edit-remark', function(e){
    e.preventDefault();

    $('.edit-remark-container').hide();
    $('.remark-info').show();
  });
}

function editPaxTerms(){
  $(document).on('click', '.edit-pax-terms', function(e){
    e.preventDefault();

    $('.edit-pax-terms-container').show();
    $('.pax-terms-info').hide();
  });

  $(document).on('click', '.cancel-edit-pax-terms', function(e){
    e.preventDefault();

    $('.edit-pax-terms-container').hide();
    $('.pax-terms-info').show();
  });
}

function editTravelTaxTerms(){
  $(document).on('click', '.edit-travel-tax-terms', function(e){
    e.preventDefault();

    $('.edit-travel-tax-terms-container').show();
    $('.travel-tax-terms-info').hide();
  });

  $(document).on('click', '.cancel-edit-travel-tax-terms', function(e){
    e.preventDefault();

    $('.edit-travel-tax-terms-container').hide();
    $('.travel-tax-terms-info').show();
  });
}

function editPackageTerms(){
  $(document).on('click', '.edit-package-terms', function(e){
    e.preventDefault();

    $('.edit-package-terms-container').show();
    $('.package-terms-info').hide();
  });

  $(document).on('click', '.cancel-edit-package-terms', function(e){
    e.preventDefault();

    $('.edit-package-terms-container').hide();
    $('.package-terms-info').show();
  });
}

function editInvoiceTerms(){
  $(document).on('click', '.edit-invoice-terms', function(e){
    e.preventDefault();

    $('.edit-invoice-terms-container').show();
    $('.invoice-terms-info').hide();
  });

  $(document).on('click', '.cancel-edit-invoice-terms', function(e){
    e.preventDefault();

    $('.edit-invoice-terms-container').hide();
    $('.invoice-terms-info').show();
  });
}

function paidStatus(){
  $(document).on('change', '.ajax-set-paid-status', function(e){
    var paid = $(this).is(":checked");
    var invoiceId = $(this).data('invoice-id');

    var data = {
      'paid': paid,
      'invoiceId': invoiceId
    };

    var url = '/finance/invoice/ajax/update-paid-status';

    ajax.call(url, data, callback);

    function callback(response){

    }
  });
}

function filterInvoices() {
  $(document).on('keyup', '.ajax-filter-invoices', function(e){

    var search = $(this).val();

    if(e.keyCode === 13 && search != ''){
      $('.invoice-filter-loader').show();

      var url = '/finance/invoice/ajax-filter-invoices';

      var data = {
        'search': search
      };

      ajax.bladeCall(url, data, callback);

      function callback(response){
        $('.filter-invoices-response').html(response);
        $('.invoice-filter-loader').hide();

        // Reset datatables
        tables.reset('.filter-invoices-datatable', '.input-filter-invoices');
      }
    }
  });
}
