var $ = jQuery = require('jquery');
var ajax = require('./ajax');

var koko = {
	url: 'https://api.wit.ai/message',
	token: 'YFHLFDKAFR5GCT2TC2EAVPOMNVMWBHU3',
	intentUnrecognized: false,
	missingEntity: false,
	previousIntent: null,
	question: '',
	call: true,
	commands: [],
	tasks: [
		'reservering_bekijken',
		'reservering_aanmaken',
		'cruisedate_aanmaken',
		'jaar_omzet_schip',
		'schip_bekijken',
		'groeten',
		'compliment_geven',
		'vertalen',
		'goedemorgen',
	],
	scenarios: []
};

const user = $('.usertype').text();

module.exports = {
	'init': function() {
		kokoEvents();
	}
}

function kokoEvents() {
	$(document).on('click', '.koko-open', function(e) {
		$(this).hide();
		$('.koko-message-container').fadeIn();
	});

	$(document).on('click', '.koko-close', function(e) {
		e.preventDefault();
		$('.koko-message-container').fadeOut();
		$('.koko-open').show();
	});

	$(document).on('click', '.koko-info', function(e) {
		e.preventDefault();
		$('.koko-info-modal').fadeIn();
	});

	$(document).on('keyup', '.koko-input', function(e) {
		$('.user-is-typing').show();
		if ($(this).is(':focus') && e.keyCode == 13) {
			var userInput = $(this).val();

			callApi(userInput);

			var userImage = $('.profile-photo').html();

			var message = '<div class="koko-message clearfix"><div class="message-response"><div class="koko-image-holder">' + userImage + '</div><span class="left">' + userInput + '</span></div></div>';
			$('.convey .koko-message').last().after(message);

			$(this).val('');
			$('.user-is-typing').hide();
		}
	});
}


function callApi(message) {
	$('.koko-is-typing').show();
	if (koko.intentUnrecognized) {
		var message = 'Intentie is: ' + message;
		koko.intentUnrecognized = false;
	}

	if (koko.previousIntent !== null) {
		switch (koko.previousIntent) {
			case "reservering_bekijken":
				if (goToReservationById(message)) {
					koko.reset();
				} else {
					koko.respond(koko.question);
				}
				break;
			default:
				koko.respond(koko.question);
		}
	} else {
		$.ajax({
			url: koko.url,
			data: {
				'q': message,
				'access_token': koko.token
			},
			dataType: 'jsonp',
			method: 'GET',
			success: function(response) {

				if (typeof response.entities.intent !== 'undefined') {
					var intent = response.entities.intent[0]['value'];

					if (isTask(intent)) {
						executeTask(response.entities, intent);
					} else {
						koko.respond('Sorry ik ben hier nog niet in getraind. Ik zal deze aanvraag vanavond bestuderen. ' + String.fromCodePoint(0x1F913));
					}
				} else {
					koko.respond('Sorry ik begrijp dit niet helemaal. Ik zal het vanavond gaan bestuderen. ' + String.fromCodePoint(0x1F913));
				}

			}
		});
	}


}

function isTask(intent) {
	return koko.tasks.includes(intent);
}

function executeTask(entities, intent) {
	if (validateTask(entities, intent)) {
		var fnKoko = koko[intent];
		fnKoko(entities);
	} else {
		koko.respond('Sorry ik kan je taak nog niet verwerken. Ik zal deze vanavond gaan verwerken.' + String.fromCodePoint(0x1F4AA));
	}
}

function validateTask(entities, intent) {
	var valid = false;

	switch (intent) {
		case "reservering_bekijken":
			valid = ('reservation_id' in entities);
			break;
		case "reservering_aanmaken":
			valid = ('partner' in entities) && ('cruise' in entities) && ('itinerary_number' in entities);
			break;
		case "schip_bekijken":
			valid = ('ship_code' in entities);
			break;
		case "jaar_omzet_schip":
			valid = ('ship_code' in entities) && ('year' in entities);
			break;
		case "cruisedate_aanmaken":
			valid = ('cruisedate_start' in entities) && ('cruisedate_end' in entities) && ('cruise' in entities);
			break;
		case "groeten":
			valid = true;
			break;
		case "compliment_geven":
			valid = true;
			break;
		case "vertalen":
			valid = ('taal' in entities) && ('phrase_to_translate' in entities);
			break;
		case "goedemorgen":
			valid = true;
			break;
		default:
	}

	return valid;
}

function kokoRespond(respond) {

	var koko = $('.message-koko-container').html();


	$('.koko-is-typing').hide();
	var message = '<div class="koko-message clearfix"><div class="koko-response"><span class="left">' + respond + '</span>' + koko + '</div></div>';
	$('.convey .koko-message').last().after(message);
	$('.conversation-container').scrollTop($('.conversation-container')[0].scrollHeight);


	// var objDiv = $(document).find('.conversation-container');
	// objDiv.scrollTop = objDiv.scrollHeight;
	$('.conversation-container').scrollTop($('.conversation-container')[0].scrollHeight);

}

function isCommand(message) {

	if (message.substr(0, 1) == '!') {
		var command = message.substr(0, message.indexOf(' '));
		var commandMessage = message.substr(message.indexOf(' ') + 1);

		var command = command.replace('!', '');

		return [
			command,
			commandMessage,
		];

	} else {
		return false;
	}
}

function getCommandResponse(command) {
	// Call our own command api and return the message to the message interface
	var message = 'Dankjewel voor je commando';
	return message;
}

function createCruiseDate(startDate, endDate, cruiseName) {
	var url = '/bot/ajax-create-cruisedate';

	var data = {
		'start_date': startDate,
		'end_date': endDate,
		'cruise_name': cruiseName
	};

	ajax.jsonCall(url, data, callback);

	function callback(response) {
		if (response) {
			koko.respond(response);
		} else {
			koko.respond('Sorry ik kan deze cruisedate niet aanmaken');
		}
	}
}

function getRevenueByShipcodeYear(shipCode, year) {
	var url = '/bot/ajax-get-revenue';

	var data = {
		'ship_code': shipCode,
		'year': year
	};

	ajax.jsonCall(url, data, callback);

	function callback(response) {
		if (response) {
			koko.respond(response);
		} else {
			koko.respond('Sorry ik kan de omzet niet vinden.');
		}

	}
}

function goToShipByShipcode(shipCode) {
	var url = '/bot/ajax-go-to-ship';

	var data = {
		'ship_code': shipCode
	};

	ajax.jsonCall(url, data, callback);

	function callback(response) {
		if (response) {
			redirect(response);
		} else {
			koko.respond('Sorry ik kan het schip niet vinden.')
		}

	}

}

function goToReservationById(reservationId) {
	var url = '/bot/ajax-go-to-reservation';

	var data = {
		'reservation_id': reservationId
	};

	ajax.jsonCall(url, data, callback);

	function callback(response) {
		if (response) {
			redirect(response);
		} else {
			return false;
		}

	}
}

function redirect(location) {
	koko.respond('Ik stuur je door');

	setTimeout(function() {
		window.location = location;
	}, 1500);
}

koko.respond = function(react) {

	var kokoContainer = $('.message-koko-container').html();

	$('.koko-is-typing').hide();
	var message = '<div class="koko-message clearfix"><div class="koko-response">' + kokoContainer + '<span class="right">' + react + '</span></div></div>';
	$('.convey .koko-message').last().after(message);
	$('.conversation-container').scrollTop($('.conversation-container')[0].scrollHeight);

	// var objDiv = $(document).find('.conversation-container');
	// objDiv.scrollTop = objDiv.scrollHeight;
	$('.conversation-container').scrollTop($('.conversation-container')[0].scrollHeight);
}

koko.reset = function() {
	// Used to determine if we have to respond with a follow-up question.
	koko.intentUnrecognized = false;
	koko.missingEntity = false;
	// Used to store previous object to use for creating interaction and allowing to finish a question.
	koko.previousIntent = null;
	koko.question = '';
}

koko.groeten = function(entities) {
	koko.respond('Hoi');
}

koko.reservering_bekijken = function(entities) {
	if (!entities.hasOwnProperty('reservation_id')) {
		koko.question = 'Welk reserveringsnummer?';
		koko.respond(koko.question);
		koko.missingEntity = true;
		koko.previousIntent = entities.intent[0]['value'];
	} else {
		var reservationId = entities.reservation_id[0]['value'];
		goToReservationById(reservationId);
	}
}

koko.leeftijd_opvragen_passagier = function(entities) {
	koko.respond('Hoe oud jij denkt.');
}

koko.schip_bekijken = function(entities) {
	var shipCode = entities.ship_code[0]['value'];
	goToShipByShipcode(shipCode);
}

koko.jaar_omzet_schip = function(entities) {
	var shipCode = entities.ship_code[0]['value'];
	var year = entities.year[0]['value'];
	getRevenueByShipcodeYear(shipCode, year);
}

koko.cruisedate_aanmaken = function(entities) {
	var startDate = entities.cruisedate_start[0]['value'];
	var endDate = entities.cruisedate_end[0]['value'];
	var cruiseName = entities.cruise[0]['value'];
	createCruiseDate(startDate, endDate, cruiseName);
}


// When this fires we will start a reservation scenario
koko.reservering_aanmaken = function(entities) {
	// entities(partner, cruise, cruisedate)
	// koko.call = false;
	// Set down the steps needed to take
	// Step 1: Cabins
	// koko.respond("Beschikbare cabines: Voer de nummers in gescheiden met een komma die je wilt kiezen");
	// Step 2: Passengers
	// koko.respond("Bestaande en/of nieuwe passagiers: Geef aan zoals bestaand: 0, nieuw: 3");
	// Step 3: Create/Select Passengers
	koko.respond('Sorry ik kan op dit moment nog geen reservering maken. Ik laat je weten zodra ik het heb geleerd.' + String.fromCodePoint(0x1F4AA));
}

koko.compliment_geven = function(entities) {
	koko.respond("Bedankt voor je compliment " + user + String.fromCodePoint(0x1F60A));
}

koko.goedemorgen = function(entities) {
	koko.respond("Goedemorgen " + user + String.fromCodePoint(0x1F64C));
}
