//
module.exports = {
    'init': function(){

    },
    'call': function(url, data, callback){
        if (data != null && !data.hasOwnProperty('view')) {
            data['view'] = '';
        }
//        jQuery(".select-loader").show();

        jQuery.ajax({
            url: url,
            type: 'POST',
            data: data,
            complete: function(xhr, textStatus) {
                //called when complete
            },
            success: function(data, textStatus, xhr) {
                callback(data);

//                jQuery(".select-loader").hide();
            },
            error: function(xhr, textStatus, errorThrown) {
                //called when there is an error
            }
        });
    },
    'bladeCall': function(url, data, callback){
        if (data != null && !data.hasOwnProperty('view')) {
            data['view'] = '';
        }

//        jQuery(".select-loader").show();

        jQuery.ajax({
            url: url,
            type: 'POST',
            dataType: 'html',
            data: data,
            complete: function(xhr, textStatus) {
                //called when complete
            },
            success: function(data, textStatus, xhr) {
                callback(data);

                jQuery('.show-more-onclick').each(function(){
                    var all_li = jQuery(this).find('li').length;

                    if(all_li > 1){
                        jQuery(this).find('li:first-child').attr('hiddenCount', all_li - 1).addClass('first-li');
                    }
                });
//                jQuery(".select-loader").hide();
            },
            error: function(xhr, textStatus, errorThrown) {
                //called when there is an error
            }
        });
    },
    'jsonCall': function(url, data, callback){
        if (data != null && !data.hasOwnProperty('view')) {
            data['view'] = '';
        }

//        jQuery(".select-loader").show();

        jQuery.ajax({
            url: url,
            type: 'POST',
            dataType: 'json',
            data: data,
            complete: function(xhr, textStatus) {
                //called when complete
            },
            success: function(data, textStatus, xhr) {
                callback(data);

//                jQuery(".select-loader").hide();
            },
            error: function(xhr, textStatus, errorThrown) {
                //called when there is an error
            }
        });
    },

    'deleteCall': function(url, data, callback){
        jQuery.ajax({
            url:url,
            type:'DELETE',
            dataType:'json',
            data: data,
            complete: function(xhr, textStatus) {

            },
            success: function(data, textStatus, xhr) {
              callback(data);
            },
            error: function(xhr, testStatus, errorThrown) {

            }

        });
    }
};
