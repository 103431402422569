var $ = jQuery = require('jquery');

module.exports = {
  'init': function(){

  },
  'matrixActualTarget': function(labelName, labels, totalRevenue, shipTargets, shipDifference,totalShipsRevenue, totalTargets, totalDifference, responseDiv){
    // @TODO: add the actual array and total + the difference array and total
    var table =
      '<table class="chart-table full-table">'+
      '<tr><td><strong>'+labelName+'</strong></td><td><strong>Actual</strong></td><td><strong>Target</strong></td><td><strong>Difference</strong></td></tr>';

    for(var i = 0; i < labels.length; i++){
      table += '<tr><td>'+labels[i]+'</td><td>&euro; '+totalRevenue[i].toLocaleString('de-DE', {minimumFractionDigits: 2, maximumFractionDigits: 2})+'</td><td>0</td><td>&euro; '+totalRevenue[i].toLocaleString('de-DE', {minimumFractionDigits: 2, maximumFractionDigits: 2})+'</td></tr>';
    }

    table += '<tr class="last"><td class="text-right"><strong>Total</strong></td><td>&euro; '+totalShipsRevenue.toLocaleString('de-DE', {minimumFractionDigits: 2, maximumFractionDigits: 2})+'</td><td>0</td><td>&euro; '+totalShipsRevenue.toLocaleString('de-DE', {minimumFractionDigits: 2, maximumFractionDigits: 2})+'</td></tr>';
    table += '</table>';

    $(responseDiv).html(table);
  },
  'matrixAllotmentCharter': function(labelName, labels, totalRevenue, totalAllotmentRevenue, totalCharterRevenue, totalShipsRevenue, totalShipsAllotmentRevenue, totalShipsCharterRevenue, responseDiv){
    var table =
      '<table class="chart-table full-table">'+
      '<tr><td><strong>'+labelName+'</strong></td><td><strong>Allotment Revenue</strong></td><td><strong>Charter Revenue</strong></td><td><strong>Total Revenue</strong></td><td><strong>Allotment %</strong></td><td><strong>Charter %</strong></td></tr>';

    for(var i = 0; i < labels.length; i++){
      var allotmentPercentage = 0;
      var charterPercentage = 0;

      if(totalAllotmentRevenue[i] > 0 && totalRevenue[i] > 0){
        allotmentPercentage = (totalAllotmentRevenue[i] / totalRevenue[i]) * 100;
      }

      if(totalCharterRevenue[i] > 0 && totalRevenue[i] > 0){
        charterPercentage = (totalCharterRevenue[i] / totalRevenue[i]) * 100;
      }


      table += '<tr><td>'+labels[i]+'</td><td>&euro; '+totalAllotmentRevenue[i].toLocaleString('de-DE', {minimumFractionDigits: 2, maximumFractionDigits: 2})+'</td><td>&euro; '+totalCharterRevenue[i].toLocaleString('de-DE', {minimumFractionDigits: 2, maximumFractionDigits: 2})+'</td><td>&euro; '+
      totalRevenue[i].toLocaleString('de-DE', {minimumFractionDigits: 2, maximumFractionDigits: 2})+'</td><td>'+allotmentPercentage.toLocaleString('en-us', {minimumFractionDigits: 2, maximumFractionDigits: 2})+' &#37;</td><td>'+charterPercentage.toLocaleString('en-us', {minimumFractionDigits: 2, maximumFractionDigits: 2})+' &#37;</td></tr>';
    }

    var allotmentPercentage = 0;
    var charterPercentage = 0;

    if(totalShipsAllotmentRevenue > 0 && totalShipsRevenue > 0){
      allotmentPercentage = (totalShipsAllotmentRevenue / totalShipsRevenue) * 100;
    }

    if(totalShipsCharterRevenue > 0 && totalShipsRevenue > 0){
      charterPercentage = (totalShipsCharterRevenue / totalShipsRevenue) * 100;
    }

    table += '<tr><td class="text-right"><strong>Total</strong></td><td>&euro; '+totalShipsAllotmentRevenue.toLocaleString('de-DE', {minimumFractionDigits: 2, maximumFractionDigits: 2})+'</td><td>&euro; '+totalShipsCharterRevenue.toLocaleString('de-DE', {minimumFractionDigits: 2, maximumFractionDigits: 2})+'</td><td>&euro; '+
    totalShipsRevenue.toLocaleString('de-DE', {minimumFractionDigits: 2, maximumFractionDigits: 2})+'</td><td>'+allotmentPercentage.toLocaleString('en-us', {minimumFractionDigits: 2, maximumFractionDigits: 2})+' &#37;</td><td>'+charterPercentage.toLocaleString('en-us', {minimumFractionDigits: 2, maximumFractionDigits: 2})+' &#37;</td></tr>';

    table += '</table>';

    $(responseDiv).html(table);
  },
  'matrixMonthlyDifference': function(labels, firstYear, secondYear, firstYearRevenue, secondYearRevenue, totalFirstYearRevenue, totalSecondYearRevenue, totalDifference, responseDiv){
    var table = '<table class="chart-table full-table">'+
      '<tr><td><strong>Month</strong></td><td><strong>'+firstYear+'</strong></td><td><strong>'+secondYear+'</strong></td><td><strong>Difference</strong></td></tr>';

    for (var i = 0; i < labels.length; i++){
      var difference = secondYearRevenue[i] - firstYearRevenue[i];

      table += '<tr><td>'+labels[i]+'</td><td>&euro; '+firstYearRevenue[i].toLocaleString('de-DE', {minimumFractionDigits: 2, maximumFractionDigits: 2})+'</td><td>&euro; '+secondYearRevenue[i].toLocaleString('de-DE', {minimumFractionDigits: 2, maximumFractionDigits: 2})+'</td>';
      if(difference > 0){
        table += '<td class="bg-success">';
      } else if(difference < 0){
        table += '<td class="bg-danger">';
      } else {
        table += '<td class="bg-info">';
      }

      table += '&euro; '+difference.toLocaleString('de-DE', {minimumFractionDigits: 2, maximumFractionDigits: 2})+'</td></tr>';
    }

    table += '<tr><td class="text-right"><strong>Total</strong></td><td>&euro; '+totalFirstYearRevenue+'</td><td>&euro; '+totalSecondYearRevenue+'</td>';

    if(parseFloat(totalDifference) > 0){
      table += '<td class="bg-succes">';
    } else if(parseFloat(totalDifference) < 0){
      table += '<td class="bg-danger">';
    } else {
      table += '<td class="bg-info">';
    }

    table += '&euro; '+totalDifference+'</td></tr>';

    $(responseDiv).html(table);
  },
  'matrixMonthRevenue': function(labels, revenue, totalRevenue, responseDiv){
    var table = '<table class="chart-table full-table">';
    table += '<tr><td><strong>Month</strong></td><td><strong>Revenue</strong></td></tr>';

    for(var i = 0; i < labels.length; i++){
        table += '<tr><td>'+labels[i]+'</td><td>€ '+revenue[i].toLocaleString('de-DE', {minimumFractionDigits: 2})+'</td></tr>';
    }

    table += '<tr><td class="text-right"><strong>Total</strong></td><td>€ '+totalRevenue.toLocaleString('de-DE', {minimumFractionDigits: 2})+'</td></tr>';
    table += '</table>';

    $(responseDiv).html(table);
  },
  'cruiseMatrix': function(response){
    $('.cruise-matrix-table').remove();
    var labels = response.labels;
    var pax = response.pax;
    var cruisedates = response.cruisedates;
    var length = labels.length + 1;
    var cruiseDatesCount = cruisedates.length;

    if(cruiseDatesCount > 0){
        var table = '<table class="cruise-matrix-table chart-table full-table">';
        table += '<tr class="no-border"><td colspan="'+length+'" class="text-center">Weeknumbers</td></tr>';
        table += '<tr class="first-row"><td>CruiseDates</td>';
        for (var i = 0; i < labels.length; i++){
            table += '<td>'+labels[i]+'</td>';
        }
        table += '</tr>';

        var i = 0;
        for (var key in pax) {
            if (pax.hasOwnProperty(key)) {
                var quantity = pax[key];
                table += '<tr><td><a href="#" class="cruisedate-pax-growth" data-cruisedate="'+cruisedates[i]+'">'+key+'</a></td>';
                for (var prop in quantity) {
                    if (quantity.hasOwnProperty(prop)) {
                        table += '<td>'+quantity[prop]+'</td>';
                    }
                }
                table += '</tr>';
            }
            i++;
        }

        table += '</table>';

        $('.cruise-matrix-container .table-content').html(table);
    } else {
        var noCruiseDates = '<p>No cruisedates found for given daterange.</p>';
        $('.cruise-matrix-container .table-content').html(noCruiseDates);
    }


    $('.cruise-matrix-container').show();
  }

}
