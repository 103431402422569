/*
 * Passenger > Events
 */

//----------------------------------------------------------------------
// Dependencies global
//----------------------------------------------------------------------
var $           = jQuery = require('jquery');
var bootstrap   = require('bootstrap');
var select2     = require('select2');
var ajax        = require('../ajax');

//----------------------------------------------------------------------
// Resource Model Variables global
//----------------------------------------------------------------------

module.exports = {
    'init': function(){
        init();
    }
};

//----------------------------------------------------------------------
// > Init
//----------------------------------------------------------------------
function init() {
    //initial even bindings
    addTagSelection();
	autoGenderSelect();
}

function addTagSelection() {
    $('.select2tags').select2({
      tags:true
    });
}

function autoGenderSelect()
{
    $(document).on('change', '.passenger-title-select', function(e){
        var title = $(this).val();
        if(title === 'mr.'){
            $('.passenger-gender-select').val('male').select2();
        } else {
            $('.passenger-gender-select').val('female').select2();
        }
    }); 

    $(document).on('change', '.passenger-gender-select', function(e){
        var gender = $(this).val();
        if(gender === 'male'){
            $('.passenger-title-select').val('mr.').select2();
        } else {
            $('.passenger-title-select').val('ms.').select2();
        }
    });
}
