


//----------------------------------------------------------------------
// Dependencies global
//----------------------------------------------------------------------

var $ = jQuery = require('jquery');
var bootstrap = require('bootstrap');
var select2 = require('select2');
var ajax = require('../ajax');
var messageHandler = require('../messageHandler');

//----------------------------------------------------------------------
// Dependencies local
//----------------------------------------------------------------------

module.exports = {
    'init': function () {
        addSelectPartnerEvents();
        addSelectShipEvents();
        addSelectCharterOrderEvents();
        checkSelectBoxes();
        // charterContractSectionsEvents();
        setWysiwygEditor();
        deleteCharterBlock();
        deleteInvoiceGenerateSetting();
        invoiceGenerateSettingEvents();
        changeStatus();
    }
};

//----------------------------------------------------------------------
// Event Functions
//----------------------------------------------------------------------
function addSelectPartnerEvents() {

    $('.chartercontract-partner-select').change(function (event) {
        //get partner id
        console.log('caught event');
        var partnerId = $('.chartercontract-partner-select').val();

        if(partnerId != 0){
            $('.error-container p').remove();
        }

        var data = {
            'partner_id': partnerId
        };
        //make ajax call to server
        ajax.bladeCall('/chartercontract/ajax/get-partner-details', data, callback);

        function callback(response) {
            $('.partner-details-container').html(response);
        }
    });
}

function addSelectShipEvents() {
    $('.chartercontract-ship-select').change(function (event) {
        //get partner id
        console.log('caught event');
        var shipId = $('.chartercontract-ship-select').val();

        var data = {
            'ship_id': shipId
        };

        ajax.bladeCall('/chartercontract/ajax/get-ship-details', data, callback);

        function callback(response) {
            $('.ship-details-container').html(response);
        }

    });
}

function addSelectCharterOrderEvents() {
     $(window).on('load', function (){
          $('.chartercontract-cruise-select').change();

      });

    $('.chartercontract-cruise-select').change(function (event) {
        //get partner id
        //console.log('caught event');
        var cruiseId = $('.chartercontract-cruise-select').val();

        var data = {
            'cruise_id': cruiseId
        };
        //make ajax call to server
        ajax.bladeCall('/chartercontract/ajax/get-cruise-date-table', data, callback);

        function callback(response) {
            $('.charter-cruise-date-select').html(response);
            $('.select2-charter-cruise-dates').select2();
            $('.create-charter-rate').show();
            $('.save-charter-order').show();


        }

    });

    $('.chartercontract-cruise-select-edit').change(function (event) {
        //get partner id
        console.log('caught event');
        var cruiseId = $('.chartercontract-cruise-select-edit').val();

        var data = {
            'cruise_id': cruiseId
        };
        //make ajax call to server
        ajax.bladeCall('/chartercontract/ajax/get-cruise-date-table', data, callback);

        function callback(response) {
            $('.charter-cruise-date-select-edit').html(response);
            $('.select2-charter-cruise-dates').select2();
            $('.create-charter-rate').show();
            $('.save-charter-order').show();
        }

    });

    $('input[type=file]#charter-excel-input').on('change', function(e) {
          $('.selected-file').html('Selected File: '+this.files[0].name);
    });
}

function checkSelectBoxes(){


    $('.check-chartercontract').click(function(e){

        var partner = $('.chartercontract-partner-select').val();
        var entity = $('.chartercontract-entity').val();

        if(partner == 0){
            $('.error-container').append('<p>Select a partner</p>');
            e.preventDefault();
        }
        else {

        }
        if(entity == 0){
            $('.error-container').append('<p>Select a entity</p>');
            e.preventDefault();
        }
        else {

        }
    });

    $('.chartercontract-entity').change(function(e){
        var entity = $('.chartercontract-entity').val();
        if(entity != 0){
            $('.error-container p').remove();
        }
    });
}


function deleteCharterBlock(){
    $(document).on('click', '.delete-charter-block', function(e){
        e.preventDefault();

        var charterBlockId = $(this).data('charterblock');
        var charterContractId = $(this).data('chartercontract');
        var answer = confirm('Are you sure you want to delete this charterblock?');

        if(answer){
            var url = '/chartercontract/'+charterContractId+'/charterblock/ajax-delete';
            var data = {
                'charterBlockId': charterBlockId
            }


            ajax.deleteCall(url, data, callback);

            function callback(response){
                if(response['delete'] == 'success'){
                    $('#charterblock-'+charterBlockId).remove();
                }
            }
        }
    });
}

function setWysiwygEditor(){
    if ($('#ckeditor').length > 0){
        CKEDITOR.replace('ckeditor', {
            height: '750'
        });
    }
    if($('#ckeditor-2').length > 0){
        CKEDITOR.replace('ckeditor-2', {
            height: '750'
        });
    }

	if($('#ckeditor-3').length > 0){
        CKEDITOR.replace('ckeditor-3', {
            height: '750'
        });
    }
}

function deleteInvoiceGenerateSetting(){
    $(document).on('click', '.charter-contract-delete-invoice-generate-setting', function(e){
        e.preventDefault();

        var charterContractId = $(this).data('chartercontract-id');
        var invoiceGenerateSettingId = $(this).data('invoicegeneratesetting-id');
        var answer = confirm('Are you sure you want to delete this payment rule?');

        if(answer){
            var url = '/chartercontract/'+charterContractId+'/invoice-generate-setting/'+invoiceGenerateSettingId+'/destroy';
            var data = {};

            ajax.deleteCall(url, data, callback);
            function callback(response){
                if(response['delete'] == 'success'){
                    $('#invoicegeneratesetting-'+invoiceGenerateSettingId).remove();
                }

                messageHandler.sendMessage(response['delete'], response['message']);
            }

        }
    });
}

function invoiceGenerateSettingEvents(){

}

function changeStatus(){
    $(document).on('change', '.charter-contract-status', function(e){
        var val = $(this).val();

        switch(val){
            case 'approved':
                $('.signed').hide();
                $('.approved').show();
                break;
            case 'signed':
                $('.signed').show();
                $('.approved').hide();
                break;
            default:
                $('.signed').hide();
                $('.approved').hide();
                break;
        }
    });
}
