var $           = jQuery = require('jquery');
var bootstrap   = require('bootstrap');
var select2     = require('select2');
var ajax        = require('../ajax');
var dataTable = require('datatables');


module.exports = {
    init: function () {
        init();
    }
};

function init(){
  addTimeLineEvents();
  initDataTable();
  getShipAvailiabilityByDateRange();
  targetEvents();
}

function initDataTable()
{

}

function addTimeLineEvents()
{
  // google.charts.load("current", {packages:["timeline"]});
  // google.charts.setOnLoadCallback(drawChart);
  // function drawChart() {
  //
  //   var container = document.getElementById('agenda');
  //   var chart = new google.visualization.Timeline(container);
  //   var dataTable = new google.visualization.DataTable();
  //   dataTable.addColumn({ type: 'string', id: 'Room' });
  //   dataTable.addColumn({ type: 'string', id: 'Name' });
  //   dataTable.addColumn({ type: 'date', id: 'Start' });
  //   dataTable.addColumn({ type: 'date', id: 'End' });
  //   dataTable.addRows([
  //     [ 'Magnolia Room', 'Beginning JavaScript',       new Date(0,0,0,12,0,0),  new Date(0,0,0,13,30,0) ],
  //     [ 'Magnolia Room', 'Intermediate JavaScript',    new Date(0,0,0,14,0,0),  new Date(0,0,0,15,30,0) ],
  //     [ 'Magnolia Room', 'Advanced JavaScript',        new Date(0,0,0,16,0,0),  new Date(0,0,0,17,30,0) ],
  //     [ 'Willow Room',   'Beginning Google Charts',    new Date(0,0,0,12,30,0), new Date(0,0,0,14,0,0) ],
  //     [ 'Willow Room',   'Intermediate Google Charts', new Date(0,0,0,14,30,0), new Date(0,0,0,16,0,0) ],
  //     [ 'Willow Room',   'Advanced Google Charts',     new Date(0,0,0,16,30,0), new Date(0,0,0,18,0,0) ]]);
  //
  //   var options = {
  //     timeline: { colorByRowLabel: true }
  //   };
  //
  //   chart.draw(dataTable, options);
  // }
}

function getShipAvailiabilityByDateRange(){
  $(document).on('click', '.set-availability-date', function(e){
    e.preventDefault();

    $('.loader-container').show();
    $('.results').hide();

    var start_date = $('.start-date').val();
    var end_date = $('.end-date').val();
    var ship_id = $('.ship-id').val();

    if(start_date && end_date){

      var url = '/ship/'+ship_id+'/ajax-get-availability-by-date-range';
      var data = {
        'start_date': start_date,
        'end_date': end_date
      }

      ajax.bladeCall(url, data, callback);

      function callback(response){
        $('.ship-availability-container').html(response);
        $('.table-filter-grand').dataTable({
              columnDefs: [{
                  targets: ['no-order'],
                  orderable:false
              }],

              lengthChange: false,
              paging: false,
              order: []
          });
          $('.loader-container').hide();
      }
    }
  });

}

function targetEvents(){

  $(document).on('click', '.ajax-edit-target', function(e){
    var targetId = $(this).data('target-id');

    var data = {
      'target_id': targetId
    };

    var url = '/ship/target/ajax/edit';

    ajax.bladeCall(url, data, callback);

    function callback(response){
      $('.edit-target-response').html(response);
    }
  });

  $(document).on('click', '.ajax-delete-target', function(e){
    e.preventDefault();

    var targetId = $(this).data('target-id');

    var data = {
      'target_id': targetId
    };

    var url = '/ship/target/ajax/destroy';

    ajax.deleteCall(url, data, callback);

    function callback(response){
      $('#ship-target-'+targetId).remove();
    }
  });

}
