/*
 * Js > Datepicker
 */

//----------------------------------------------------------------------
// Dependencies
//----------------------------------------------------------------------
var $ = jQuery = require('jquery');
var datetimepicker = require('datetimepicker');
var moment = require('moment');

//----------------------------------------------------------------------
// Exports
//----------------------------------------------------------------------
module.exports = {
    init: function () {
        initDatepicker();
        initDateTimePicker();
        initStartEndDateTimePicker('.start_datetime-datetimepicker', '.end_datetime-datetimepicker');
        initStartEndTimePicker('.timepicker_start', '.timepicker_end');
        initYearpicker();
        initMonthPicker();
    }
};

//----------------------------------------------------------------------
// Functions
//----------------------------------------------------------------------
function initYearpicker() {
  $('.yearpicker').datetimepicker({
      format:'YYYY'
  });

}

function initMonthPicker() {
    $('.monthpicker').datetimepicker({
        format:'MM'
    });
}

function initDatepicker() {
    $('.datepicker').datetimepicker({
        format: 'DD/MM/YYYY'
    });
}

function initDateTimePicker() {
    $('.datetimepicker').datetimepicker({
        format: 'DD/MM/YYYY HH:mm:ss'
    });
}

function initStartEndDateTimePicker(start, end) {
    $(start).datetimepicker({
        format: 'DD/MM/YYYY HH:mm:ss'
    });
    $(end).datetimepicker({
        format: 'DD/MM/YYYY HH:mm:ss'
    });

    //link start and end datetime pickers
    $(start).on("dp.change", function (e) {
        $(end).data("DateTimePicker").minDate(e.date);
    });
    $(end).on("dp.change", function (e) {
        $(start).data("DateTimePicker").maxDate(e.date);
        //calculate night
        var fromDate = $(start).data("DateTimePicker").date();
        var toDate = $(end).data("DateTimePicker").date();
        var night = toDate.diff(fromDate, 'days');
        //set night
        $('input[name=night]').val(night);
    });

    //link back night to start and end datetimepicker
    $('input[name=night]').blur(function (e) {
        e.preventDefault();
        var night = $(this).val();
        var fromDate = $('.start_datetime-datetimepicker').data("DateTimePicker").date();
        var toDate = fromDate.add(night, 'days');
        var toDate = $('.end_datetime-datetimepicker').data("DateTimePicker").date(toDate);
    });
}

function initStartEndTimePicker(start, end) {
    $(start).datetimepicker({
        format: 'HH:mm'
    });

    $(end).datetimepicker({
        format: 'HH:mm'
    });

    $(start).on("dp.change", function (e) {
        $('.timepicker_strt').data("DateTimePicker");
    });

    $(end).on("dp.change", function (e) {
        $(end).data("DateTimePicker");
    });
}
