var $         = jQuery = require('jquery');
var bootstrap = require('bootstrap');
var select2   = require('select2');


//----------------------------------------------------------------------
// Child includes
//----------------------------------------------------------------------
var passengerEvents = require('./events');

module.exports = {
    'init': function(){
        passengerEvents.init();
    }
};
