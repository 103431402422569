/*
 * Reservation > Events
 */

//----------------------------------------------------------------------
// Dependencies global
//----------------------------------------------------------------------
var $ = jQuery = require('jquery');
var bootstrap = require('bootstrap');
var select2 = require('select2');
var ajax = require('../ajax');

//----------------------------------------------------------------------
// Exports
//----------------------------------------------------------------------
var reservationHelper = require('./helper');

module.exports = {
    init: function() {
        init();
    }
};

//----------------------------------------------------------------------
// Functions
// ----------------------------------------------------------------------

function init() {
    addReservationCreateEvents();
    addReservationEditEvents();
    addReservationPassengerEditEvents();
    addReservationBatchEvents();
    addReservationFilterEvents();
    getAllotmentPackages();
    getNoteValueEvents();
    searchReservationEvents();
}

function getNoteValueEvents() {

    $('.edit-note').click(function(e) {
        e.preventDefault();
        var id = $(this).data('id');
        var title = $(this).data('title');
        var content = $(this).data('content');
        $('#note-id').val(id);
        $('#note-title').val(title);
        $('#note-content').val(content);
        $('#edit-note-modal').modal('show');
    });

}

function addReservationCreateEvents() {

    //ajax - modal - create new passenger during reservation
    $('.btn-create-reservation-passenger').click(function(e) {
        console.log('@passenger create');
        e.preventDefault();
        var cFirstname = $('.ajax-create-passenger input[name="firstname"]').val();
        var cLastname = $('.ajax-create-passenger input[name="lastname"]').val();
        var cDob = $('.ajax-create-passenger input[name="date_of_birth"]').val();
        var cGender = $('.ajax-create-passenger select[name="gender"]').val();
        var cTitle = $('.ajax-create-passenger select[name="title"]').val();
        var cNationality = $('.ajax-create-passenger input[name="nationality"]').val();
        console.log('@title' + cTitle);

        var data = {
            firstname: cFirstname,
            lastname: cLastname,
            date_of_birth: cDob,
            gender: cGender,
            title: cTitle,
            nationality: cNationality
        };

        ajax.jsonCall('/passenger/ajax/create', data, callback);

        function callback(response) {
            reservationHelper.appendPassenger(response);
            //clear and close modal
            var cFirstname = $('.ajax-create-passenger input[name="firstname"]').val('');
            var cLastname = $('.ajax-create-passenger input[name="lastname"]').val('');
            var cDob = $('.ajax-create-passenger input[name="date_of_birth"]').val('');

            $('#create-passenger-modal').modal('hide');
        }
    });

    //on cruise date container change, update cabins card
    $('.cruise-date-container').change(function(e) {
        updateReservationCabins();
    });

    //on partner select change, update cruise dates
    $('.select-reservation-partner').change(function(e) {
        if ($('.select-reservation-cruise').length > 0 && $('.select-reservation-cabin-type').length > 0) {
            fetchCruiseDateTable();
        }
    });

    //on cruise select change, update cruise dates
    $('.select-reservation-cruise').change(function(e) {
        fetchCruiseDateTable();
    });
}

function addReservationEditEvents() {
    //CANCELLATIONS
    $('.select-reservation-status').change(function(e) {

        var status = $(this).find(':selected').html();
        if (status == 'cancelled') {

            var answer = confirm('Are you sure you want to cancel the whole Reservation?');
            if (!answer) {
                e.preventDefault();
            } else {
                $('#insert-reservation-cancel-date').removeClass('inithide');
            }
        }
    });

    //passenger cancellazion
    $('.btn-cabin-set-cancel-date').click(function(e) {
        console.log('cancel');
    });

    //package cancellazion .
    $('.btn-package-cancel').click(function(e) {
        var cabin_id = $(this).data('cabinid');
        var package_id = $(this).data('id');
        var url = '/reservation/passenger/cabin/' + cabin_id + '/package/' + package_id + '/cancel';
        var form = $('.form-cancel-passenger-cabin-reservation');

        form.attr('action', url);
        $('#set-cabin-cancel-date-modal').modal('show');
    });


    // remove cabin

    $(document).on('click', '.remove-cabin-row', function(e) {
        e.preventDefault();

        var cabin_id = $(this).data('cabinid');
        $('#booked_cabins' + cabin_id).remove();

        $(this).parent().parent().remove();
    });


    $(document).on('click', '.btn-package-res-delete', function(e) {
        e.preventDefault();
        console.log('@fixed shiz');
        var ppr_id = $(this).data('id');

        var data = {
            'ppr_id': ppr_id
        };
        var url = '/reservation/passenger/package/delete';
        ajax.deleteCall(url, data, callback);

        function callback(response) {
            if (response.success == true) {
                console.log(response)
            } else {
                alert('Cannot remove the package reservation');
            }
        }


    });

}



function addReservationPassengerEditEvents() {

    $(document).on('change', '.edit-passenger-cabin', function(e) {

        var allotment_id = $('#cabin-type-select option:selected').val();

        var allotment_type = $('#allotment-type-select option:selected').val();
        var reservation_id = $(this).data('reservationid');


        var url = '/reservation/passenger/ajax/get-passenger-cabin-edit';
        var data = {
            'allotment_id': allotment_id,
            'allotment_type': allotment_type,
            'reservation_id': reservation_id
        };

        ajax.bladeCall(url, data, callback);

        function callback(response) {
            $('#passenger-cabin-edit-form-container').html(response);
            $('.select2').select2();
        }
    });


    $(document).on('change', '.retrieve-available-cabins', function(e) {

        console.log("@retrieve cabins");


        // var allotment_id = 0//$('#cabin-type-select option:selected').val();
        //
        // var reservation_id = 0//$(this).data('reservationid');
        //
        // var url = '/reservation/passengers/retrieve-available-cabins-swap';
        //
        // var data = {
        //   'allotment_id': allotment_id,
        //   'reservation_id':reservation_id,
        // }
        //
        // ajax.bladeCall(url, data, callback);
        // function callback(response)
        // {
        //     $('#passenger-cabin-swap-form').html(response);
        //     $('.select2').select2();
        // }



    });

}


//----------------------------------------------------------------------
// Helpers
//----------------------------------------------------------------------

function fetchAvailableCabinsByReservation() {

}

function fetchCruiseDateTable() {
    var partner_id = $('.select-reservation-partner').val();
    var cruise_id = $('.select-reservation-cruise').val();
    var data = {
        partner_id: partner_id,
        cruise_id: cruise_id,
    };

    ajax.bladeCall('/reservation/ajax/get-cruise-date-table', data, callback);

    function callback(response) {
        $('.cruise-date-container').html(response);
        updateReservationCabins();
    }

}

// Update cabins by cruisedate, by checking cabin status and allotment for partner
function updateReservationCabins() {
    $('.reservation__create input:radio[name="cruiseDate_id"]').off().on('click', function(e) {
        //e.preventDefault();

        //get cruiseDateId
        var partnerId = $('.select-reservation-partner').val();
        var cruiseDateId = $(this).val();
        var cruiseId = $('.select-reservation-cruise').val();

        //prepare ajax post data
        var data = {
            "partnerId": partnerId,
            "cruiseId": cruiseId,
            "cruiseDateId": cruiseDateId
        };

        //update cabins for card cabins
        ajax.bladeCall('/reservation/ajax/get-cabins-table', data, callback);

        function callback(response) {
            $('.cabins-card-container').html(response);

            //initialize selects
            initCabinsCardEvents();
            //reservation cabins toggling
            reservationHelper.showCard('.reservation-cabins');
        }


        // Scroll to cabins when a date is selected
        $('html, body').animate({
            scrollTop: $(".reservation-cabins").offset().top
        }, 1000);
    });



}

function getAllotmentPackages() {
    $(document).on('change', '.cabinType-select', function(e) {

        //get cruiseDateId
        var partnerId = $('.select-reservation-partner').val();
        var cruiseDateId = $('input:radio[name="cruiseDate_id"]:checked').val();
        var cruiseId = $('.select-reservation-cruise').val();
        var cabinTypeId = $(this).val();

        //prepare ajax post data
        var data = {
            "partnerId": partnerId,
            "cruiseId": cruiseId,
            "cruiseDateId": cruiseDateId,
            "cabinTypeId": cabinTypeId
        };

        //update available packages for allotment
        ajax.jsonCall('/reservation/ajax/get-allotment-packages', data, callback);
        //
        function callback(response) {
            $('.reservation-passenger-package-select-dummy').empty();

            response.map(function(item) {
                $('.reservation-passenger-package-select-dummy').append('<option value="' + item.id + '">' + item.name + '</option>');
            });
        }
    });
}
//Initialize cabins card events
function initCabinsCardEvents() {

    $('.reservation-cabins .btn-add-cabin').click(function(e) {
        e.preventDefault();

        //collect information
        var cabinType = $('.reservation-cabins .cabinType-select option:selected').text();
        var allotmentType = $('.reservation-cabins .allotmentType-select option:selected').text();
        var cabinNumber = $('.reservation-cabins .cabinNumber-select option:selected').text();
        var cabinId = $('.reservation-cabins .cabinNumber-select option:selected').val();

        //check if booking is allowed
        //ajax call to allotment
        //if not allowed --> show message + do not add cabin and show message modal.

        //ajax.jsonCall('/reservation/cabin/allotment-availability/', data, callbackAvailability);

        //function callbackAvailability(response)
        //{
        /**
         **/
        //}
        if ($('.cabinNumber-select option:selected').val() == -1) {
            alert('Select a Cabin');
        } else {
            //duplicate reservation-cabin-row inithide
            var newItem;
            newItem = $('.reservation-cabin-row.inithide').clone().removeClass('inithide');
            //fill in the dummy fields
            $(newItem).find('.cabin-type').text(cabinType);
            $(newItem).find('.cabin-allotment').text(allotmentType);
            if (allotmentType == 'Guarantee' || allotmentType == 'guarantee' || allotmentType == 'flexible' || allotmentType == 'Flexible') {
                $(newItem).find('.cabin-number').attr('data-cabinid', cabinId);
                $(newItem).find('.cabin-number').text(cabinNumber);
                $(newItem).find('.btn-remove-row').attr('data-cabinid', cabinId);
                $(newItem).addClass('cabin-' + cabinId);
            }

            newItem.appendTo('.reservation-cabins .table-cabins tbody');
            $('.table-cabins .reservation-cabin-row').show();

            //prepare hidden fields
            //hide this cabin number (nice to have)
            $('<input>').attr({
                type: 'hidden',
                id: 'booked_cabins' + cabinId,
                name: 'booked_cabins[]',
                value: cabinId
            }).appendTo('#create-reservation-form');

        }
    });
    // check for not selected passenger cabin
    $('.create-reservation').click(function(e) {
        if ($('.reservation-passenger-cabin-select option:selected').val() == -1) {
            alert('Select a Cabin');
            e.preventDefault();
        }
    });

    //enable select2 on dropdowns
    $('.cabins-card-container .select2').select2();

    //cabinType-select
    $('.cabinType-select').change(function(e) {
        //enable allotmentType-select
        if ($(this).val() > 0) {
            $('.allotmentType-select').removeAttr('disabled');
        }
    });

    //allotmentType-select
    $('.allotmentType-select, .cabinType-select').change(function(e) {
        //prepare ajax data
        var partnerId = $('.select-reservation-partner').val();
        var cruiseDateId = $('input[type="radio"][name="cruiseDate_id"]:checked').val();
        var cabinTypeId = $('.cabinType-select').val();
        var allotmentType = $('.allotmentType-select').val();

        // reset cabin number
        // $('.cabinNumber-select').select2();
        $('.cabinNumber-select').find('option').remove();


        var data = {
            'partnerId': partnerId,
            'cruiseDateId': cruiseDateId,
            'cabinTypeId': cabinTypeId,
            'allotmentType': allotmentType
        };

        if (allotmentType == 'guarantee' || allotmentType == 'flexible') {
            //show select to select cabin number
            $('.form-group.cabinNumber').show();
            //enable cabinNumber-select
            $('.cabinNumber-select').removeAttr('disabled');

            if (cabinTypeId != -1) {
                //fetch cabin numbers --> @TODO: Check if cabinstatus is already reserved!
                ajax.jsonCall('/reservation/ajax/get-cabin-status', data, callback);
            }

            function callback(response) {
                console.log(response);
                $('.cabinNumber-select').empty();
                $('.cabinNumber-select').append('<option value="-1">--- Select cabin number ---</option>');
                response.map(function(item) {
                    $('.cabinNumber-select').append('<option value="' + item.id + '">' + item.number + '</option>');
                });
            }
        } else {
            //show select to select cabin number
            $('.form-group.cabinNumber').hide();
            //enable cabinNumber-select
            $('.cabinNumber-select').attr('disabled', 'disabled');
        }
    });

    //detect .table-cabins change
    jQuery.fn.contentChange = function(callback) {
        var elms = jQuery(this);
        elms.each(
            function(i) {
                var elm = jQuery(this);
                elm.data('lastContents', elm.html());
                window.watchContentChange = window.watchContentChange ? window.watchContentChange : [];
                window.watchContentChange.push({
                    'element': elm,
                    'callback': callback
                });
            }
        );
        return elms;
    };

    setInterval(function() {
        if (window.watchContentChange) {
            for (i in window.watchContentChange) {
                if (window.watchContentChange[i].element.data('lastContents') != window.watchContentChange[i].element.html()) {
                    window.watchContentChange[i].callback.apply(window.watchContentChange[i].element);
                    window.watchContentChange[i].element.data('lastContents', window.watchContentChange[i].element.html());
                };
            }
        }
    }, 500);

    $('.table-cabins').contentChange(function() {
        //Load selectable cabin numbers for passenger assignment
        var cabinNumbers = $('.table-cabins .cabin-number');
        $('.reservation-passenger-cabin-select, .reservation-passenger-cabin-select-dummy').empty();
        $('.reservation-passenger-cabin-select, .reservation-passenger-cabin-select-dummy').append('<option value="-1" selected>--- Select a cabin ---</option>');
        for (var i = cabinNumbers.length - 1; i >= 0; i--) {
            var cabNumber = $(cabinNumbers[i]).text();
            var cabinId = $(cabinNumbers[i]).attr('data-cabinid');
            $('.reservation-passenger-cabin-select, .reservation-passenger-cabin-select-dummy').append('<option value="' + cabinId + '">' + cabNumber + '</option>');
        }


        //reset selects
        $('.reservation-passenger-cabin-select').select2();

        //event when passenger is assigned
        // $('.reservation-passenger-cabin-select').change(function (e) {
        $(document).on('change', '.reservation-passenger-cabin-select', function(e) {
            var passengerId = $(this).data('passenger');
            var cabinId = $(this).val();

            var element = $('#booked_cabinPassengerAssignment-' + passengerId);

            if(element){
                //update hidden input name attribute
                // console.log($('#booked_cabinPassengerAssignment-' + passenger.id));
                $(element).attr('name', 'booked_cabinPassengerAssignment[' + cabinId + '][]');
            } else {
                $('<input>').attr({
                    type: 'hidden',
                    id: 'booked_cabinPassengerAssignment-' +  passenger.id,
                    name: 'booked_cabinPassengerAssignment[' + cabinId + '][]',
                    value: passenger.id
                }).appendTo('#create-reservation-form');
            }

        });

        //show passenger - check if there are cabins or empty
        reservationHelper.showCard('.reservation-passenger');

    });

}

function addReservationBatchEvents() {
    $(window).on('load', function() {
        $('.batch-cruise-select').change();
    });

    $('.batch-cruise-select').on('change', function(e) {
        var cruise_id = $('.batch-cruise-select option:selected').val();
        var url = '/reservation/batch/ajax/get-cruise-date-table';
        var data = {
            'cruise_id': cruise_id
        }
        ajax.bladeCall(url, data, callback);

        function callback(response) {
            $('.batch-cruise-date-container').html(response);
            $('.select2-cruise-date-batch').select2();
            $('input[type=file]#reservation-excel-input').on('change', function(e) {
                $('.selected-file').html('Selected File: ' + this.files[0].name);
            });
        }


    });


}

function addReservationFilterEvents() {
    $(document).on('change', '.cruise-select', function(e) {
        getFilteredReservationsSelects();
        $('.partner-select').attr('disabled', 'disabled');
        $('.error-container .cruise').remove();
    });

    $(document).on('change', '.partner-select', function(e) {
        getFilteredReservationsSelects();
        $('.error-container .partner').remove();
    })

    $(document).on('change', '.cruisedate-select', function(e) {
        getFilteredReservationsSelects();
        $('.error-container .cruisedate').remove();
    })

    $(document).on('click', '.search-reservation', function(e) {

        $('.error-container p').remove();

        if ($('#partner-select-id option:selected').val() == -1) {
            $(".error-container").append('<p class="partner">Select a partner<p>');

        } else if ($('#cruise-select-id option:selected').val() == -1) {
            $(".error-container").append('<p class="cruise">Select a cruise</p>');;

        } else if ($('#cruisedate-select-id option:selected').val() == -1) {
            $(".error-container").append('<p class="cruisedate">Select a cruisedate</p>');


        } else {
            getFilteredReservations();
        }
    });
}


$(document).on('change', '.update-reservation-status', function(e) {
    var reservation_id = $(this).data('reservationid');
    var status = $('#update-reservation-status-id-' + reservation_id + ' option:selected').val();
    var url = '/reservation/ajax/update-reservation-status';

    var data = {
        'reservation_id': reservation_id,
        'status': status
    }
    ajax.jsonCall(url, data, callback)

    function callback(response) {

    }

});

$(document).on('change', '.load-change', function(e) {
    $('.load-container').addClass('waiting-cursor');
})

function getFilteredReservationsSelects() {
    var partner_id = $('#partner-select-id option:selected').val();
    var cruise_id = $('#cruise-select-id option:selected').val();
    var cruise_date_id = $('#cruisedate-select-id option:selected').val();

    var data = {
        'cruise_id': cruise_id,
        'partner_id': partner_id,
        'cruise_date_id': cruise_date_id
    }

    var url = '/reservation/ajax/get-filtered-reservations-selects';

    ajax.bladeCall(url, data, callback);

    function callback(response) {
        $('.dynamic-reservation-view').html(response);
        $('.cruise-select').select2();
        $('.partner-select').select2();
        $('.cruisedate-select').select2();
        $('.filter-reservation').select2();

    }
}
//
function getFilteredReservations() {
    var partner_id = $('#partner-select-id option:selected').val();
    var cruise_id = $('#cruise-select-id option:selected').val();
    var cruise_date_id = $('#cruisedate-select-id option:selected').val();
    var reservation_status = $('#reservation-status-select option:selected').val();

    var data = {
        'cruise_id': cruise_id,
        'partner_id': partner_id,
        'cruise_date_id': cruise_date_id,
        'reservation_status': reservation_status
    }

    var url = '/reservation/ajax/get-filtered-reservations';

    ajax.bladeCall(url, data, callback);

    function callback(response) {
        $('.reservation-results').html(response);
        //      $('.filter-reservation').select2();

        //can this be done differently --> JS hack.
        var filterDefault = $('.table-filter-default').dataTable({
            columnDefs: [{
                targets: ['no-order'],
                orderable: false
            }],
            lengthChange: false,
            stateSave: true,
            order: [],
            fnDrawCallback: function(oSettings) {
                if (oSettings._iDisplayLength > oSettings.fnRecordsDisplay()) {
                    $(oSettings.nTableWrapper).find('.dataTables_paginate').hide();
                } else {
                    $(oSettings.nTableWrapper).find('.dataTables_paginate').show();
                }
            }
        });

        $('.input-filter-default').keyup(function() {
            //multiple datatables on one page
            if ($(this).closest('.datatables-container').length > 0) {
                $(this).closest('.datatables-container').find('.table-filter-default').dataTable().fnFilter($(this).val());
            } else {
                filterDefault.fnFilter($(this).val());
            }
        });
    }

}

function searchReservationEvents() {
    $(document).on('keyup', '.search-reservation', function(e) {
        var reservationId = $(this).val();

        if (e.keyCode == 13 && reservationId != ''){
            var data = {
                'reservation_id': reservationId
            };

            var url = 'reservation/ajax/get-reservation';
            ajax.jsonCall(url, data, callback);

            function callback(response){
                if(response){
                    window.location.replace(response);
                } else {
                    $('.search-reservation-error').text('Reservation not found');
                }
            }
        } else if(e.keyCode == 13 && reservationId == ''){
            $('.search-reservation-error').text('Please enter a valid reservation number');
        }
    });
}
