var $ = jQuery = require('jquery');
var bootstrap = require('bootstrap');
var select2 = require('select2');
var ajax = require('../../ajax');
var datepicker = require('../../datepicker');
var generateTables = require('../../modules/generateTables');

module.exports = {
  init: function() {
    init();
  }
}

function init() {
  shipRevenueEvents();
  partnerRevenueCharts();
  cruiseRevenueCharts();

  if ($(document).find('#shipYearVsYear').length > 0) {
    shipYearVsYearEvents();
  }

  paxGrowthEvents();
  invoiceReportEvents();
  labelRevenueEvents();

  targetInfo();
  exportEvents();

  showPaxGrowthChart();

  paxEvents();

  accountsReceivableEvents();
}

var colorList = [];
var backgroundColorList = [];

const PARTNER = 'Partner';
const SHIP = 'Ship';

// Return dynamic colors
var dynamicColors = function(opacity) {
  var r = Math.floor(Math.random() * 255);
  var g = Math.floor(Math.random() * 255);
  var b = Math.floor(Math.random() * 255);
  return "rgba(" + r + "," + g + "," + b + ", " + opacity + ")";
}

// Generate a given amount of colors
var generateColors = function(dataObjectCount) {
  var i = 0;
  while (i < dataObjectCount) {
    colorList.push(dynamicColors(1));
    i++;
  }
  return colorList;
}

var generateBackgroundColors = function(dataObjectCount) {
  var i = 0;
  while (i < dataObjectCount) {
    backgroundColorList.push(dynamicColors(0.4));
    i++;
  }

  return backgroundColorList;
}

function shipRevenueEvents() {
  $(document).on('change', '.ships-type-of-report-select', function(e) {
    var value = $(this).val();

    if (value == 'Revenue' || value == 'RvI') {
      $('.ships-revenue-with-partners').fadeIn();
    } else {
      $('.ships-revenue-with-partners').fadeOut();
    }
  });

  $(document).on('click', '.get-ships-revenue', function(e) {
    e.preventDefault();

    $('.ships-revenue-loader').show();

    var shipId = $('.ships-revenue-ship-select').val();
    var startDate = $('.ships-revenue-startdate').val();
    var endDate = $('.ships-revenue-enddate').val();
    var withPartners = $('.ships-revenue-with-partners').is(':checked');
    var typeOfReport = $('.ships-type-of-report-select').val();

    var shipName = $('.ships-revenue-ship-select').find('option:selected').text();

    var data = {
      'ship': shipId,
      'start_date': startDate,
      'end_date': endDate,
      'with_partners': withPartners,
      'type_of_report': typeOfReport,
    };

    var url = '/finance/report/ajax/get-ships-revenue';

    ajax.bladeCall(url, data, callback);

    function callback(response) {
      $('.ships-revenue-container').html(response);
      $('.ships-revenue-loader').hide();

      $('.ships-revenue-name').text(shipName);
      $('.ships-revenue-startdate').text(startDate);
      $('.ships-revenue-enddate').text(endDate)
    }

  });
}

function shipYearVsYearEvents() {
  // init
  var firstYear = '2016';
  var secondYear = '2017';
  var shipId = 1;
  var displayType = 'Monthly';
  var type = $('.vs-type-select').val();
  var partnerId = $('.vs-partner-select').val();
  var ctx = document.getElementById("shipYearVsYear");
  var shipYearVsYear;

  var labels;
  var firstYearRevenue;
  var secondYearRevenue;
  var totalFirstYearRevenue;
  var totalSecondYearRevenue;
  var difference;
  var shipName;

  var data = {
    'first_year': firstYear,
    'second_year': secondYear,
    'ship_id': shipId,
    'display_type': displayType,
    'type': type,
    'partner_id': partnerId
  };

  var url = '/finance/report/ajax/get-ship-year-vs-year-revenue';

  ajax.jsonCall(url, data, callback);

  function callback(response) {
    labels = response.labels;
    firstYearRevenue = response.firstYearRevenue;
    secondYearRevenue = response.secondYearRevenue;
    totalFirstYearRevenue = response.totalFirstYearRevenue;
    totalSecondYearRevenue = response.totalSecondYearRevenue;
    difference = response.difference;
    shipName = response.shipName;

    shipYearVsYear = new Chart(ctx, {
      type: 'line',
      data: {
        labels: labels,
        datasets: [{
            label: firstYear,
            data: firstYearRevenue,
            backgroundColor: 'transparent',
            borderColor: 'rgba(2,88,135, 1)'
          },
          {
            label: secondYear,
            data: secondYearRevenue,
            backgroundColor: 'transparent',
            borderColor: 'rgba(255,102,0, 1)',
          }
        ]
      },
      options: {
        legend: {
          display: true,
        },
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            }
          }]
        },
        elements: {
          line: {
            tension: 0
          }
        }
      }
    });

    $('.first-year').text(firstYear);
    $('.second-year').text(secondYear);
    $('.ship-name').text(shipName);
    $('.display-type').text(displayType);

    totalFirstYearRevenue = totalFirstYearRevenue.toLocaleString('de-DE', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });
    totalSecondYearRevenue = totalSecondYearRevenue.toLocaleString('de-DE', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });
    difference = difference.toLocaleString('de-DE', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });

    $('.first-year-revenue').text(totalFirstYearRevenue);
    $('.second-year-revenue').text(totalSecondYearRevenue);
    $('.year-vs-year-difference').text(difference);

    generateTables.matrixMonthlyDifference(labels, firstYear, secondYear, firstYearRevenue, secondYearRevenue, totalFirstYearRevenue, totalSecondYearRevenue, difference, '.ship-monthly-difference-table');
    $('.ship-year-vs-year-loader').hide();
  }

  $(document).on('change', '.first-year-select, .second-year-select, .vs-ship-select, .vs-partner-select, .display-type-select', function(e) {
    $('.ship-year-vs-year-loader').show();

    firstYear = $('.first-year-select').val();
    secondYear = $('.second-year-select').val();
    shipId = $('.vs-ship-select').val();
    displayType = $('.display-type-select').val();
    type = $('.vs-type-select').val();
    partnerId = $('.vs-partner-select').val();

    var data = {
      'first_year': firstYear,
      'second_year': secondYear,
      'ship_id': shipId,
      'partner_id': partnerId,
      'type': type,
      'display_type': displayType,
    };

    var url = '/finance/report/ajax/get-ship-year-vs-year-revenue';

    ajax.jsonCall(url, data, callback);

    function callback(response) {

      firstYearRevenue = response.firstYearRevenue;
      secondYearRevenue = response.secondYearRevenue;
      totalFirstYearRevenue = response.totalFirstYearRevenue;
      totalSecondYearRevenue = response.totalSecondYearRevenue;
      difference = response.difference;
      shipName = response.shipName;

      shipYearVsYear.data.datasets[0].data = firstYearRevenue;
      shipYearVsYear.data.datasets[0].label = firstYear;

      shipYearVsYear.data.datasets[1].data = secondYearRevenue;
      shipYearVsYear.data.datasets[1].label = secondYear;
      shipYearVsYear.update();

      $('.first-year').text(firstYear);
      $('.second-year').text(secondYear);
      $('.ship-name').text(shipName);
      $('.display-type').text(displayType);

      totalFirstYearRevenue = totalFirstYearRevenue.toLocaleString('de-DE', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });
      totalSecondYearRevenue = totalSecondYearRevenue.toLocaleString('de-DE', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });
      difference = difference.toLocaleString('de-DE', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });

      $('.first-year-revenue').text(totalFirstYearRevenue);
      $('.second-year-revenue').text(totalSecondYearRevenue);
      $('.year-vs-year-difference').text(difference);

      generateTables.matrixMonthlyDifference(labels, firstYear, secondYear, firstYearRevenue, secondYearRevenue, totalFirstYearRevenue, totalSecondYearRevenue, difference, '.ship-monthly-difference-table');
      $('.ship-year-vs-year-loader').hide();
    }

  });

  $(document).on('change', '.vs-type-select', function(e) {
    var type = $(this).val();

    if (type === PARTNER) {
      $('.ship-vs-container').hide();
      $('.partner-vs-container').show();
    } else if (type === SHIP) {
      $('.ship-vs-container').show();
      $('.partner-vs-container').hide();
    }
  });
}

function partnerRevenueCharts() {

  $(document).on('click', '.get-partners-revenue', function(e) {
    e.preventDefault();

    $('.partners-revenues-loader').show();

    var startDate = $('.partners-startdate').val();
    var endDate = $('.partners-enddate').val();
    var partnerId = $('.partners-select-list').val();
    var typeOfReport = $('.partners-type-of-report-select').val();
    var withShips = $('.partners-revenue-with-ships').is(':checked');

    var partnerName = $('.partners-select-list').find('option:selected').text();

    var data = {
      'start_date': startDate,
      'end_date': endDate,
      'partner': partnerId,
      'type_of_report': typeOfReport,
      'with_ships': withShips
    };

    var url = '/finance/report/ajax/get-partners-revenue';

    ajax.bladeCall(url, data, callback);

    function callback(response) {
      $('.partners-revenues-container').html(response);

      $('.partner-name').text(partnerName);
      $('.partner-startdate').text(startDate);
      $('.partner-enddate').text(endDate);

      $('.partners-revenues-loader').hide();
    }

  });

  $(document).on('change', '.partners-select-list, .partners-type-of-report-select', function(e) {

    var partnerId = $('.partners-select-list').val();
    var typeOfReport = $('.partners-type-of-report-select').val();

    if (partnerId != 0 && typeOfReport == 'Revenue') {
      $('.partners-revenue-with-ships').show();
    } else {
      $('.partners-revenue-with-ships').hide();
    }

  });

}


function cruiseRevenueCharts() {

  // Init cruiseRevenueCharts
  var cruiseId = 1;
  var this_year = new Date().getFullYear();

  var startDate = '01/01/' + this_year;
  var endDate = '01/04/' + this_year;
  var data = {
    'cruise_id': cruiseId,
    'start_date': startDate,
    'end_date': endDate,
  };

  var url = '/finance/report/ajax/get-cruise-pax-growth';

  ajax.jsonCall(url, data, callback);

  function callback(response) {
    generateTables.cruiseMatrix(response);
  }

  $(document).on('click', '.get-cruise-pax-growth', function(e) {
    e.preventDefault();
    $('.cruise-pax-chart').fadeOut();
    var cruiseId = $('.cruise-pax-select').find('option:selected').val();
    var startDate = $('.cruise-pax-start-date').val();

    var endDate = $('.cruise-pax-end-date').val();
    var data = {
      'cruise_id': cruiseId,
      'start_date': startDate,
      'end_date': endDate,
    };

    var url = '/finance/report/ajax/get-cruise-pax-growth';

    ajax.jsonCall(url, data, callback);

    function callback(response) {
      generateTables.cruiseMatrix(response);
    }
  });

  $(document).on('click', '.cruisedate-pax-growth', function(e) {

    e.preventDefault();
    var cruiseDateId = $(this).data('cruisedate');
    var startDate = $('.cruise-pax-start-date').val();
    var endDate = $('.cruise-pax-end-date').val();
    var ctx = document.getElementById('cruisePaxChart');
    var cruisePaxChart;

    var data = {
      'cruise_date_id': cruiseDateId,
      'start_date': startDate,
      'end_date': endDate,
    };

    $('.cruise-pax-chart').fadeIn();

    $('html, body').animate({
      scrollTop: $(".cruise-pax-chart").offset().top
    }, 1000);

    var url = '/finance/report/ajax/get-cruisedate-pax-growth';

    ajax.jsonCall(url, data, callback);

    function callback(response) {
      labels = response.labels;
      pax = response.pax;

      cruisePaxChart = new Chart(ctx, {
        type: 'line',
        data: {
          labels: labels,
          datasets: [{
            label: 'Pax',
            data: pax,
            backgroundColor: 'transparent',
            borderColor: 'rgba(2,88,135, 1)'
          }]
        }
      });

    }
  });

}

function paxGrowthEvents() {

  $(document).on('change', '.pax-growth-type', function(e) {
    var value = $(this).val();

    if (value === 'Ship') {
      $('.ship-select-container').show();
      $('.cruise-select-container').hide();
      shrinkDateContainers();
    } else if (value === 'Cruise') {
      $('.ship-select-container').hide();
      $('.cruise-select-container').show();
      shrinkDateContainers();
    } else {
      $('.ship-select-container').hide();
      $('.cruise-select-container').hide();
      expandDateContainers();
    }
  });

  function shrinkDateContainers() {
    $('.pax-growth-startdate-container').removeClass('col-md-4');
    $('.pax-growth-enddate-container').removeClass('col-md-4');

    $('.pax-growth-startdate-container').addClass('col-md-3');
    $('.pax-growth-enddate-container').addClass('col-md-3');

  }

  function expandDateContainers() {
    $('.pax-growth-startdate-container').removeClass('col-md-3');
    $('.pax-growth-enddate-container').removeClass('col-md-3');

    $('.pax-growth-startdate-container').addClass('col-md-4');
    $('.pax-growth-enddate-container').addClass('col-md-4');
  }

  $(document).on('click', '.get-pax-growth', function(e) {
    e.preventDefault();
    $('.pax-growth-loader').show();

    var startDate = $('.pax-growth-startdate').val();
    var endDate = $('.pax-growth-enddate').val();
    var paxGrowthType = $('.pax-growth-type').val();
    var ship = $('.ship-pax-growth-select').val();
    var cruise = $('.cruise-pax-growth-select').val();

    var shipName = $('.ship-pax-growth-select').find('option:selected').text();
    var cruiseName = $('.cruise-pax-growth-select').find('option:selected').text();

    var data = {
      'start_date': startDate,
      'end_date': endDate,
      'pax_growth_type': paxGrowthType,
      'ship': ship,
      'cruise': cruise
    };

    var url = '/finance/report/ajax/get-pax-growth';

    ajax.bladeCall(url, data, callback);

    function callback(response) {
      $('.pax-growth-container').html(response);

      $('.pax-growth-type-response').text(paxGrowthType + ':');
      $('.pax-growth-startdate-response').text(startDate);
      $('.pax-growth-enddate-response').text(endDate);

      if (paxGrowthType === 'Ship') {
        $('.pax-growth-type-selected-item').text(shipName);
      } else if (paxGrowthType === 'Cruise') {
        $('.pax-growth-type-selected-item').text(cruiseName);
      }

      $('.pax-growth-loader').hide();
    }

  });


}

function invoiceReportEvents() {
  $(document).on('click', '.get-invoice-report-list', function(e) {
    e.preventDefault();

    $('.invoices-report-loader').show();

    var type = $('.invoice-report-type-select').val();
    var startDate = $('.invoice-report-startdate').val();
    var endDate = $('.invoice-report-enddate').val();
    var invoiceType = $('.invoice-report-invoice-type-select').val();

    var data = {
      'type': type,
      'start_date': startDate,
      'end_date': endDate,
      'invoice_type': invoiceType
    };

    var url = '/finance/report/ajax/get-invoices-report-list';

    ajax.bladeCall(url, data, callback);

    function callback(response) {
      $('.invoices-report-loader').hide();

      $('.invoices-report-container').html(response);

      $('.invoices-report-startdate-response').text(startDate);
      $('.invoices-report-enddate-response').text(endDate);
    }

  });

  $(document).on('change', '.invoice-report-type-select', function(e) {
    var val = $(this).val();

    if (val === 'Upcoming Invoices') {
      $('.invoice-type-container').show();
      $('.invoice-report-container').addClass('col-md-3').removeClass('col-md-4');
    } else {
      $('.invoice-type-container').hide();
      $('.invoice-report-container').removeClass('col-md-3').addClass('col-md-4');
    }
  });
}

function labelRevenueEvents() {
  $(document).on('click', '.get-labels-revenue', function(e) {
    $('.labels-revenue-loader').show();

    var labelId = $('.label-select').val();
    var labelName = $('.label-select').find('option:selected').text();
    var startDate = $('.label-startdate').val();
    var endDate = $('.label-enddate').val();

    var data = {
      'label_id': labelId,
      'start_date': startDate,
      'end_date': endDate,
    };

    var url = '/finance/report/ajax/get-labels-revenue';

    ajax.bladeCall(url, data, callback);

    function callback(response) {
      $('.labels-revenue-container').html(response);
      $('.labels-revenue-loader').hide();

      $('.label-name').text(labelName);
      $('.label-startdate-heading').text(startDate);
      $('.label-enddate-heading').text(endDate);
    }
  });
}

function targetInfo() {
  $(document).on('click', '.show-target-info', function(e) {
    let elementId = $(this).data('target-item');

    $('.ships-target-info').hide();
    $(elementId).show();
  });
}

function exportEvents() {

  $(document).on('click', '.export', function(e) {
    $('.downloading-container').fadeIn();
    $('body').css('overflow', 'hidden');
  });

  $(document).on('click', '.close-downloading-container', function(e) {
    e.preventDefault();
    $('.downloading-container').hide();
    $('body').css('overflow', 'auto');
  });
}

function showPaxGrowthChart() {
  $(document).on('click', '.show-graph', function(e) {
    e.preventDefault();

    var ctxPax = document.getElementById("paxGrowthChart");
    var labels = JSON.parse($('.labels-data').val());
    var paxGrowth = JSON.parse($('.pax-growth-data').val());

    var datasets = [];

    for (var key in paxGrowth) {

      var data = [];

      for (var y in paxGrowth[key]) {
        data.push(paxGrowth[key][y]);
      }

      var dataset = {
        'label': key,
        'data': data,
        'backgroundColor': dynamicColors(1),
      };

      datasets.push(dataset);
    }


    var growthPaxChart = new Chart(ctxPax, {
      type: 'bar',
      data: {
        labels: labels,
        datasets: datasets,
      },
      options: {
        scales: {
          xAxes: [{
            stacked: true
          }],
          yAxes: [{
            stacked: true
          }]
        }
      }
    });

    $('.chart-container').show();

  });
}

function paxEvents() {
  $(document).on('change', '.pax-type-select', function(e) {
    var val = $(this).val();

    if (val === 'Ship') {
      $('.pax-partner-container').hide();
      $('.pax-ship-container').show();
    } else if (val === 'Partner') {
      $('.pax-partner-container').show();
      $('.pax-ship-container').hide();
    }
  });

  $(document).on('click', '.get-pax-report', function(e) {
    e.preventDefault();
    $('.pax-loader').show();

    var paxType = $('.pax-type-select').val();
    var partner = $('.pax-partner-select').val();
    var ship = $('.pax-ship-select').val();
    var startDate = $('.pax-startdate').val();
    var endDate = $('.pax-enddate').val();

    var data = {
      'pax_type': paxType,
      'partner': partner,
      'ship': ship,
      'start_date': startDate,
      'end_date': endDate
    };

    var url = '/finance/report/ajax/get-pax-report';

    ajax.bladeCall(url, data, callback);

    function callback(response) {
      $('.pax-container').html(response);
      $('.pax-container').show();
      $('.pax-loader').hide();
    }
  });
}

function accountsReceivableEvents() {
  $(document).on('click', '.get-accounts-receivable', function(e){
    $('.accounts-receivable-loader').show();
    var startDate = $('.accounts-receivable-startdate').val();
    var endDate = $('.accounts-receivable-enddate').val();

    var data = {
      'start_date': startDate,
      'end_date': endDate
    }; 

    var url = '/finance/report/ajax/get-accounts-receivable';

    ajax.bladeCall(url, data, callback);

    function callback(response) {
      $('.accounts-receivable-report-container').html(response);
      $('.accounts-receivable-loader').hide();
    }
  });
}
