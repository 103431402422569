/*
 * Reservation > Main
 */

//----------------------------------------------------------------------
// Dependencies
//----------------------------------------------------------------------
var $         = jQuery = require('jquery');
var bootstrap = require('bootstrap');
var select2   = require('select2');

//child
var reservationEvents = require('./events');
var reservationTypeahead = require('./typeahead');

module.exports = {
    "init": function(){
        reservationEvents.init();
        if($('.find-passenger-typeahead').length > 0){
            reservationTypeahead.init();
        }
    }
};
