//----------------------------------------------------------------------
// Dependencies global
//----------------------------------------------------------------------
var $ = jQuery = require('jquery');
var bootstrap = require('bootstrap');
var select2 = require('select2');
var ajax = require('../ajax');
var messageHandler = require('../messageHandler');


//----------------------------------------------------------------------
// Dependencies global
//----------------------------------------------------------------------
module.exports = {
	'init': function() {
		step1Events();
		step2Events();
		step3Events();
		step4Events();
		step5Events();
		editAllotmentEvents();
		editContractEvents();
		contractEditPackageEvents();
		contractAllotmentAddPackageEvents();
		contractAddAllotmentEvents();
		guaranteedCabinsEventsEdit();
		checkRequiredFields();
		massEditAllotmentsEvents();
		checkGuaranteedReserved();
		contractSectionsEvents();
		setWysiwygEditor();
		destroyInvoiceGenerateSetting();
		changeStatus();
		tabEvents();
	}
};

//----------------------------------------------------------------------
// Functions
//----------------------------------------------------------------------
function step1Events() {
	$('.contract-partner-select').change(function(event) {
		//get partner id
		var partnerId = $('.contract-partner-select').val();

		var data = {
			'partner_id': partnerId
		};
		//make ajax call to server
		ajax.bladeCall('/contract/ajax/get-partner-details', data, callback);

		function callback(response) {
			$('.partner-details-container').html(response);
		}
	});
}

function step2Events() {
	$('.contract-cruise-select').change(function(event) {
		//get cruise id
		var cruiseId = $('.contract-cruise-select').val();

		var data = {
			'cruise_id': cruiseId
		};

		ajax.bladeCall('/contract/ajax/get-cruise-details', data, callback);

		function callback(response) {
			$('.cruise-details-container').html(response);
		}
	});
}

function step3Events() {
	getCruiseDatesBySeasonCabinTypeSelect();
	addFlexRateItem();
	addGuaranteedRateItem();
	deleteAllotmentRateRow();
}

function step4Events() {
	addSeasonPackage();
	editSeasonPackagesEvents();
}

function step5Events() {
	// Set the payment rule amount requirements on payment rule type change
	$(document).on('change', '.payment-rule-type-select', function(e) {
		var selectedOption = $(this).find('option:selected').val();
		if (selectedOption == 'Upfront') {
			$('.payment-rule-amount').parent().find('label').addClass('label-required');
			$('.payment-rule-amount').attr('required', 'required');
		} else {
			$('.payment-rule-amount').parent().find('label').removeClass('label-required');
			$('.payment-rule-amount').removeAttr('required');
			$('.payment-rule-amount').val('');
		}

		if (selectedOption == 'Rest') {
			var cruiseDateCheckboxes = $(this).parent().parent().parent().find('.mass-checkbox');
			$(cruiseDateCheckboxes).each(function() {
				$(this).prop('checked', true);
			});
		}
	});
}

function editAllotmentEvents() {
	$('#step3-allotment-edit-modal').on('loaded.bs.modal', function(event) {
		getCruiseDatesBySeasonCabinTypeSelect();
		addFlexRateItem();
		addGuaranteedRateItem();
		guaranteedCabinsEvents();
		deleteAllotmentRateRow();
	});
}

function editContractEvents() {
	$('#rate-edit-modal').on('loaded.bs.modal', function(event) {
		//click event
		$('.btn-add-edit-rate').click(function(event) {
			//duplicate dummy row
			var dupeRow = $('.table-rate-dummy .rate-row').clone().removeClass('rate-row');
			//change name attribute of duplicated row for correct post variables
			var dupRateTypeName = 'rate_modifier_type[]';
			var dupRateValueName = 'rate_modifier_value[]';
			//$(dupeRow).find('.rateType_name option[value="'+rateTypeId+'"]').attr('selected', 'selected');
			$(dupeRow).find('.rateType_name').attr('name', dupRateTypeName);
			$(dupeRow).find('.rateValue_name').attr('name', dupRateValueName);
			$(dupeRow).find('.rateValue_name').attr('step', 'any');

			//append to real container
			dupeRow.appendTo('.edit-rate-container tbody');

			//HACK: dirty select
			//$('.edit-rate-container tbody select').select2();
		});

		$('.btn-rate-row-delete').click(function(event) {
			$(this).closest('tr').remove();
		});
	});
}

function editSeasonPackagesEvents() {
	$('#season-package-edit-modal').on('loaded.bs.modal', function(event) {
		//bind tax rule change
		$('.taxable').change(function() {
			if (this.value == '1') {
				$('.taxable-type-form-group').show();
			} else {
				$('.taxable-type-form-group').hide();
			}
		});

		//bind package rate
		$('.btn-add-package-rate').click(function(event) {
			var packageId = $(this).data('packageid');
			var seasonId = $(this).data('seasonid');
			var rateRow = $('#packageCollapseEdit' + packageId + '.panel-collapse .inithide.table-package-rate-dummy .package-rate-row').clone();

			var packageRateTypeName = 'allocations[seasonId_' + seasonId + '][packages][packageId_' + packageId + '][packageRate][type][]';
			var packageRateValueName = 'allocations[seasonId_' + seasonId + '][packages][packageId_' + packageId + '][packageRate][value][]';

			$(rateRow).find('.rateType_name').attr('name', packageRateTypeName);
			$(rateRow).find('.rateValue_name').attr('name', packageRateValueName);
			$(rateRow).find('.rateValue_name').attr('step', 'any');
			$(rateRow).find('select').select2();
			$(rateRow).removeClass('.table-package-rate-dummy');

			rateRow.appendTo('#packageCollapseEdit' + packageId + '.panel-collapse .package-rate-container tbody');
		});

		deleteAllotmentRateRow();
	});
}

function contractEditPackageEvents() {
	$('#packages-edit-modal').on('loaded.bs.modal', function(event) {
		$('.btn-rate-row-delete').click(function(event) {
			$(this).closest('tr').remove();
		});

		//bind tax rule change
		$('input[type=radio][name=taxable]').change(function() {
			if (this.value == '1') {
				$('.taxable-type-form-group').show();
				$('.package-taxable-panel input[type=radio][name=taxableType]').removeAttr('disabled');
			} else {
				$('.taxable-type-form-group').hide();
				$('.taxable-type-form-group input[type=radio][name=taxableType]').attr('disabled');
			}
		});

		//bind package rate
		$('.btn-add-package-rate').click(function(event) {
			var rateRow = $('.inithide.table-package-rate-dummy .package-rate-row').clone();

			$(rateRow).find('.rateType_name').attr('name', 'rateType[]');
			$(rateRow).find('.rateValue_name').attr('name', 'rateValue[]');
			$(rateRow).find('.rateValue_name').attr('step', 'any');

			$(rateRow).removeClass('.table-package-rate-dummy');

			rateRow.appendTo('.package-rate-container tbody');
		});
	});
}

function contractAllotmentAddPackageEvents() {
	$('.season-package-select').change(function($item) {
		$('.package-taxable-panel').show();
	});

	//bind tax rule change
	$('input[type=radio][name=taxable]').change(function() {
		if (this.value == '1') {
			$('.taxable-type-form-group').show();
			$('.package-taxable-panel input[type=radio][name=taxableType]').removeAttr('disabled');
		} else {
			$('.taxable-type-form-group').hide();
			$('.taxable-type-form-group input[type=radio][name=taxableType]').attr('disabled');
		}
	});

	//bind package rate
	$('.btn-add-package-rate').click(function(event) {
		var rateRow = $('.inithide.table-package-add-rate-dummy .package-add-rate-row').clone();

		$(rateRow).find('.rateType_name').attr('name', 'rateType[]');
		$(rateRow).find('.rateValue_name').attr('name', 'rateValue[]');
		$(rateRow).find('.rateValue_name').attr('step', 'any');

		$(rateRow).removeClass('.table-package-rate-dummy');

		rateRow.appendTo('.package-add-rate-container tbody');

		$('.btn-rate-row-delete').click(function(event) {
			$(this).closest('tr').remove();
		});
	});
}

function contractAddAllotmentEvents() {
	$('.contract-add-allotment-season-select, .contract-add-allotment-cabintype-select').change(function(event) {
		//prepare date
		var cruise_id = $(this).data('cruise-id');

		var item = '#allotment-add-modal-cruise-' + cruise_id;

		var season_id = $(item + ' .contract-add-allotment-season-select').val();
		var cabintype_id = $(item + ' .contract-add-allotment-cabintype-select').val();
		var contract_id = $(item + ' .contract-id').val();

		var data = {
			'season_id': season_id,
			'cabintype_id': cabintype_id,
			'contract_id': contract_id,
			'cruise_id': cruise_id
		};

		if (season_id > 0 && cabintype_id > 0) {
			//update cruisedates tab
			ajax.bladeCall('/contract/ajax/get-contract-cruisedates-details', data, callback1);

			function callback1(response) {
				$(item + ' .cruise-dates-container').html(response);

				// mass checkbox
				$('#cd_mass_check').click(function(e) {
					// e.preventDefault();

					if ($(this).is(':checked')) {
						$('.allotment-cruisedate-checkbox').prop('checked', true);
					} else {
						$('.allotment-cruisedate-checkbox').prop('checked', false);
					}
				});

				guaranteedCabinsEvents();

				//select2 update
				$('select.select2:not(.select2-hidden-*)').select2();
			}
		}
	});

	//flex rate add
	$('.btn-contract-add-flexible-rate').click(function(event) {
		var cruise_id = $(this).data('cruise-id');
		var item = '#allotment-add-modal-cruise-' + cruise_id;

		var dupeRow = $(item + ' .table-flexible-rate-dummy .flexible-rate-row').clone();
		$(dupeRow).find('.rateType_name').attr('name', 'allocations[rateFlex][type][]');
		$(dupeRow).find('.rateValue_name').attr('name', 'allocations[rateFlex][value][]');
		$(dupeRow).find('.rateValue_name').attr('step', 'any');
		dupeRow.appendTo(item + ' .table-flexible-rate-overview tbody');
		$('.table-flexible-rate-overview tbody select').select2();

		deleteAllotmentRateRow();
	});

	//guaranteed rate add
	$('.btn-contract-add-guaranteed-rate').click(function(event) {
		var cruise_id = $(this).data('cruise-id');
		var item = '#allotment-add-modal-cruise-' + cruise_id;

		var dupeRow = $(item + ' .table-guaranteed-rate-dummy .guaranteed-rate-row').clone();
		$(dupeRow).find('.rateType_name').attr('name', 'allocations[rateGuaranteed][type][]');
		$(dupeRow).find('.rateValue_name').attr('name', 'allocations[rateGuaranteed][value][]');
		$(dupeRow).find('.rateValue_name').attr('step', 'any');
		dupeRow.appendTo(item + ' .table-guaranteed-rate-overview tbody');
		$('.table-guaranteed-rate-overview tbody select').select2();

		deleteAllotmentRateRow();
	});
}

//----------------------------------------------------------------------
// Wrapper/Helpers
//----------------------------------------------------------------------
function getCruiseDatesBySeasonCabinTypeSelect() {
	$('.contract-allotment-season-select, .contract-allotment-cabintype-select').change(function(event) {
		var modalTarget = $(this).closest('.tab-pane').attr('id');
		//only ajax fetch cruise dates when both selects are filled, else do nothing?
		var season_id = $('#' + modalTarget + ' .contract-allotment-season-select').val();
		var cabintype_id = $('#' + modalTarget + ' .contract-allotment-cabintype-select').val();

		var seasonLabel = $('#' + modalTarget + ' .contract-allotment-season-select option:selected').text();
		var cabintypeLabel = $('#' + modalTarget + ' .contract-allotment-cabintype-select option:selected').text();

		if (season_id == 0) {
			$('span.selected-season').text('none');
		} else {
			$('span.selected-season').text(seasonLabel);
		}

		if (cabintype_id == 0) {
			$('span.selected-cabintype').text('none');
		} else {
			$('span.selected-cabintype').text(cabintypeLabel);
		}


		//update btn-add-flexible-rate and btn-add-guaranteed-rate buttons
		$('.btn-add-flexible-rate, .btn-add-guaranteed-rate').attr('data-seasonid', season_id).attr('data-cabintypeid', cabintype_id);


		var data = {
			'season_id': season_id,
			'cabintype_id': cabintype_id,
		};

		//if both are set, refresh cruisedate list
		if (season_id > 0 && cabintype_id > 0) {
			//update cruisedates tab
			ajax.bladeCall('/contract/ajax/get-cruisedates-details', data, callback1);

			function callback1(response) {
				$('#' + modalTarget + ' .cruise-dates-container').html(response);

				// mass checkbox
				$('#cd_mass_check').click(function(e) {
					// e.preventDefault();

					if ($(this).is(':checked')) {
						$('.allotment-cruisedate-checkbox').prop('checked', true);
					} else {
						$('.allotment-cruisedate-checkbox').prop('checked', false);
					}
				});

				guaranteedCabinsEvents();

				//select2 update
				$('select.select2:not(.select2-hidden-*)').select2();
			}

			//update flexrate tab
			ajax.bladeCall('/contract/ajax/get-flexrates-details', data, callback2);

			function callback2(response) {
				$('.table-flexible-rate-overview tbody').html(response);
				$('.table-flexible-rate-overview tbody select').select2();
				deleteAllotmentRateRow();
			}

			//update guaranteedrate tab
			ajax.bladeCall('/contract/ajax/get-guaranteedrates-details', data, callback3);

			function callback3(response) {
				$('.table-guaranteed-rate-overview tbody').html(response);
				$('.table-guaranteed-rate-overview tbody select').select2();
				deleteAllotmentRateRow();
			}
		}
	});
}

function addFlexRateItem() {
	$('.btn-add-flexible-rate').click(function(event) {
		var modalTarget = $(this).closest('.tab-pane').attr('id');

		var seasonId = $(this).data('seasonid');
		var cabinTypeId = $(this).data('cabintypeid');

		//duplicate dummy row
		var dupeRow = $('.table-flexible-rate-dummy .flexible-rate-row').clone();
		//change name attribute of duplicated row for correct post variables
		var dupFlexTypeName = 'allocations[seasonId_' + seasonId + '][cabintypes][cabinTypeId_' + cabinTypeId + '][rateFlex][type][]';
		var dupFlexValueName = 'allocations[seasonId_' + seasonId + '][cabintypes][cabinTypeId_' + cabinTypeId + '][rateFlex][value][]';
		//$(dupeRow).find('.rateType_name option[value="'+rateTypeId+'"]').attr('selected', 'selected');
		$(dupeRow).find('.rateType_name').attr('name', dupFlexTypeName);
		$(dupeRow).find('.rateValue_name').attr('name', dupFlexValueName);
		$(dupeRow).find('.rateValue_name').attr('step', 'any');

		//append to real container
		dupeRow.appendTo('#' + modalTarget + ' .table-flexible-rate-overview tbody');

		//HACK: dirty select
		$('.table-flexible-rate-overview tbody select').select2();

		deleteAllotmentRateRow();
	});
}

function addGuaranteedRateItem() {
	$('.btn-add-guaranteed-rate').click(function(event) {
		var modalTarget = $(this).closest('.tab-pane').attr('id');

		var seasonId = $(this).data('seasonid');
		var cabinTypeId = $(this).data('cabintypeid');

		//duplicate dummy row
		var dupeRow = $('.table-guaranteed-rate-dummy .guaranteed-rate-row').clone();
		//change name attribute of duplicated row for correct post variables
		var dupGuaranteedTypeName = 'allocations[seasonId_' + seasonId + '][cabintypes][cabinTypeId_' + cabinTypeId + '][rateGuaranteed][type][]';
		var dupGuaranteedValueName = 'allocations[seasonId_' + seasonId + '][cabintypes][cabinTypeId_' + cabinTypeId + '][rateGuaranteed][value][]';
		//$(dupeRow).find('.rateType_name option[value="'+rateTypeId+'"]').attr('selected', 'selected');
		$(dupeRow).find('.rateType_name').attr('name', dupGuaranteedTypeName);
		$(dupeRow).find('.rateValue_name').attr('name', dupGuaranteedValueName);
		$(dupeRow).find('.rateValue_name').attr('step', 'any');

		//append to real container
		dupeRow.appendTo('#' + modalTarget + ' .table-guaranteed-rate-overview tbody');

		//HACK: dirty select
		$('.guaranteed-rate-container tbody select').select2();

		deleteAllotmentRateRow();
	});
}

function deleteAllotmentRateRow() {
	$('.btn-rate-row-delete').click(function(event) {
		$(this).closest('tr').remove();
	});
}

function guaranteedCabinsEvents() {
	//initialize
	$('.guaranteed-num').focus(function() {
		var changeVal = $(this).val();
		var cruiseDateId = $(this).data('cruisedateid');

		if (changeVal > 0) {
			$('.guaranteed-row-cabins-' + cruiseDateId + " .select2").removeAttr('disabled');
			$('.guaranteed-row-cabins-' + cruiseDateId + " .select2").select2({
				maximumSelectionLength: changeVal
			});
			//$('.guaranteed-row-cabins-' + cruiseDateId + ' .select2').select2('val','');
			$('.guaranteed-row-cabins-' + cruiseDateId).show();
		} else {
			//             console.log(changeVal);
			//            $('.guaranteed-row-cabins-' + cruiseDateId + ' .select2').select2('destroy');
			$('.guaranteed-row-cabins-' + cruiseDateId).hide();

		}
	});

	$('.guaranteed-num').change(function(e) {
		e.preventDefault();

		var changeVal = $(this).val();
		var cruiseDateId = $(this).data('cruisedateid');

		if (changeVal > 0) {
			$('.guaranteed-row-cabins-' + cruiseDateId + " .select2").removeAttr('disabled');
			$('.guaranteed-row-cabins-' + cruiseDateId + " .select2").select2({
				maximumSelectionLength: changeVal
			});
			//            $('.guaranteed-row-cabins-' + cruiseDateId + ' .select2').select2('val', '');
			$('.guaranteed-row-cabins-' + cruiseDateId).show();
		} else {
			//             console.log(changeVal);
			//            $('.guaranteed-row-cabins-' + cruiseDateId + ' .select2').select2('destroy');
			$('.guaranteed-row-cabins-' + cruiseDateId).hide();

		}
	});

	$('.guaranteed-num').keyup(function(e) {
		e.preventDefault();

		var changeVal = $(this).val();
		var cruiseDateId = $(this).data('cruisedateid');

		if (changeVal > 0) {
			$('.guaranteed-row-cabins-' + cruiseDateId + " .select2").removeAttr('disabled');
			$('.guaranteed-row-cabins-' + cruiseDateId + " .select2").select2({
				maximumSelectionLength: changeVal
			});
			//            $('.guaranteed-row-cabins-' + cruiseDateId + ' .select2').select2('val', '');
			$('.guaranteed-row-cabins-' + cruiseDateId).show();
		} else {
			//             console.log(changeVal);
			//            $('.guaranteed-row-cabins-' + cruiseDateId + ' .select2').select2('destroy');
			$('.guaranteed-row-cabins-' + cruiseDateId).hide();

		}
	});
}

function guaranteedCabinsEventsEdit() {
	$('.guaranteed-edit-num').focus(function(e) {
		e.preventDefault();
		var changeVal = $(this).val();

		var guarenteedSelect = $('.max-guarenteed-num :selected').length;

		if (changeVal > 0) {
			$('.max-guarenteed-num').select2({
				maximumSelectionLength: changeVal
			});
			$('.guarenteed-select-box').show();
		} else {
			$('.guarenteed-select-box').hide();
		}

		if (changeVal == guarenteedSelect) {
			$('.error-container .guarenteed-cabins').remove();
		}
	});

	$('.guaranteed-edit-num').change(function(e) {
		e.preventDefault();
		var changeVal = $(this).val();

		var guarenteedSelect = $('.max-guarenteed-num :selected').length;

		if (changeVal > 0) {
			$('.max-guarenteed-num').select2({
				maximumSelectionLength: changeVal
			});
			$('.guarenteed-select-box').show();
		} else {
			$('.guarenteed-select-box').hide();
		}

		if (changeVal == guarenteedSelect) {
			$('.error-container .guarenteed-cabins').remove();
		}
	});

	$('.guaranteed-edit-num').keyup(function(e) {
		e.preventDefault();
		var changeVal = $(this).val();

		var guarenteedSelect = $('.max-guarenteed-num :selected').length;

		if (changeVal > 0) {
			$('.max-guarenteed-num').select2({
				maximumSelectionLength: changeVal
			});
			$('.guarenteed-select-box').show();
		} else {
			$('.guarenteed-select-box').hide();
		}

		if (changeVal == guarenteedSelect) {
			$('.error-container .guarenteed-cabins').remove();
		}
	});

	var initVal = $('.guaranteed-edit-num').val();

	if (initVal == 0) {
		$('.guarenteed-select-box').hide();
	} else {
		$('.guarenteed-select-box').show();
	}

	$('.max-guarenteed-num').on('select2:selecting', function(e) {
		var guaVal = $('.guaranteed-edit-num').val();
		var guarenteedSelect = $('.max-guarenteed-num :selected').length;

		if (guaVal == guarenteedSelect) {
			$('.max-guarenteed-num').select2({
				maximumSelectionLength: guaVal
			});
			e.preventDefault();

		}
	});

	$(document).on('click', '.check-guarenteed', function(e) {

		var guarenteedNo = $('.guaranteed-edit-num').val();
		var guarenteedSelect = $('.max-guarenteed-num :selected').length;
		$('.error-container p').remove();
		if (guarenteedNo != guarenteedSelect) {
			e.preventDefault();
			$('.error-container').append('<p class="guarenteed-cabins">Amount of guaranteed cabins must be equal to selected amount of guaranteed cabins.</p>');
		} else {
			$('.error-container .guarenteed-cabins').remove();
		}
	});

}

function addSeasonPackage() {
	//update the package modal to keep track of seasonID
	$('.btn-season-add-package').click(function(event) {


		var seasonId = $(this).data('seasonid');
		$('#season-package-modal .btn-add-package').attr('data-seasonid', seasonId);
		$('#season-package-modal .season-package-select').attr('data-seasonid', seasonId);

	});

	//on package select, append package panel
	$('#season-package-modal .season-package-select').change(function(event) {

		//show tax rules panel
		$('.package-taxable-panel').show();

		//remove current child package(s)
		$('#season-package-modal .season-package-container .package-panel').remove();
		//duplicate package accordion panel
		var packagePanel = $('.inithide.package-panel-dummy').clone();
		$(packagePanel).removeClass('inithide').removeClass('package-panel-dummy').addClass('package-panel');

		//get id and name of selected package
		var packageLabel = $('#season-package-modal .season-package-select option:selected').text();
		var packageId = $('#season-package-modal .season-package-select').val();
		var seasonId = $(this).data('seasonid');

		//update panel specific content
		// $(packagePanel).find('.package-name').text(packageLabel);
		$(packagePanel).find('.package-name').attr('href', '#packageCollapse' + packageId);
		$(packagePanel).find('.package-name').attr('aria-controls', 'packageCollapse' + packageId);
		$(packagePanel).find('.panel-collapse').attr('id', 'packageCollapse' + packageId);

		$(packagePanel).find('.btn-add-package-rate').attr('data-packageid', packageId);
		$(packagePanel).find('.btn-add-package-rate').attr('data-seasonid', seasonId);

		$(packagePanel).find('.package_rate_type_input').select2();

		$('.season-package-container').append(packagePanel);

		$('.season-package-container .collapse').collapse();

		//bind tax rule change
		$('input[type=radio][name=taxable]').change(function() {
			if (this.value == '1') {
				$('.taxable-type-form-group').show();
				$('.package-taxable-panel input[type=radio][name=taxableType]').removeAttr('disabled');

				$('.package-taxable-panel input[type=radio][name=taxable]').attr('name', 'allocations[seasonId_' + seasonId + '][packages][packageId_' + packageId + '][taxable]');
				$('.package-taxable-panel input[type=radio][name=taxableType]').attr('name', 'allocations[seasonId_' + seasonId + '][packages][packageId_' + packageId + '][taxable_type]');
			} else {
				$('.taxable-type-form-group').hide();
				$('.taxable-type-form-group input[type=radio][name=taxableType]').attr('disabled');
			}
		});

		//bind package rate
		$('.btn-add-package-rate').click(function(event) {
			var packageId = $(this).data('packageid');
			var seasonId = $(this).data('seasonid');

			var rateRow = $('#packageCollapse' + packageId + '.panel-collapse .inithide.table-package-rate-dummy .package-rate-row').clone();

			var packageRateTypeName = 'allocations[seasonId_' + seasonId + '][packages][packageId_' + packageId + '][packageRate][type][]';
			var packageRateValueName = 'allocations[seasonId_' + seasonId + '][packages][packageId_' + packageId + '][packageRate][value][]';

			$(rateRow).find('.rateType_name').attr('name', packageRateTypeName);
			$(rateRow).find('.rateValue_name').attr('name', packageRateValueName);
			$(rateRow).find('.rateValue_name').attr('step', 'any');
			$(rateRow).find('select').select2();
			$(rateRow).removeClass('.table-package-rate-dummy');

			rateRow.appendTo('#packageCollapse' + packageId + '.panel-collapse .package-rate-container tbody');
		});
	});

}

function checkRequiredFields() {
	$(document).on('click', '.check-selects-create', function(e) {
		var season = $('.contract-allotment-season-select').val();
		var cabinType = $('.contract-allotment-cabintype-select').val();
		$('.error-container p').remove();

		var gNo = [];
		var gSelect = [];
		var flexRateTypeArr = [];
		var guaRateTypeArr = [];

		$('.table-flexible-rate-overview .rateType_name').each(function(index, elem) {
			var val = $(elem).find('option:selected').val();

			flexRateTypeArr.push(val);
		});

		$('.table-guaranteed-rate-overview .rateType_name').each(function(index, elem) {
			var val = $(elem).find('option:selected').val();

			guaRateTypeArr.push(val);
		});

		$('.guaranteed-num').each(function(index, elem) {
			var val = $(elem).val();

			if (val != 0) {
				gNo.push(val);
			}
		});

		var gSelect = $('.guarenteed-selects').map(function() {
			if ($(this).find('option:selected').length != 0) {
				return $(this).find('option:selected').length;
			}
		}).toArray();

		var check = 0;
		var gNoString = gNo.join();
		var gSelectString = gSelect.join();

		if (gNoString !== gSelectString) {
			check++;
		}


		var checkFlexRateType = 0;
		var checkGuaRateType = 0;

		for (var i = 0; i < flexRateTypeArr.length; i++) {
			if (flexRateTypeArr[i] == 0) {
				checkFlexRateType++;
			}
		}

		for (var i = 0; i < guaRateTypeArr.length; i++) {
			if (guaRateTypeArr[i] == 0) {
				checkGuaRateType++;
			}
		}

		if (season == 0) {
			$('.error-container').append('<p class="season">Select a season</p>');
			e.preventDefault();
		}

		if (cabinType == 0) {
			$('.error-container').append('<p class="cabinType">Select a cabin type</p>');
			e.preventDefault();
		}


		if (check != 0 || gNo.length > 0 && gSelect.length == 0 || gNo.length == 0 && gSelect.length > 0) {
			$('.error-container').append('<p>Amount of select guaranteed cabins must be equal to amount of guaranteed.</p>');
			e.preventDefault();
		}
		if (checkFlexRateType != 0) {
			$('.error-container').append('<p class="ratetype">Select a flexible rate type</p>');
			e.preventDefault();
		}
		if (checkGuaRateType != 0) {
			$('.error-container').append('<p class="ratetype">Select a guarenteed rate type</p>');
			e.preventDefault();
		}

	});

	$(document).on('click', '.check-selects-edit', function(e) {
		$('.error-container p').remove();
		var gNo = [];
		var gSelect = [];
		var flexRateTypeArr = [];
		var guaRateTypeArr = [];

		$('.table-flexible-rate-overview .rateType_name').each(function(index, elem) {
			var val = $(elem).find('option:selected').val();

			flexRateTypeArr.push(val);
		});

		$('.table-guaranteed-rate-overview .rateType_name').each(function(index, elem) {
			var val = $(elem).find('option:selected').val();

			guaRateTypeArr.push(val);
		});
		$('.guaranteed-num').each(function(index, elem) {
			var val = $(elem).val();

			if (val != 0) {
				gNo.push(val);
			}
		});

		var gSelect = $('.guarenteed-selects').map(function() {
			if ($(this).find('option:selected').length != 0) {
				return $(this).find('option:selected').length;
			}
		}).toArray();


		var check = 0;
		var gNoString = gNo.join();
		var gSelectString = gSelect.join();

		if (gNoString !== gSelectString) {
			check++;
		}

		var checkFlexRateType = 0;
		var checkGuaRateType = 0;

		for (var i = 0; i < flexRateTypeArr.length; i++) {
			if (flexRateTypeArr[i] == 0) {
				checkFlexRateType++;
			}
		}

		for (var i = 0; i < guaRateTypeArr.length; i++) {
			if (guaRateTypeArr[i] == 0) {
				checkGuaRateType++;
			}
		}

		if (check != 0 || gNo.length > 0 && gSelect.length == 0 || gNo.length == 0 && gSelect.length > 0) {
			$('.error-container').append('<p class="guarenteed">Selected amount of guaranteed cabins must be equal to number of guaranteed.</p>');
			e.preventDefault();
		}

		if (checkFlexRateType != 0) {
			$('.error-container').append('<p class="ratetype">Select a flexible rate type</p>');
			e.preventDefault();
		}
		if (checkGuaRateType != 0) {
			$('.error-container').append('<p class="ratetype">Select a guarenteed rate type</p>');
			e.preventDefault();
		}

	});


	$(document).on('click', '.check-add-selects', function(e) {
		var gNo = [];
		var gSelect = [];
		var item = $(this).data('item');

		var season = $(item + ' .contract-add-allotment-season-select').val();
		var cabinType = $(item + ' .contract-add-allotment-cabintype-select').val();
		var cruisedates = $(item + ' .allotment-cruisedate-checkbox:checked').length;
		$(item + ' .error-container p').remove();

		var flexRateTypeArr = [];
		var guaRateTypeArr = [];

		$(item + ' .table-flexible-rate-overview .rateType_name').each(function(index, elem) {
			var val = $(elem).find('option:selected').val();

			flexRateTypeArr.push(val);
		});

		$(item + ' .table-guaranteed-rate-overview .rateType_name').each(function(index, elem) {
			var val = $(elem).find('option:selected').val();

			guaRateTypeArr.push(val);
		});

		$(item + ' .guaranteed-num').each(function(index, elem) {
			var val = $(elem).val();

			if (val != 0) {
				gNo.push(val);
			}
		});

		var gSelect = $(item + ' .guarenteed-selects').map(function() {
			if ($(this).find('option:selected').length != 0) {
				return $(this).find('option:selected').length;
			}
		}).toArray();

		var check = 0;
		var gNoString = gNo.join();
		var gSelectString = gSelect.join();

		if (gNoString !== gSelectString) {
			check++;
		}

		var checkFlexRateType = 0;
		var checkGuaRateType = 0;

		for (var i = 0; i < flexRateTypeArr.length; i++) {
			if (flexRateTypeArr[i] == 0) {
				checkFlexRateType++;
			}
		}

		for (var i = 0; i < guaRateTypeArr.length; i++) {
			if (guaRateTypeArr[i] == 0) {
				checkGuaRateType++;
			}
		}

		if (checkFlexRateType != 0) {
			$(item + ' .error-container').append('<p class="ratetype">Select a flexible rate type</p>');
			e.preventDefault();
		}

		if (checkGuaRateType != 0) {
			$(item + ' .error-container').append('<p class="ratetype">Select a guaranteed rate type</p>');
			e.preventDefault();
		}

		if (season == 0) {
			$(item + ' .error-container').append('<p class="season">Select a season</p>');
			e.preventDefault();
		}

		if (cabinType == 0) {
			$(item + ' .error-container').append('<p class="cabinType">Select a cabin type</p>')
		}

		if (cruisedates == 0) {
			$(item + ' .error-container').append('<p class="cruisedates">Select a cruisedate</p>');
			e.preventDefault();
		}

		if (check != 0 || gNo.length > 0 && gSelect.length == 0 || gNo.length == 0 && gSelect.length > 0) {
			$(item + ' .error-container').append('<p class="guarenteed">Selected amount of guaranteed cabins must be equal to number of guaranteed.</p>');
			e.preventDefault();
		}
	});


	$('.check-cruisenum').click(function(e) {
		var num = $('.checkcruises').val();

		if (num == 0) {
			e.preventDefault();
		}
	})

	$('.contract-allotment-season-select').change(function(e) {
		var season = $('.contract-allotment-season-select').val();
		if (season != 0) {
			$('.error-container .season').remove();
		}

	});

	$('.contract-allotment-cabintype-select').change(function(e) {
		var cabinType = $('.contract-allotment-cabintype-select').val();
		if (cabinType != 0) {
			$('.error-container .cabinType').remove();
		}
	});

	$('.contract-add-allotment-cabintype-select').change(function(e) {
		var cabinType = $('.contract-add-allotment-cabintype-select').val();

		if (cabinType != 0) {
			$('.error-container .cabinType').remove();
		}
	});

	$('.contract-add-allotment-season-select').change(function(e) {
		var season = $('.contract-add-allotment-season-select').val();

		if (season != 0) {
			$('.error-container .season').remove();
		}
	});

	$(document).on('change', '.rateType_name', function() {
		if ($(this).val() != 0) {
			$('.error-container .ratetype').remove();
		}
	});

	$(document).on('click', '.check-rate', function(e) {
		var rateTypeArr = [];

		$('.table-flexible-rate-overview .rateType_name').each(function(index, elem) {
			var val = $(elem).find('option:selected').val();

			rateTypeArr.push(val);
		});

		var checkRateType = 0;

		for (var i = 0; i < rateTypeArr.length; i++) {
			if (rateTypeArr[i] == 0) {
				checkRateType++;
			}
		}

		if (checkRateType != 0) {
			$('.error-container').append('<p class="ratetype">Select a rate type</p>');
			e.preventDefault();
		}
	});

	$(document).on('click', '.check-package', function(e) {
		$('.error-container p').remove();
		var packageTypeArr = [];


		var package = $('.season-package-select').val();

		$('.table-package-add-rate-overview .rateType_name').each(function(index, elem) {
			var val = $(elem).find('option:selected').val();

			packageTypeArr.push(val);
		});

		var checkPackageType = 0;

		for (var i = 0; i < packageTypeArr.length; i++) {
			if (packageTypeArr[i] == 0) {
				checkPackageType++;
			}
		}

		if (checkPackageType != 0) {
			$('.error-container').append('<p class="ratetype">Select a rate type</p>');
			e.preventDefault();
		}

		if (package == 0) {
			$('.error-container').append('<p class="package">Select a package</p>');
			e.preventDefault();
		}

	});

	$(document).on('click', '.check-edit-package', function(e) {
		$('.error-container p').remove();
		var editPackageTypeArr = [];

		$('.table-package-rate-overview .rateType_name').each(function(index, elem) {
			var val = $(elem).find('option:selected').val();

			editPackageTypeArr.push(val);
		});

		var checkEditPackageType = 0;

		for (var i = 0; i < editPackageTypeArr.length; i++) {
			if (editPackageTypeArr[i] == 0) {
				checkEditPackageType++;
			}
		}

		if (checkEditPackageType != 0) {
			$('.error-container').append('<p class="ratetype">Select a rate type</p>');
			e.preventDefault();
		}
	});

}

function massEditAllotmentsEvents() {

	$(document).on('change', '.mass-allotment-season, .mass-allotment-cabintype', function(e) {
		var season = $('.mass-allotment-season').val();
		var cabinType = $('.mass-allotment-cabintype').val();
		var cruise = $('.get-cruises').val();
		var contract = $('.get-contract').val()

		if (season != 0 && cabinType != 0) {
			$('.cruise-dates-container').show();
			$('.flex-gua-container').show();

			var url = '/contract/ajaxgetcruisedates';
			var data = {
				'seasonId': season,
				'cruise': cruise,
				'contract': contract,
				'cabinTypeId': cabinType,
			}
			ajax.bladeCall(url, data, callback);

			function callback(response) {
				$('.cruise-dates-container').html(response);
			}
		} else {
			$('.cruise-dates-container').hide();
			$('.flex-gua-container').hide();
		}
	});

	$(document).on('change', '.mass-allotment-season', function(e) {
		var season = $('.mass-allotment-season').val();

		if (season != 0) {
			$('.error-container .season').remove();
		}
	});

	$(document).on('change', '.mass-allotment-cabintype', function(e) {
		var cabinType = $('.mass-allotment-cabintype').val();

		if (cabinType != 0) {
			$('.error-container .cabinType').remove();
		}
	});

	$(document).on('change', '.allotment-cruisedate-checkbox', function(e) {
		var cruisedates = $('.allotment-cruisedate-checkbox:checked').length;

		if (cruisedates != 0) {
			$('.error-container .cruisedates').remove();
		}
	});

	$(document).on('click', '.check-selects', function(e) {
		var item = $(this).data('item');

		var season = $(item + ' .mass-allotment-season').val();
		var cabinType = $(item + ' .mass-allotment-cabintype').val();
		var cruisedates = $(item + ' .allotment-cruisedate-checkbox:checked').length;
		var packageType = $(item + ' .table-package-rate-overview .package-rate-row .packageRateType').val();
		var package_first = $(item + ' .table-package-rate-dummy .package-rate-row .packageRateType').val();

		var flexRateArr = [];
		var guaRateArr = [];
		var packageTypeArr = [];

		$(item +' .table-package-rate-overview .package-rate-row .packageRateType').each(function(index, elem) {
			var val = $(elem).find('option:selected').val();

			packageTypeArr.push(val);
		});

		$(item + ' .table-flexible-rate-overview .flexible-rate-row .flexRateType').each(function(index, elem) {
			var val = $(elem).find('option:selected').val();

			flexRateArr.push(val);
		});

		$(item + ' .table-guaranteed-rate-overview .guaranteed-rate-row .guaranteedRateType').each(function(index, elem) {
			var val = $(elem).find('option:selected').val();

			guaRateArr.push(val);
		});

		var checkPackage = 0;
		var checkFlex = 0;
		var checkGua = 0;

		for (var i = 0; i < packageTypeArr.length; i++) {
			if (packageTypeArr[i] == 0) {
				checkPackage++;
			}
		}

		for (var i = 0; i < flexRateArr.length; i++) {
			if (flexRateArr[i] == 0) {
				checkFlex++;
			}
		}

		for (var i = 0; i < guaRateArr.length; i++) {
			if (guaRateArr[i] == 0) {
				checkGua++;
			}
		}


		$(item + ' .error-container p').remove();

		if (season == 0) {
			e.preventDefault();
			$(item + ' .error-container').append('<p class="season">Select a season</p>');
		}
		if (cabinType == 0) {
			e.preventDefault();
			$(item + ' .error-container').append('<p class="cabinType">Select a cabintype</p>');
		}
		if (cruisedates == 0) {
			$(item + ' .error-container').append('<p class="cruisedates">Select a cruisedate</p>');
			e.preventDefault();
		}
		if (package_first == 0 && packageType == null) {
			$(item + ' .error-container').append('<p class="packagetype">Select a package rate type</p>');
			e.preventDefault();
		}
		if (checkPackage != 0) {
			e.preventDefault();
			$(item + ' .error-container').append('<p class="packagetype">Select a package rate type</p>');
		}
		if (checkFlex != 0) {
			e.preventDefault();
			$(item + ' .error-container').append('<p class="flextype">Select a flex rate type</p>');
		}
		if (checkGua != 0) {
			e.preventDefault();
			$(item + ' .error-container').append('<p class="guatype">Select a guaranteed rate type</p>');
		}
	});

	$(document).on('change', '.package-rate-row .packageRateType', function() {
		var package = $(this).val();

		if (package != 0) {
			$('.error-container .packagetype').remove();
		}
	});

	$(document).on('change', '.flexible-rate-row .flexRateType', function() {
		var flexible = $(this).val();

		if (flexible != 0) {
			$('.error-container .flextype').remove();
		}
	});

	$(document).on('change', '.guaranteed-rate-row .guaranteedRateType', function() {
		var guaranteed = $(this).val();

		if (guaranteed != 0) {
			$('.error-container .guatype').remove();
		}
	});


	$(document).on('click', '.btn-mass-add-guaranteed-rate', function(event) {
		var modalTarget = $(this).closest('.tab-pane').attr('id');

		var seasonId = $(this).data('seasonid');
		var cabinTypeId = $(this).data('cabintypeid');
		var cruise_id = $(this).data('cruise-id');

		var item = '#allotments-cruise-' + cruise_id;

		//duplicate dummy row
		var dupeRow = $(item + ' .table-guaranteed-rate-dummy .guaranteed-rate-row').clone();
		//change name attribute of duplicated row for correct post variables

		var dupGuaranteedTypeName = 'guarenteed[rateGuaranteedTypes][]';
		var dupGuaranteedValueName = 'guarenteed[rateGuaranteedValues][]';
		//$(dupeRow).find('.rateType_name option[value="'+rateTypeId+'"]').attr('selected', 'selected');
		$(dupeRow).find('.rateType_name').attr('name', dupGuaranteedTypeName);
		$(dupeRow).find('.rateValue_name').attr('name', dupGuaranteedValueName);
		$(dupeRow).find('.rateValue_name').attr('step', 'any');

		//append to real container
		dupeRow.appendTo('#' + modalTarget + ' .table-guaranteed-rate-overview tbody');

		//HACK: dirty select
		$('.guaranteed-rate-container tbody select').select2();
		$('.guaranteed-rate-row .rateType_name').addClass('guaranteedRateType');
		deleteAllotmentRateRow();
	});

	$(document).on('click', '.btn-mass-add-flexible-rate', function(event) {
		var modalTarget = $(this).closest('.tab-pane').attr('id');

		var seasonId = $(this).data('seasonid');
		var cabinTypeId = $(this).data('cabintypeid');
		var cruise_id = $(this).data('cruise-id');

		var item = '#allotments-cruise-' + cruise_id;

		//duplicate dummy row
		var dupeRow = $(item + ' .table-flexible-rate-dummy .flexible-rate-row').clone();
		//change name attribute of duplicated row for correct post variables

		var dupFlexTypeName = 'flex[rateFlexTypes][]';
		var dupFlexValueName = 'flex[rateFlexValues][]';
		//$(dupeRow).find('.rateType_name option[value="'+rateTypeId+'"]').attr('selected', 'selected');
		$(dupeRow).find('.rateType_name').attr('name', dupFlexTypeName);
		$(dupeRow).find('.rateValue_name').attr('name', dupFlexValueName);
		$(dupeRow).find('.rateValue_name').attr('step', 'any');

		//append to real container
		dupeRow.appendTo('#' + modalTarget + ' .table-flexible-rate-overview tbody');

		//HACK: dirty select
		$('.table-flexible-rate-overview tbody select').select2();
		$('.flexible-rate-row .rateType_name').addClass('flexRateType');
		deleteAllotmentRateRow();
	});

	$(document).on('change', '.mass-package-select', function(event) {
		$('.package-taxable-panel').show();
		var package = $('.season-package-select').val();

		if (package != 0) {
			$('.package').addClass('packageRateType');
		}
	});

	//bind tax rule change
	$(document).on('change', 'input[type=radio][name=taxable]', function() {
		if (this.value == '1') {
			$('.taxable-type-form-group').show();
			$('.package-taxable-panel input[type=radio][name=taxableType]').removeAttr('disabled');
		} else {
			$('.taxable-type-form-group').hide();
			$('.taxable-type-form-group input[type=radio][name=taxableType]').attr('disabled');
		}
	});

	//bind package rate
	$(document).on('click', '.btn-mass-add-package-rate', function(event) {
		var rateRow = $('.inithide.table-package-add-rate-dummy .package-add-rate-row').clone();

		$(rateRow).find('.rateType_name').attr('name', 'rateType[]');
		$(rateRow).find('.rateValue_name').attr('name', 'rateValue[]');
		$(rateRow).find('.rateValue_name').attr('step', 'any');

		$(rateRow).removeClass('.table-package-rate-dummy');

		rateRow.appendTo('.package-add-rate-container tbody');

		$(document).on('click', '.btn-rate-row-delete', function(event) {
			$(this).closest('tr').remove();
		});
	});

	$(document).on('change', '.mass-package-select', function(event) {

		//show tax rules panel
		$('.package-taxable-panel').show();

		//remove current child package(s)
		$('#season-package-modal .season-package-container .package-panel').remove();
		//duplicate package accordion panel
		var packagePanel = $('.inithide.package-panel-dummy').clone();
		$(packagePanel).removeClass('inithide').removeClass('package-panel-dummy').addClass('package-panel');

		//get id and name of selected package
		var packageLabel = $('#season-package-modal .season-package-select option:selected').text();
		var packageId = $('#season-package-modal .season-package-select').val();
		var seasonId = $(this).data('seasonid');

		//update panel specific content
		// $(packagePanel).find('.package-name').text(packageLabel);
		$(packagePanel).find('.package-name').attr('href', '#packageCollapse' + packageId);
		$(packagePanel).find('.package-name').attr('aria-controls', 'packageCollapse' + packageId);
		$(packagePanel).find('.panel-collapse').attr('id', 'packageCollapse' + packageId);

		$(packagePanel).find('.btn-add-package-rate').attr('data-packageid', packageId);
		$(packagePanel).find('.btn-add-package-rate').attr('data-seasonid', seasonId);


		$(packagePanel).find('.package_rate_type_input').select2();

		$('.season-package-container').append(packagePanel);

		$('.season-package-container .collapse').collapse();

		//bind tax rule change
		$('input[type=radio][name=taxable]').change(function() {
			if (this.value == '1') {
				$('.taxable-type-form-group').show();
				$('.package-taxable-panel input[type=radio][name=taxableType]').removeAttr('disabled');

				$('.package-taxable-panel input[type=radio][name=taxable]').attr('name', 'package[taxable]');
				$('.package-taxable-panel input[type=radio][name=taxableType]').attr('name', 'package[taxable_type]');
			} else {
				$('.taxable-type-form-group').hide();
				$('.taxable-type-form-group input[type=radio][name=taxableType]').attr('disabled');
			}
		});

		//bind package rate
		$(document).on('click', '.btn-mass-add-package-rate', function(event) {
			var packageId = $(this).data('packageid');
			var seasonId = $(this).data('seasonid');

			var rateRow = $('#packageCollapse' + packageId + '.panel-collapse .inithide.table-package-rate-dummy .package-rate-row').clone();

			var packageRateTypeName = 'package[packageRateTypes][]';
			var packageRateValueName = 'package[packageRateValues][]';

			$(rateRow).find('.rateType_name').attr('name', packageRateTypeName);
			$(rateRow).find('.rateValue_name').attr('name', packageRateValueName);
			$(rateRow).find('.rateValue_name').attr('step', 'any');
			$(rateRow).find('select').select2();
			$(rateRow).removeClass('.table-package-rate-dummy');

			rateRow.appendTo('#packageCollapse' + packageId + '.panel-collapse .package-rate-container tbody');
		});
	});

	$(document).on('click', '#cd_mass_check', function(e) {
		// e.preventDefault();

		if ($(this).is(':checked')) {
			$('.allotment-cruisedate-checkbox').prop('checked', true);
		} else {
			$('.allotment-cruisedate-checkbox').prop('checked', false);
		}
	});
}

function checkGuaranteedReserved() {
	$(".max-guarenteed-num").on("select2:unselecting", function(e) {
		var cabinStatusId = e.params.args.data.id;

		e.preventDefault();

		$('.error-container .guaranteed').remove();

		if (cabinStatusId) {
			var url = '/contract/ajaxCheckGuaranteedReserved';
			var data = {
				'cabinStatusId': cabinStatusId
			}
			ajax.jsonCall(url, data, callback);

			function callback(response) {
				if (response['reserved'] == false) {
					$('.error-container').append('<p class="guaranteed">This cabin could not be deleted because it is already reserved.</p>');
				} else {
					$('.max-guarenteed-num option[value="' + cabinStatusId + '"]').removeAttr('selected');
					$('.max-guarenteed-num').select2();
				}
			}
		}
	});

}

function contractSectionsEvents() {

	// check for duplicates
	$(document).on('click', '.check-contract-section', function(e) {
		e.preventDefault();
		$('.error-container .number').remove();
		var number = $('input[name="number"]').val();
		var contract = $('.contract-info').val();
		var contractSectionId = $('.contract-section-id').val();

		if (number && contract) {
			var url = '/contract/ajaxCheckContractSectionDuplicates';
			var data = {
				'number': number,
				'contract': contract,
				'contractSectionId': contractSectionId
			}

			ajax.jsonCall(url, data, callback);

			function callback(response) {
				if (response['exists'] == 0) {
					$('#contract-section').submit();
				} else {
					$('.error-container .number').remove();
					$('.error-container').append('<p class="number">This number already exists for this contract</p>');
				}
			}
		}
	});

	// delete contract section
	$(document).on('click', '.delete-contract-section', function(e) {
		e.preventDefault();
		var answer = confirm("Are you sure you want to delete this section?");

		if (answer) {
			var contractId = $(this).data('contractid');
			var contractSectionId = $(this).data('contractsectionid');

			var url = '/contract/' + contractId + '/section/' + contractSectionId + '/ajaxDeleteContractSection';
			var data;
			//    = {
			//       'contractSectionId': contractSectionId
			//   }

			ajax.deleteCall(url, data, callback);

			function callback(response) {
				if (response['delete'] == 'success') {
					$('#contract-section' + contractSectionId).remove();
					// $('#contract-section' + contractSectionId).hide();
				} else {
					alert('This contract section could not be deleted.');
				}
			}

		}

	});

}

function setWysiwygEditor() {
	if ($('.wysiwyg').length > 0) {
		CKEDITOR.replace('section', {
			height: '750'
		});
	}
}

function destroyInvoiceGenerateSetting() {
	$(document).on('click', '.contract-destroy-invoice-generate-setting', function(e) {
		e.preventDefault();
		var contractId = $(this).data('contract-id');
		var invoiceGenerateSettingId = $(this).data('invoicegeneratesetting-id');
		var answer = confirm('Are you sure you want to delete this payment rule?');

		if (answer) {
			var url = '/contract/' + contractId + '/invoice-generate-setting/' + invoiceGenerateSettingId + '/destroy';
			var data = {};

			ajax.deleteCall(url, data, callback);

			function callback(response) {
				if (response['delete'] == 'success') {
					$('#invoicegeneratesetting-' + invoiceGenerateSettingId).remove();
				}

				messageHandler.sendMessage(response['delete'], response['message']);
			}

		}
	});
}

function changeStatus() {
	$(document).on('change', '.contract-status', function(e) {
		var val = $(this).val();

		switch (val) {
			case 'approved':
				$('.signed').hide();
				$('.signed-info').show();
				$('.approved-info').hide();
				$('.approved').show();
				break;
			case 'signed':
				$('.approved').hide();
				$('.approved-info').show();
				$('.signed-info').hide();
				$('.signed').show();
				break;
			default:
				$('.approved').hide();
				$('.signed').hide();
				$('.approved-info').show();
				$('.signed-info').show();
				break;
		}
	});
}

function tabEvents() {
	$(document).on('click', '[role="modal-tab"]', function(e) {
		e.preventDefault();
		var target = $(this).attr('href');
		var item = $(this).data('item');
		var object = item + ' ' + target;
		$(document).find(object).show().siblings().hide();
		$(this).parent().addClass('active').siblings().removeClass('active');
	});
}
