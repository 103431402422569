var $ = jQuery = require('jquery');

module.exports = {
    init: function(){
       tableHeader();
        massCheck();
    }
};

function tableHeader(){
    var td1 = $('.td1').width();
    $('.th1').css('width', td1);
    $('.td1').css('width', td1);

    var td2 = $('.td2').width();
    $('.th2').css('width', td2);
    $('.td2').css('width', td2);

    var td3 = $('.td3').width();
    $('.th3').css('width', td3);
    $('.td3').css('width', td3);

    var td4 = $('.td4').width();
    $('.th4').css('width', td4);
    $('.td4').css('width', td4);

    var td5 = $('.td5').width();
    $('.th5').css('width', td5);
    $('.td5').css('width', td5);

    var td6 = $('.td6').width();
    $('.th6').css('width', td6);
    $('.td6').css('width', td6);

    var td7 = $('.td7').width();
    $('.th7').css('width', td7);
    $('.td7').css('width', td7);

    var td8 = $('.td8').width();
    $('.th8').css('width', td8);
    $('.td8').css('width', td8);

    var td9 = $('.td9').width();
    $('.th9').css('width', td9);
    $('.td9').css('width', td9);

    var td10 = $('.td10').width();
    $('.th10').css('width', td10);
    $('.td10').css('width', td10);

    var td11 = $('.td11').width();
    $('.th11').css('width', td11);
    $('.td11').css('width', td11);

    var td12 = $('.td12').width();
    $('.th12').css('width', td12);
    $('.td12').css('width', td12);

    var td13 = $('.td13').width();
    $('.th13').css('width', td13);
    $('.td13').css('width', td13);

    $(window).scroll(function(){
        var windowTop = $(window).scrollTop();

        if(windowTop > 450) {
//            $('.fixed-header-table').css('position', 'fixed'); push
//            $('.fixed-header-table').css('top', '50px');
            $('.fixed-header-table').addClass('fixed-header-table2');
        }
        else {
//            $('.fixed-header-table').css('position', 'relative');
            $('.fixed-header-table').removeClass('fixed-header-table2');
        }

        if(windowTop > 100) {
            $('.page-toolbar').addClass('page-toolbar-fixed');
            $('.page-toolbar .float-button').addClass('fixed-float-button');
            $('.content').css('margin-top', '50px');
        }
        else {
            $('.page-toolbar').removeClass('page-toolbar-fixed');
            $('.page-toolbar .float-button').removeClass('fixed-float-button');
            $('.content').css('margin-top', '0px');
        }
        if(windowTop > 500) {
            $('.back-to-top').fadeIn("slow");
        }
        else {
            $('.back-to-top').fadeOut("slow");
        }
    });

    $('.back-to-top').click(function(e){
        e.preventDefault();

        $('html, body').animate({scrollTop: 0}, 1000);

    });

}
function massCheck() {
   $(document).on('click', '.cd-mass-check', function(e) {
       // e.preventDefault();

       var container = $(this).closest('table');

       if ($(this).is(':checked')) {
           container.find('.mass-checkbox').prop('checked', true);
       } else {
           container.find('.mass-checkbox').prop('checked', false);
       }
   });
}
