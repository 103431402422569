var $ = jQuery = require('jquery');

module.exports = {
	'reset': function(table, filter) {
		var filterDefault = $(table).dataTable({
			columnDefs: [{
				targets: ['no-order'],
				orderable: false
			}],
			language: {
				   "url": "/api/helper/datatable-translations"
			},
			lengthChange: false,
			stateSave: true,
			order: [],
			fnDrawCallback: function(oSettings) {
				if (oSettings._iDisplayLength > oSettings.fnRecordsDisplay()) {
					$(oSettings.nTableWrapper).find('.dataTables_paginate').hide();
				} else {
					$(oSettings.nTableWrapper).find('.dataTables_paginate').show();
				}
			}
		});

		// Fix to allow multiple datatables on same page (i.e. tabs)
		$(filter).keyup(function() {
			//multiple datatables on one page
			if ($(this).closest('.datatables-container').length > 0) {
				$(this).closest('.datatables-container').find(table).dataTable().fnFilter($(this).val());
			} else {
				filterDefault.fnFilter($(this).val());
			}
		});
	}
}
