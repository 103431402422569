/*
 * Partner > Main
 */

//----------------------------------------------------------------------
// Dependencies global
//----------------------------------------------------------------------
var $         = jQuery = require('jquery');
var bootstrap = require('bootstrap');
var select2   = require('select2');

//----------------------------------------------------------------------
// Child includes
//----------------------------------------------------------------------
var events = require('./events');
var validation = require('./validation');

module.exports = {
    "init": function(){
        events.init();
        validation.init();
    }
};
