//----------------------------------------------------------------------
// Dependencies
//----------------------------------------------------------------------
var $         = jQuery = require('jquery')

module.exports = {
    'appendPassenger': function(passenger){
        //copy duplicate
        var newItem;
        newItem = $(".reservation-passenger-row.inithide").clone().removeClass("inithide");
        //add passenger identifier to row
        $(newItem).find('.btn-reservation-passenger-remove').attr('data-passengerid', passenger.id);
        //add row identifier
        $(newItem).addClass('passenger-' + passenger.id);
        //id
         $('<input>').attr({
             type: 'hidden',
             id: 'hiddenPassengerId' + passenger.id,
             name: 'booked_passengers[]',
             value: passenger.id
         }).appendTo('#create-reservation-form');

        //name
        $(newItem).find('.passenger-name').text(passenger.lastname + ', ' + passenger.firstname);
        //dob
        $(newItem).find('.passenger-dob').text(passenger.date_of_birth);
        //gender
        $(newItem).find('.passenger-gender').text(passenger.gender);

        //fix select
        $(newItem).find('.reservation-passenger-cabin-select-dummy').addClass('reservation-passenger-cabin-select');
        $(newItem).find('.reservation-passenger-cabin-select').removeClass('reservation-passenger-cabin-select-dummy');
        $(newItem).find('.reservation-passenger-cabin-select').attr('data-passenger', passenger.id);
        $(newItem).find('.reservation-passenger-cabin-select').select2();

        $(newItem).find('.reservation-passenger-cabin-select').change(function (e) {
            e.preventDefault();
            var passengerId = $(this).attr('data-passenger');
            var cabinId = $(this).val();

            //update hidden input name attribute
            // console.log($('#booked_cabinPassengerAssignment-' + passenger.id));
            $('#booked_cabinPassengerAssignment-' + passenger.id).attr('name', 'booked_cabinPassengerAssignment['+ cabinId +'][]');
        });

        // append input to keep cabin assignment
        $('<input>').attr({
            type: 'hidden',
            id: 'booked_cabinPassengerAssignment-' +  passenger.id,
            name: 'booked_cabinPassengerAssignment[]',
            value: passenger.id
        }).appendTo('#create-reservation-form');

        //fix package multi select
        //fix select
        $(newItem).find('.reservation-passenger-package-select-dummy').addClass('reservation-passenger-package-select');
        $(newItem).find('.reservation-passenger-package-select').removeClass('reservation-passenger-package-select-dummy');
        $(newItem).find('.reservation-passenger-package-select').attr('data-passenger', passenger.id);
        $(newItem).find('.reservation-passenger-package-select').select2();

        $('<input>').attr({
            type: 'hidden',
            id: 'booked_packagePassenger-' +  passenger.id,
            name: 'booked_packagePassenger[]',
            value: passenger.id
        }).appendTo('#create-reservation-form');

        $(newItem).find('.reservation-passenger-package-select').change(function (e) {
            e.preventDefault();

            var passengerId = $(this).attr('data-passenger');


            //update hidden input name attribute
            $('#booked_packagePassenger-' + passenger.id).attr('name', 'booked_packagePassenger['+ passengerId +']');
            $('#booked_packagePassenger-' + passenger.id).attr('value', $(this).val());
        });

        newItem.appendTo(".table-passenger tbody");

        $(".table-passenger .reservation-passenger-row").show();

        return $(document).on("click", ".btn-reservation-passenger-remove", function(e) {
            e.preventDefault();
            //remove hidden input, get passengerid first
            var passengerId = $(this).data('passengerid');

            $('#hiddenPassengerId' + passengerId).remove();
            $('#booked_cabinPassengerAssignment-' + passengerId).remove();
            $('#booked_packagePassenger-' +  passengerId).remove();


            return $(this).parent().parent().remove();

        });
    },
    'showCard': function(target){
        $(target).find('.btn-card-toggle').removeClass('fa-caret-left');
        $(target).find('.btn-card-toggle').addClass('fa-caret-down');
        $(target).find('.card-content').show();
    }
};
