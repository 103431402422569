var $ = jQuery = require('jquery');

module.exports = {
  'init': function(){

  },
  'validateRequired': function(){
    var totalRequired = 0;;
    var totalFilled = 0;;

    $('.form-control').each(function(e){
      if($(this).attr('required')){
        totalRequired++;

        if($(this).val() != ''){
          totalFilled++;
          $(this).next('small').text('');
        } else {
          $(this).next('small').text('This field is required');
        }
      }
    });

    return totalRequired === totalFilled;
  },
};
