//----------------------------------------------------------------------
// Dependencies
//----------------------------------------------------------------------
var $ = jQuery = require('jquery');
var bootstrap = require('bootstrap');
var select2 = require('select2');
var ajax = require('../ajax');
var datetimepicker = require('datetimepicker');
var localDatatables = require('../datatables');
var formValidator = require('../form-validator');

//----------------------------------------------------------------------
// Exports
//----------------------------------------------------------------------
module.exports = {
	init: function() {
		init();
	}
};

function init() {
	createInvoiceEvents();
	createTaxRateEvents();
	initReportExportDateParams();
	addSetReportExportDateParams();
	changeInvoiceStatusEvent();
	addInvoiceItemsEvents();

}


//----------------------------------------------------------------------
// Invoice create events
//----------------------------------------------------------------------
function createInvoiceEvents() {

	//mass reservations assignment in allotment invoice
	// mass checkbox
	$('.mass-invoice-reservation-cb').click(function(e) {
		// e.preventDefault();

		if ($(this).is(':checked')) {
			$('.invoice-reservation-cb').prop('checked', true);
		} else {
			$('.invoice-reservation-cb').prop('checked', false);
		}
	});

	$('.finance__invoice-create .ship-select').on('change', function() {
		changeEvents();
	});

	$('input[name="from_cruise_date"], .finance__invoice-create input[name="to_cruise_date"]').on('dp.change', function() {
		changeEvents();
	});

	function changeEvents() {
		var shipId = $('.finance__invoice-create .ship-select').val();
		var fromCruiseDate = $('.finance__invoice-create input[name="from_cruise_date"]').val();
		var toCruiseDate = $('.finance__invoice-create input[name="to_cruise_date"]').val();

		if (shipId > 0 && fromCruiseDate != "" && toCruiseDate != "") {
			var data = {
				'shipId': shipId,
				'fromCruiseDate': fromCruiseDate,
				'toCruiseDate': toCruiseDate,
				'view': 'api.cruise.cruisedates-by-range'
			};

			ajax.call('/api/cruise/cruisedates-by-range', data, callback);

			function callback(response) {
				$('.cruise-dates-container').html(response);
				//reset datatables filter
				localDatatables.init();
			}
		}
	}

	$('.upfront-contract-select').change(function(event) {
		var contractId = $('.upfront-contract-select').val();

		if (contractId != -1) {
			$('.error-container p').remove();
		}
		var data = {
			'contractId': contractId,
			'view': 'api.cruise.cruisedates-by-contract'
		};

		ajax.call('/api/cruise/cruisedates-by-contract', data, callback);

		function callback(response) {
			$('.cruisedates-container').html(response);
			//reset datatables filter
			localDatatables.init();
		}

	});

	$('.partner-select').change(function(event) {
		//get partner id
		var partnerId = $('.partner-select').val();

		if (partnerId != -1) {
			$('.error-container p').remove();
		}
		var data = {
			'partner_id': partnerId
		};
		//make ajax call to server
		ajax.bladeCall('/contract/ajax/get-partner-details', data, callback);

		function callback(response) {
			$('.partner-details-container').html(response);
			$('.partner-details-container').removeClass('table-striped');
			$('.partner-details-container h4').text('Partner details');
			$('.partner-details-container h4').replaceWith(function() {
				return $("<h6 />", {
					html: $(this).html()
				});
			});

		}
	});
	// Start error checks
	// remove errors

	$('.upfront-charter-contract-select').change(function() {
		if ($('.upfront-charter-contract-select').val() != -1) {
			$('.error-container p').remove();
		}
	});

	$('.charter-contract-select').change(function() {
		if ($('.charter-contract-select').val() != -1) {
			$('.error-container p').remove();
		}
	});

	$('.contract-select').change(function() {
		if ($('.contract-select').val() != -1) {
			$('.error-container p').remove();
		}
	});

	// show errors on click with invalid value

	$('.check-partner').click(function(e) {
		$('.error-container p').remove();
		if ($('.partner-select').val() == -1) {
			e.preventDefault();
			$('.error-container').append('<p>Select a partner</p>');
		}
	});

	$('.check-charter').click(function(e) {
		$('.error-container p').remove();
		if ($('.upfront-charter-contract-select').val() == -1) {
			e.preventDefault();
			$('.error-container').append('<p>Select a charter contract');
		}
	});

	$('.check-charter-order').click(function(e) {
		$('.error-container p').remove();
		if ($('.charter-contract-select').val() == -1) {
			e.preventDefault();
			$('.error-container').append('<p>Select a charter order');
		}
	});

	$('.check-contract').click(function(e) {
		$('.error-container p').remove();
		if ($('.contract-select').val() == -1 || $('.upfront-contract-select').val() == -1) {
			e.preventDefault();
			$('.error-container').append('<p>Select a contract');
		}
	});

	$(document).on('click', '.check-blank-contract', function(e) {
		$('.error-container p').remove();
		if ($('.itinerary-number-select').val() == -1) {
			e.preventDefault();
			$('.error-container').append('<p>Select an itinerary number</p>');
		}
	});

	$('.check-cruisedates').click(function(e) {
		var cruiseDates = $('.cruise_date_id:checked').length;

		if (cruiseDates == 0) {
			e.preventDefault();
			$('.error-container').append('<p class="cruisedates">Select a cruise date</p>');
		}
	});
	$(document).on('change', '.cruise_date_id', function(e) {
		$('.error-container .cruisedates').remove();
	});
	$('.cruise_date_id').change(function(e) {
		$('.error-container .cruisedates').remove();
	});
	// End error checks

	$('.entity-select').change(function(event) {
		//get entity id
		var entityId = $('.entity-select').val();

		var data = {
			'entity_id': entityId
		};
		//make ajax call to server
		ajax.bladeCall('/finance/entity/ajax/get-entity', data, callback);

		function callback(response) {
			$('.entity-details-container').html(response);
		}
	});

	//int-travel-distances-toggle
	$('.int-travel-distances-toggle').click(function(e) {
		e.preventDefault();
		$(this).toggleClass('fa-caret-down');
		$(this).toggleClass('fa-caret-left');
		$(this).parent().parent().parent().find('.int-travel-distances').toggle();
	});

	// Disable vat % if ditance vat is checked

	$(document).on('change', '.distance-vat-checkbox', function(e) {
		if (this.checked) {
			$(this).parent().parent().find('.distance-vat-percentage').attr('disabled', 'disabled');
		} else {
			$(this).parent().parent().find('.distance-vat-percentage').removeAttr('disabled');
		}
	});
}

//----------------------------------------------------------------------
// TaxRate create events
//----------------------------------------------------------------------

function createTaxRateEvents() {

	$(document).on('change', '.region-country-select', function(e) {

		var country = $(this).children(':selected').text();

		var data = {
			'country': country
		};

		ajax.jsonCall('/api/helper/cities-by-country', data, callback);

		function callback(response) {
			response.push({
				id: 0,
				text: 'No City/Port Selected'
			});
			$('.region-city-select option').remove();
			$('.region-city-select').select2({
				'data': response
			});
			$('.region-city-select').select2('val', 0);


		}
	});

}

function initReportExportDateParams() {

	var startDate = $('.start').val();
	var endDate = $('.end').val();

	$('input[name="startAllotmentReportDate"]').attr('value', startDate);
	$('input[name="endAllotmentReportDate"]').attr('value', endDate);
	$('input[name="startPartnerReportDate"]').attr('value', startDate);
	$('input[name="endPartnerReportDate"]').attr('value', endDate);



}

function addSetReportExportDateParams() {
	$(document).on('click', '.set-report-date', function(e) {
		e.preventDefault();
		var startDate = $('.start').val();
		var endDate = $('.end').val();
		$('input[name="startAllotmentReportDate"]').attr('value', startDate);
		$('input[name="endAllotmentReportDate"]').attr('value', endDate);
		$('input[name="startPartnerReportDate"]').attr('value', startDate);
		$('input[name="endPartnerReportDate"]').attr('value', endDate);

	});
}

//----------------------------------------------------------------------
//> Invoice Edit Events
//----------------------------------------------------------------------

function changeInvoiceStatusEvent() {
	$('.invoice-change-status').change(function(e) {
		e.preventDefault();
		var answer = confirm('You are about to confirm this Invoice. This cannot be undone. Are you sure?');
		if (answer) {

			var url = '/finance/invoice/ajax/status/update';

			var invoice_id = $(this).data('invoiceid');
			var status = $(this).val();

			var data = {
				'status': status,
				'invoice_id': invoice_id
			};

			ajax.jsonCall(url, data, callback);

			function callback(response) {
				if (response.success === 'true') {

					$('#invoice-number-cell').html(response.code);
					$('.invoice-change-status').prop('disabled', true);
					$(".revert-invoice-btn").attr("disabled", "disabled");
					$(".revert-invoice-btn").removeAttr("href");
					$('.revert-invoice-btn').hide();
          $('.hide-on-confirmed').hide();
				} else {
					alert('Something went wrong while confirming this invoice, please check if all data is correct');
				}
			}
		}
	});
}


//hack DRY issue with chart js.


//----------------------------------------------------------------------
//> Finance Report
//----------------------------------------------------------------------

function addInvoiceItemsEvents() {
	$(document).on('click', '.add-invoice-item', function(e) {
		var validatedRequired = formValidator.validateRequired();

		if (validatedRequired) {
			var description = $('input[name="description_val"]').val();
			var item_price = $('input[name="item_price_val"]').val();
			var amount = $('input[name="amount_val"]').val();
			var sub_total = item_price * amount;
			$('.error-container p').remove();

			$('.invoice-items-container').removeClass('inithide');
			var dupeRow = $('.invoice-items-container .invoice-item-row.inithide').clone().removeClass('inithide');

			$(dupeRow).find('.description').attr('value', description);
			$(dupeRow).find('.description').attr('name', 'invoiceItems[description][]');
			$(dupeRow).find('.description').prop('readonly', true);
			$(dupeRow).find('.item_price').attr('value', item_price);
			$(dupeRow).find('.item_price').attr('name', 'invoiceItems[item_price][]');
			$(dupeRow).find('.item_price').prop('readonly', true);
			$(dupeRow).find('.amount').attr('value', amount);
			$(dupeRow).find('.amount').attr('name', 'invoiceItems[amount][]');
			$(dupeRow).find('.amount').prop('readonly', true);
			$(dupeRow).find('.sub-total').attr('value', sub_total);
			$(dupeRow).find('.sub-total').attr('name', 'invoiceItems[sub_total][]');
			$(dupeRow).find('.sub-total').prop('readonly', true);

			//append to real container
			dupeRow.appendTo('.invoice-items-container table tbody');

		}

	});

	$(document).on('click', '.remove-invoice-item', function(e) {
		$(this).closest('tr').remove();
	});
}
